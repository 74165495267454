import * as React from "react";

import { Input, InputNumber, Select, Form } from "antd";
import { WrappedFormUtils, GetFieldDecoratorOptions } from "antd/lib/form/Form";

class SurveyFieldItem extends React.Component<
  { field: any; form: WrappedFormUtils; editable: boolean; initialValue?: any },
  { otherVisible: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      otherVisible: false
    };
  }

  selectOnChange(key) {
    this.setState({ otherVisible: key === "other" });
  }

  getOptions({ isRequired }): GetFieldDecoratorOptions {
    const opts: GetFieldDecoratorOptions = { rules: [] };
    if (isRequired) {
      opts.rules.push({ required: true, message: "Please input this field" });
    }
    opts.initialValue = this.props.initialValue;
    return opts;
  }

  renderInput({ fieldType, placeholder, selections, selectionHasOther }) {
    switch (fieldType) {
      case "MULTI_TEXT":
        return <Input.TextArea disabled={!this.props.editable} placeholder={placeholder} />;
      case "NUMBER":
        return (
          <InputNumber
            size="small"
            disabled={!this.props.editable}
            style={{ width: "100%" }}
            placeholder={placeholder}
          />
        );
      case "SELECTION":
      case "MULTI_SELECTION":
        const props = {};
        if (fieldType === "MULTI_SELECTION") {
          props["mode"] = "multiple";
        }
        return (
          <Select size="small" disabled={!this.props.editable} {...props} onChange={e => this.selectOnChange(e)}>
            {selections.map(selection => (
              <Select.Option key={selection.id}>{selection.text}</Select.Option>
            ))}
            {selectionHasOther && <Select.Option key="other">Other</Select.Option>}
          </Select>
        );
      default:
        return <Input size="small" style={{ width: "100%" }} placeholder={placeholder} />;
    }
  }

  render() {
    const { form, field } = this.props;
    const { getFieldDecorator } = form;
    const { id, isRequired, fieldType, label, helpText, placeholder, selectionHasOther } = field;
    return (
      <div>
        <Form.Item label={label} help={helpText}>
          {getFieldDecorator(id.toString() as string, this.getOptions(field))(this.renderInput(field))}
        </Form.Item>
        {this.state.otherVisible && (
          <Form.Item label={`Input ${label}`}>
            {getFieldDecorator(`${id.toString()}_other`, this.getOptions(field))(<Input />)}
          </Form.Item>
        )}
      </div>
    );
  }
}

class HelpfulCoursesItem extends React.Component<{
  coursesRawSelection: any[];
  coursesSelectionMap: any;
  editable: boolean;
  form: WrappedFormUtils;
  helpfulCoursesData?: any[];
}> {
  getInitialHelpfulCoursesValues(): string[] {
    if (!this.props.helpfulCoursesData) {
      return [];
    }
    return this.props.helpfulCoursesData.map(id => {
      const cs = this.props.coursesSelectionMap[id];
      return cs && `${cs.number} - ${cs.name}`;
    });
  }

  render() {
    return (
      <Form.Item label="Helpful Courses">
        {this.props.form.getFieldDecorator("helpfulCourses", {
          rules: [{ required: true, message: "Please select helpful courses." }],
          initialValue: this.getInitialHelpfulCoursesValues()
        })(
          <Select
            size="small"
            disabled={!this.props.editable}
            mode="multiple"
            placeholder="Please type or select courses"
          >
            {this.props.coursesRawSelection.map(cs => (
              <Select.Option key={cs.id} value={`${cs.number}-${cs.id}`}>
                {cs.number} - {cs.name}
              </Select.Option>
            ))}
          </Select>
        )}
      </Form.Item>
    );
  }
}

type Source = "NC" | "OL" | "NW" | "CF" | "OT";

class CoopSourceItem extends React.Component<
  {
    form: WrappedFormUtils;
    editable: boolean;
    coopSourceData?: { source: Source; sourceOther: string; nucareersJobId: string };
  },
  {
    currentSelection: Source | null;
  }
> {
  constructor(props) {
    super(props);
    this.state = { currentSelection: null };
  }

  handleSelectOnChange(value) {
    this.setState({ currentSelection: value });
  }

  render() {
    return (
      <React.Fragment>
        <Form.Item label="Co-op Source">
          {this.props.form.getFieldDecorator("coopSource", {
            rules: [{ required: true, message: "Please select Co-op source." }],
            initialValue: this.props.coopSourceData && this.props.coopSourceData.source
          })(
            <Select size="small" onChange={v => this.handleSelectOnChange(v)} disabled={!this.props.editable}>
              <Select.Option value="NC">NUcareers</Select.Option>
              <Select.Option value="OL">Online</Select.Option>
              <Select.Option value="NW">Networking</Select.Option>
              <Select.Option value="CF">Career Fair</Select.Option>
              <Select.Option value="OT">Other</Select.Option>
            </Select>
          )}
        </Form.Item>
        {((this.props.coopSourceData && this.props.coopSourceData.source === "NC") ||
          this.state.currentSelection === "NC") && (
          <Form.Item label="NUcareers Job Id">
            {this.props.form.getFieldDecorator("coopSourceNucareersJobId", {
              rules: [{ required: true, message: "Please input the Job Id from NUCareers." }],
              initialValue: this.props.coopSourceData && this.props.coopSourceData.nucareersJobId
            })(<Input size="small" disabled={!this.props.editable} />)}
          </Form.Item>
        )}
        {((this.props.coopSourceData && this.props.coopSourceData.source === "OT") ||
          this.state.currentSelection === "OT") && (
          <Form.Item label="Co-op Source Other">
            {this.props.form.getFieldDecorator("coopSourceOther", {
              rules: [{ required: true, message: "Please input the co-op source." }],
              initialValue: this.props.coopSourceData && this.props.coopSourceData.sourceOther
            })(<Input size="small" disabled={!this.props.editable} />)}
          </Form.Item>
        )}
      </React.Fragment>
    );
  }
}

export { SurveyFieldItem, HelpfulCoursesItem, CoopSourceItem };
