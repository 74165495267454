import * as React from "react";
import { ComponentProps } from "../../../../store/types";
import { Table, Form, Tag, Button, Modal, Input, Switch, Icon, Select, Divider, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import { FormComponentProps } from "antd/lib/form";
import { NewFacultyAccountModalProps } from "./types";
import { WrappedFormUtils } from "antd/lib/form/Form";

import * as api from "../../../../api";
import { actions } from "../../../../store";

class ModalFormComp extends React.Component<
  ComponentProps & FormComponentProps & NewFacultyAccountModalProps & { editing: any; type: "coop" | "program" }
> {
  getInitialValue(key: string) {
    if (this.props.editing === null) {
      return null;
    }
    if (key === "adviser") {
      key = this.props.type === "coop" ? "coopAdviserUser" : "academicAdviserUser";
      return this.props.editing[key].id;
    } else {
      return this.props.editing[key];
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Form.Item label="Selection Text">
          {getFieldDecorator("text", { initialValue: this.getInitialValue("text"), rules: [{ required: true }] })(
            <Input />
          )}
        </Form.Item>
        <Form.Item
          label="Is Hidden"
          help="Hide this selection to prevent student from select it in profile page, rather than delete it."
        >
          {getFieldDecorator("isHidden", { initialValue: this.getInitialValue("isHidden"), valuePropName: "checked" })(
            <Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} />
          )}
        </Form.Item>
        <Form.Item label="Faculty Account">
          {getFieldDecorator("adviser", { initialValue: this.getInitialValue("adviser"), rules: [{ required: true }] })(
            <Select
              dropdownRender={menu => (
                <div>
                  {menu}
                  <Divider style={{ margin: "2px 0" }} />
                  <div
                    style={{ padding: "8px", cursor: "pointer" }}
                    onMouseDown={() => this.props.openNewFacultyAccountModal()}
                  >
                    <a>
                      <Icon type="plus" /> New Faculty Account
                    </a>
                  </div>
                </div>
              )}
            >
              {this.props.adviser.data.map(adviser => (
                <Select.Option key={adviser.id} value={adviser.id}>
                  {`${adviser.username}<${adviser.email}>`}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const ModalForm = Form.create()(ModalFormComp);

export default class Selection extends React.Component<
  ComponentProps & NewFacultyAccountModalProps & { type: "coop" | "program" },
  { modalVisible: boolean; editingIdx: number }
> {
  constructor(props, public modalRef) {
    super(props);
    this.modalRef = React.createRef();
    this.state = {
      modalVisible: false,
      editingIdx: null
    };
  }

  get selectionKey() {
    return this.props.type === "coop" ? "coopAdviser" : "program";
  }

  getColumns(): ColumnProps<any>[] {
    return [
      { dataIndex: "text", title: "Selection" },
      {
        dataIndex: "isHidden",
        title: "Is Hidden",
        render: x => (x ? <Tag color="volcano">Yes</Tag> : <Tag color="green">No</Tag>)
      },
      {
        dataIndex: this.props.type === "coop" ? "coopAdviserUser" : "academicAdviserUser",
        title: "Faculty Account",
        render: x => `${x.username}<${x.email}>`
      },
      {
        key: "edit",
        render: (_, record, idx) => <a onClick={() => this.setState({ editingIdx: idx, modalVisible: true })}>Edit</a>
      }
    ];
  }

  get editing() {
    if (this.state.editingIdx === null) {
      return null;
    } else {
      return this.props.selection.rawSelection[this.selectionKey][this.state.editingIdx];
    }
  }

  get modalTitle() {
    return (this.state.editingIdx === null ? "New" : "Edit") + " Co-op Adviser Selection";
  }

  get modalFrom(): WrappedFormUtils {
    return this.modalRef.current;
  }

  performData(x) {
    const data = { isHidden: !!x.isHidden, text: x.text };
    data[this.props.type === "coop" ? "coopAdviserUserId" : "academicAdviserUserId"] = x["adviser"];
    return data;
  }

  get okMethod() {
    const s = api.adviserSelection;
    if (this.state.editingIdx === null) {
      const m = this.props.type === "coop" ? s.createCoop : s.createProgram;
      return x => m(this.performData(x));
    } else {
      const m = this.props.type === "coop" ? s.updateCoop : s.updateProgram;
      return x => m(this.editing.id, this.performData(x));
    }
  }

  ok() {
    this.modalFrom.validateFields((errors, values) => {
      if (!errors) {
        this.okMethod({ ...values }).then(() => {
          message.success("Submited.");
          this.setState({ modalVisible: false });
          this.props.dispatch(actions.selection.getAllSelections());
        });
      }
    });
  }

  afterModalClose() {
    this.modalFrom.resetFields();
    this.setState({ editingIdx: null });
  }

  render() {
    return (
      <React.Fragment>
        <Table
          size="small"
          rowKey="id"
          dataSource={this.props.selection.rawSelection[this.selectionKey]}
          columns={this.getColumns()}
          loading={this.props.selection.spinning}
          pagination={false}
          footer={() => (
            <Button icon="plus" block type="primary" onClick={() => this.setState({ modalVisible: true })}>
              {this.modalTitle}
            </Button>
          )}
        />
        <Modal
          title={this.modalTitle}
          okText="submit"
          visible={this.state.modalVisible}
          onOk={() => this.ok()}
          onCancel={() => this.setState({ modalVisible: false, editingIdx: null })}
          afterClose={() => this.afterModalClose()}
        >
          <ModalForm {...this.props} editing={this.editing} ref={this.modalRef} />
        </Modal>
      </React.Fragment>
    );
  }
}
