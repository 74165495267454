import * as React from "react";
import moment = require("moment");
import { Timeline, Button, Tooltip } from "antd";

(window as any).moment = moment;

export default class ApprovalTimeline extends React.Component<{
  approval: any;
  onAction: (action: string) => any;
}> {
  getCoopAdviserTimelineColor() {
    if (!this.props.approval.finished) {
      return "#ECECEC";
    } else if (this.props.approval.rejected) {
      return "red";
    } else if (this.props.approval.coopAdviserApproved) {
      return "green";
    } else {
      return "blue";
    }
  }

  getCoopAdviserTimelineContent() {
    if (this.props.approval.coopAdviserApproved) {
      const {
        coopAdviserApprovedAt: at,
        coopAdviserApprovedBy: by,
        coopAdviserApprovedComment: comment
      } = this.props.approval;
      return (
        <div>
          <b>Co-op Adviser Approved At:</b>
          <br />
          {moment(at).format("lll")}
          <br />
          {this.getUEC({ ...by, comment })}
        </div>
      );
    } else {
      return (
        <p>
          <b>Co-op Adviser Approval</b>
          <br />
          {!this.props.approval.finalized ? (
            <Button type="primary" size="small" onClick={() => this.props.onAction("coop")}>
              Approve(Co-op)
            </Button>
          ) : (
            ""
          )}
        </p>
      );
    }
  }

  getAcademicAdviserTimelineColor() {
    if (!this.props.approval.finished) {
      return "#ECECEC";
    } else if (this.props.approval.rejected) {
      return "red";
    } else if (this.props.approval.academicAdviserApproved) {
      return "green";
    } else {
      return "blue";
    }
  }

  getUEC({ username, email, comment }) {
    return (
      <React.Fragment>
        <Tooltip title={email}>
          <a href={`mailto:${email}`}>{username}</a>:
        </Tooltip>
        <br />
        {`${comment}`}
      </React.Fragment>
    );
  }

  getAcademicAdviserTimelineContent() {
    if (this.props.approval.academicAdviserApproved) {
      const {
        academicAdviserApprovedAt: at,
        academicAdviserApprovedBy: by,
        academicAdviserApprovedComment: comment
      } = this.props.approval;
      return (
        <div>
          <b>Program Director Approved At:</b>
          <br />
          {moment(at).format("lll")}
          <br />
          {this.getUEC({ ...by, comment })}
        </div>
      );
    } else {
      return (
        <p>
          <b>Program Director Approval</b>
          <br />
          {!this.props.approval.finalized ? (
            <Button type="primary" size="small" onClick={() => this.props.onAction("academic")}>
              Approve(Academic)
            </Button>
          ) : (
            ""
          )}
        </p>
      );
    }
  }

  getRejectColor() {
    return this.props.approval.rejected ? "red" : "blue";
  }

  getRejectContent() {
    if (this.props.approval.rejected) {
      const { rejectedBy: by, rejectedAt: at, rejectedComment: comment } = this.props.approval;
      return (
        <div>
          <b>Rejected At:</b>
          <br />
          {moment(at).format("lll")}
          <br />
          {this.getUEC({ ...by, comment })}
        </div>
      );
    } else {
      return (
        <Button type="primary" size="small" onClick={() => this.props.onAction("reject")}>
          Reject
        </Button>
      );
    }
  }

  renderExtension() {
    return (
      <React.Fragment>
        {this.props.approval.extensions.map(({ newEndDate, createdBy, createdAt, comment }) => (
          <Timeline.Item color="green">
            <div>
              <b>Extended to</b>
              <br />
              <b>{moment(newEndDate).format("LL")}</b>:
              <br />
              {moment(createdAt).format("lll")}
              <br />
              {this.getUEC({ ...createdBy, comment })}
            </div>
          </Timeline.Item>
        ))}
      </React.Fragment>
    );
  }

  render() {
    const { approval } = this.props;
    return (
      <Timeline mode="right">
        <Timeline.Item color="green">
          <p>
            <b>Created At:</b> <br />
            {moment(approval.createdAt).format("lll")}
          </p>
        </Timeline.Item>
        <Timeline.Item color={approval.finished ? "green" : "blue"}>
          {
            <p>
              <b>Submitted At:</b>
              <br />
              {approval.finished ? moment(approval.finishedAt).format("lll") : <span>Unfinished...</span>}
            </p>
          }
        </Timeline.Item>
        <Timeline.Item color={this.getCoopAdviserTimelineColor()}>{this.getCoopAdviserTimelineContent()}</Timeline.Item>
        <Timeline.Item color={this.getAcademicAdviserTimelineColor()}>
          {this.getAcademicAdviserTimelineContent()}
        </Timeline.Item>
        {(this.props.approval.rejected || !this.props.approval.finalized) && (
          <Timeline.Item color={this.getRejectColor()}>{this.getRejectContent()}</Timeline.Item>
        )}
        {this.props.approval.approved &&
          this.props.approval.extensions.map(({ newEndDate, createdBy, createdAt, comment }, i) => (
            <Timeline.Item color="green" key={i}>
              <div>
                <b>Extended to {moment(newEndDate).format("LL")} At:</b>
                <br />
                {moment(createdAt).format("lll")}
                <br />
                {this.getUEC({ ...createdBy, comment })}
              </div>
            </Timeline.Item>
          ))}
        {this.props.approval.approved && (
          <Timeline.Item color="blue">
            <Button type="primary" size="small" onClick={() => this.props.onAction("extend")}>
              Extend
            </Button>
          </Timeline.Item>
        )}
      </Timeline>
    );
  }
}
