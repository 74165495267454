import Cookies from "js-cookie";

import { get, post, postForm, buildURL, toJSON, firstOrEmptyObj, makeGetSelection } from "./utils";

window.Cookies = Cookies;

export const assessment = {
  getSections: param =>
    get("/api/assessment-sections/").then(sections => {
      const all = param && param.all; // TODO
      if (!all) {
        sections = sections.filter(one => !one.isHidden);
        sections.forEach(section => {
          section.questions = section.questions.filter(one => !one.isHidden);
        });
      }
      return sections;
    }),
  getMeta: () => get("/api/assessment-meta/").then(res => res[0]),
  retrieveAnswers: () => get("/api/assessment-answers/"),
  submit: data => post("/api/assessment-answers/", data)
};

export const profile = {
  retrieve: () => get("/api/student-profile/").then(firstOrEmptyObj),
  submit: data => post("/api/student-profile/", data),
  getSelection: makeGetSelection("/api/profile-selection/")
};

export const information = {
  getSelection: makeGetSelection("/api/information-selection/"),
  retrieveInformation: () => {
    let information, profile;

    return Promise.resolve()
      .then(() => get("/api/information/"))
      .then(firstOrEmptyObj)
      .then(x => {
        information = x;
      })
      .then(() => get("/api/student-profile/"))
      .then(firstOrEmptyObj)
      .then(x => {
        profile = x;
      })
      .then(() => ({ ...information, ...profile }));
  },
  submit: data => {
    const { firstName, lastName, NUID } = data;
    const profileData = { firstName, lastName, NUID };
    return Promise.resolve()
      .then(() => post("/api/information/", data))
      .then(() => post("/api/student-profile/", profileData));
  }
};

export const resume = {
  retrieve: () => get("/api/resume/").then(firstOrEmptyObj),
  submit: data => post("/api/resume/", data)
};

export const site = {
  // getFooter: () => get('/api/footer/').then(res => res.footer)
};

export const studentApproval = {
  retrieveMy: () => get("/api/approval/my/"),
  startNew: () => post("/api/approval/my/", {}),
  uploadDoc: ({ data, approvalId }) => postForm(`/api/approval/${approvalId}/doc/`, data),
  submit: ({ data, approvalId }) => post(`/api/approval/${approvalId}/submit/`, data)
  // retrieveForm: () => get("/api/approval-form/"),
  // getFormSelection: makeGetSelection("/api/approval-form-selection/"),
  // submitForm: data => post("/api/approval-form/", data),
  // retrieveDoc: () => get("/api/approval-doc/"),
  // submitDoc: data => postForm("/api/approval-doc/", data),
  // survey: {
  //   getSelection: param =>
  //     get("/api/survey-selection/").then(selection => {
  //       const all = param && param.all;
  //       if (!all) {
  //         Object.keys(selection).forEach(key => {
  //           selection[key] = selection[key].filter(one => !one.isHidden);
  //         });
  //       }
  //       return selection;
  //     }),
  //   submit: data => post("/api/approval-survey/", data)
  // }
};

export const singleSurvey = {};

// export const studentDetail = {
//   getById: ({ id }) => get(`/api/student-detail/${id}`),
//   getSelf: () => get("/api/student-detail-self/")
// };
