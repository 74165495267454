import { Action } from "redux";

export interface AssessmentSectionState {
  readonly id: number;
  readonly title: string;
  readonly isHidden: boolean;
  readonly questions: {
    readonly id: number;
    readonly title: string;
    readonly description: string;
    readonly isHidden: boolean;
  }[];
}

export interface AssessmentState {
  readonly sections: AssessmentSectionState[];
  readonly sectionGot: boolean;
  readonly spinning: boolean;
  readonly id2idx: any;
}

export enum AssessmentActionTypes {
  TOUCH_START = "ASSESSMENT/TOUCH_START",
  LOAD_SECTIONS_DONE = "ASSESSMENT/LOAD_SECTIONS_DONE"
}

export class LoadSections implements Action {
  readonly type = AssessmentActionTypes.TOUCH_START;
}

export class LoadSectionsDone implements Action {
  readonly type = AssessmentActionTypes.LOAD_SECTIONS_DONE;
  constructor(public sections: any) {}
}

export type AssessmentActions = LoadSections | LoadSectionsDone;
