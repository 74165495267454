import * as React from "react";
import { Alert } from "antd";
import Information from "../../student/create/Information";

export default function InformationComp() {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <Alert
          type="info"
          message="This page is a preview."
          showIcon
          description={
            <span>
              This is the second step and all the validations work the same as is. If the student ever submitted this
              page to the server and visit it again, the previous submission will be pulled back to fill each blanks.
              Note that the first 3 fields(First Name, Last Name, NUID) are the same as those in the basic profile pages
              so that they are always filled automatically because students must finish the basic profile submission in
              the very first beginning. Here these 3 fields shows again for them to double check.<br></br>
              By clicking the Submit button, the validator will be triggered and if it passes, you will be directed to
              the final step.<br></br>
              To avoid any validation, click the 3-Resume from the header menu.
            </span>
          }
          closable
          style={{ marginBottom: "1em" }}
        ></Alert>
      </div>
      <Information facultyPreview></Information>
    </React.Fragment>
  );
}
