import * as React from "react";
import { connect } from "react-redux";
import { Spin, message, Divider, Radio, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";

import * as api from "../../../api";
import { actions } from "../../../store";
import { ComponentProps } from "../../../store/types";
import Step from "./Step";

const Section: React.FC<{
  number: number;
  section: { title: string; questions: { title: string; description: string; id: string }[] };
  answers: any;
  answerOnChange: (arg: { question: string; answer: string }) => any;
}> = props => (
  <div>
    <h2>{`${props.number}. ${props.section.title}`}</h2>
    <ul>
      {props.section.questions.map(({ title, description, id }, i) => (
        <li key={i}>
          <h3>{`${props.number}.${i + 1}. ${title}`}</h3>
          <p>{description}</p>
          <Radio.Group
            style={{ marginBottom: 12 }}
            onChange={e => props.answerOnChange({ question: id, answer: e.target.value })}
            defaultValue={props.answers[id] || "NE"}
            size="small"
          >
            <Radio.Button value="NE">No Experience</Radio.Button>
            <Radio.Button value="BG">Beginner</Radio.Button>
            <Radio.Button value="IM">Intermediate</Radio.Button>
            <Radio.Button value="AD">Advanced</Radio.Button>
          </Radio.Group>
        </li>
      ))}
    </ul>
  </div>
);

class SelfAssessment extends React.Component<
  ComponentProps & { readonly facultyPreview: boolean },
  { sections: any[]; answers: any; meta: any; sectionLoaded: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      answers: {},
      meta: null,
      sectionLoaded: false
    };
    api.assessment
      .getSections()
      .then(sections => this.setState({ sections }))
      .then(api.assessment.retrieveAnswers)
      .then(answers => answers.forEach(x => this.answerOnChange(x)))
      .then(() => this.setState({ sectionLoaded: true }));
    api.assessment.getMeta().then(meta => this.setState({ meta }));
  }

  isSpinning() {
    return !(this.state.sectionLoaded && this.state.meta);
  }

  answerOnChange({ question, answer }) {
    const answers = JSON.parse(JSON.stringify(this.state.answers));
    answers[question] = answer;
    this.setState({ answers });
  }

  submit() {
    const data = [];
    Object.keys(this.state.answers).forEach(question => data.push({ question, answer: this.state.answers[question] }));
    if (this.props.facultyPreview) {
      message.info("[Faculty Demo] Self assessment submitted.");
      this.props.dispatch(actions.push("2-information"));
    } else {
      api.assessment.submit(data).then(() => {
        message.success("Self assessment submitted.");
        this.props.dispatch(actions.push("2-information"));
      });
    }
  }

  renderSelfAssessment() {
    return (
      <div>
        <p>{this.state.meta.intro}</p>
        <ul>
          <li>
            <p>
              <b>No Experience: </b>
              {this.state.meta.noExp}
            </p>
          </li>
          <li>
            <p>
              <b>Beginner: </b>
              {this.state.meta.beginner}
            </p>
          </li>
          <li>
            <p>
              <b>Intermediate: </b>
              {this.state.meta.intermediate}
            </p>
          </li>
          <li>
            <p>
              <b>Advanced: </b>
              {this.state.meta.advanced}
            </p>
          </li>
        </ul>
        {this.state.sections.map((section, i) => (
          <Section
            answerOnChange={x => this.answerOnChange(x)}
            answers={this.state.answers}
            section={section}
            key={i}
            number={i + 1}
          />
        ))}
        <Button type="primary" style={{ width: "100%" }} onClick={() => this.submit()}>
          Submit
        </Button>
      </div>
    );
  }

  render() {
    return (
      <Spin style={{ width: "100%" }} spinning={this.isSpinning()}>
        <div style={{ margin: 16 }}>
          <div style={{ maxWidth: 800, margin: "0 auto" }}>
            <Step currentStep={0} />
            <Divider />
            {!this.isSpinning() && this.renderSelfAssessment()}
          </div>
        </div>
      </Spin>
    );
  }
}

export default connect(state => state)(SelfAssessment);
