import * as React from "react";
import { Alert } from "antd";
import Approval from "../../student/approve/Approval";

export default function ApprovalAfter() {
  return (
    <div style={{ maxWidth: 800, margin: "0 auto" }}>
      <Alert
        type="info"
        message="This page is a preview."
        showIcon
        closable
        style={{ marginBottom: "1em" }}
        description="After submitting the co-op approval request, students can view its status in this page. If it is rejected, they can start a new one."
      ></Alert>
      <Approval
        facultyPreview
        facultyMy={[
          {
            academicAdviserApproved: false,
            coopAdviserApproved: false,
            createdAt: "2019-01-01T00:00:00-04:00",
            finished: true
          }
        ]}
      ></Approval>
    </div>
  );
}
