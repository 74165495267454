import * as api from "../api";
import { UserListState, UserListActions, UserListActionTypes as AT } from "./userList.types";
import { ThunkActionCreator } from "./types";
import { ActionCreator, Reducer } from "redux";

const load: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return api.userList.list().then(data => dispatch({ type: AT.LOAD_DONE, data }));
};

const del: ThunkActionCreator = id => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return api.userList.del(id).then(() => dispatch({ type: AT.DELETE_DONE, id }));
};

const create: ThunkActionCreator = data => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return api.userList.create(data).then(data => dispatch({ type: AT.CREATE_DONE, data }));
};

export const actions = { load, del, create };

const initialState: UserListState = {
  data: [],
  dataGot: false,
  spinning: true,
  id2idx: {}
};

const getId2idx = (data: any[]) => {
  const id2idx = {};
  data.forEach((one, idx) => {
    id2idx[one.id] = idx;
  });
  return id2idx;
};

export const reducer: Reducer<UserListState, UserListActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case AT.TOUCH_START: {
      return {
        ...state,
        spinning: true
      };
    }
    case AT.LOAD_DONE: {
      return {
        ...state,
        data: action.data,
        id2idx: getId2idx(action.data),
        dataGot: true,
        spinning: false
      };
    }
    case AT.CREATE_DONE: {
      const created = action.data;
      return {
        ...state,
        data: [created].concat(state.data),
        id2idx: { ...state.id2idx, [created.id]: state.data.length },
        spinning: false
      };
    }
    case AT.DELETE_DONE: {
      const id = action.id;
      const idx = state.id2idx[id];
      state.data.splice(idx, 1);
      delete state.id2idx[id];
      return {
        ...state,
        data: [...state.data],
        id2idx: { ...state.id2idx },
        spinning: false
      };
    }
    default:
      return state;
  }
};
