import { Action } from "redux";

export interface ApprovalStudentState {
  readonly my: any[];
  readonly myLoading: boolean;
  readonly currentApproval: any;
}

export enum ApprovalStudentActionTypes {
  TOUCH_START = "APPROVALST/TOUCH_START",
  TOUCH_DONE = "APPROVALST/TOUCH_DONE",
  LOAD_MY_START = "APPROVALST/LOAD_MY_START",
  LOAD_MY_DONE = "APPROVALST/LOAD_MY_DONE",
  SET_CURRENT_APPROVAL = "APPROVALST/SET_CURRENT_APPROVAL",
  SUBMIT_DONE = "APPROVALST/SUBMIT_DONE",
  SURVEY_FIELDS_GOT = "APPROVAST/SURVEY_FIELDS_GOT",
  UPLOAD_DOC_DONE = "APPROVALST/UPLOAD_DOC_DONE"
}

export class TouchStart implements Action {
  readonly type = ApprovalStudentActionTypes.TOUCH_START;
}

export class TouchDone implements Action {
  readonly type = ApprovalStudentActionTypes.TOUCH_DONE;
}

export class LoadMyStart implements Action {
  readonly type = ApprovalStudentActionTypes.LOAD_MY_START;
}

export class LoadMyDone implements Action {
  readonly type = ApprovalStudentActionTypes.LOAD_MY_DONE;
  constructor(public data: any) {}
}

export class SubmitDone implements Action {
  readonly type = ApprovalStudentActionTypes.SUBMIT_DONE;
}

export class UploadDocDone implements Action {
  readonly type = ApprovalStudentActionTypes.UPLOAD_DOC_DONE;
}

export class SetCurrentApproval implements Action {
  readonly type = ApprovalStudentActionTypes.SET_CURRENT_APPROVAL;
  constructor(public approval: any) {}
}

export type ApprovalStudentActions =
  | TouchStart
  | TouchDone
  | LoadMyStart
  | LoadMyDone
  | SubmitDone
  | SetCurrentApproval
  | UploadDocDone;
