import * as React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Spin } from "antd";

import { ComponentProps } from "../../store/types";
import { actions } from "../../store";
import List from "../faculty/search/List";
import * as api from "../../api";

class StudentListComp extends React.Component<
  ComponentProps & RouteComponentProps<{ b58: string }>,
  { title: string }
> {
  constructor(props) {
    super(props);
    this.state = { title: "" };
  }

  componentDidMount() {
    const b58 = this.props.match.params.b58;
    Promise.all([
      this.props.dispatch(actions.selection.getAllSelections()),
      this.props.dispatch(actions.assessment.loadSections())
    ]).then(() => this.props.dispatch(actions.search.initSearch(b58)));
    api.sutdentList.meta(b58).then(({ title }) => this.setState({ title }));
  }

  isSpinning() {
    return !(this.props.selection.selectionGot && this.props.search.dataGot);
  }

  render() {
    return (
      <Spin spinning={this.isSpinning()} style={{ width: "100%" }}>
        {!this.isSpinning() && (
          <div>
            <h1>{this.state.title}</h1>
            <List selectable={false} />
          </div>
        )}
      </Spin>
    );
  }
}

export default withRouter(connect(state => state)(StudentListComp));
