import { RouterState, RouterAction } from "connected-react-router";
import { ActionCreator } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { EmployerActions, EmployerState, EmployerActionTypes } from "./employers.types";
import { AuthActions, AuthState, AuthActionTypes } from "./auth.types";
import { SearchActions, SearchState, SearchActionTypes } from "./search.types";
import { SelectionState, SelectionActions, SelectionActionTypes } from "./selections.types";
import { UserListState, UserListActions, UserListActionTypes } from "./userList.types";
import { AssessmentActions, AssessmentState, AssessmentActionTypes } from "./assessment.types";
import { PUState, PUActions, PUActionTypes } from "./profile-user.types";
import { ApprovalStudentState, ApprovalStudentActions, ApprovalStudentActionTypes } from "./approval-student.types";
import { ApprovalFacultyActionTypes, ApprovalFacultyState, ApprovalFacultyActions } from "./approval-faculty.types";
import { AdviserUserActionTypes, AdviserUserState, AdviserUserActions } from "./adviser-user.types";

export const ActionTypes = {
  Employer: EmployerActionTypes,
  Auth: AuthActionTypes,
  Selection: SelectionActionTypes,
  UserList: UserListActionTypes,
  Search: SearchActionTypes,
  Assessment: AssessmentActionTypes,
  User: PUActionTypes,
  ApprovalStudent: ApprovalStudentActionTypes,
  ApprovalFaculty: ApprovalFacultyActionTypes,
  Adviser: AdviserUserActionTypes
};

export interface RootState {
  readonly router: RouterState;
  readonly auth: AuthState;
  readonly employer: EmployerState;
  readonly search: SearchState;
  readonly selection: SelectionState;
  readonly userList: UserListState;
  readonly assessment: AssessmentState;
  readonly pu: PUState;
  readonly approvalStudent: ApprovalStudentState;
  readonly approvalFaculty: ApprovalFacultyState;
  readonly adviser: AdviserUserState;
}

export type Actions =
  | EmployerActions
  | AuthActions
  | SearchActions
  | SelectionActions
  | UserListActions
  | AssessmentActions
  | PUActions
  | ApprovalStudentActions
  | ApprovalFacultyActions
  | AdviserUserActions
  | RouterAction;
export type ActionCreators = ActionCreator<Actions>;
export type ThunkActions = ThunkAction<any, RootState, any, Actions>;
export type ThunkActionCreator = ActionCreator<ThunkActions>;
export type RootDispatch = ThunkDispatch<RootState, any, Actions>;
export type ComponentProps = RootState & { dispatch: RootDispatch };
