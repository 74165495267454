import * as React from "react";
import { Alert } from "antd";
import { Resume } from "../../student/create";

export default function ResumeComp() {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <Alert
          type="info"
          message="This page is a preview."
          showIcon
          description={
            <span>
              This is the final step. There are some instructions below guiding the students to submit a public resume
              link. However there's a huge number of students still submitting private resume links.<br></br>I don't see
              any convenient way to automatically check the link is public or not. Please email me if you have any
              ideas.
            </span>
          }
          closable
          style={{ marginBottom: "1em" }}
        ></Alert>
      </div>
      <Resume facultyPreview></Resume>
    </React.Fragment>
  );
}
