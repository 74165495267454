import * as React from "react";
import { connect } from "react-redux";
import { Pagination } from "antd";

import { actions } from "../../../store";
import { RootState, RootDispatch } from "../../../store/types";

const ListPagination = ({ total, currentPage, pageSize, pageChange }) => (
  <Pagination
    showSizeChanger
    pageSizeOptions={["10", "20", "50"]}
    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} records`}
    total={total}
    current={currentPage}
    pageSize={pageSize}
    onChange={pageChange}
    onShowSizeChange={pageChange}
  />
);

export default connect(
  (state: RootState) => {
    const { currentPage, pageSize } = state.search;
    return { currentPage, pageSize };
  },
  (dispatch: RootDispatch) => ({
    pageChange: (currentPage, pageSize) => dispatch(actions.search.pageChange({ currentPage, pageSize }))
  })
)(ListPagination);
