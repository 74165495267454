import { Action } from "redux";

export type SingleKeys = "entranceDate" | "expGraduationDate" | "program" | "campus" | "coopAdviser";
export type MultipleKeys = "availability" | "coursesCompleted";
export type SkillTagsKeys = "techSkills" | "topTechSkills";
export type AnswerKeys = "NE" | "BG" | "IM" | "AD";

export interface Filter {
  readonly assessment: {
    [questionId: number]: { [K in AnswerKeys]: boolean };
  };
  readonly single: { [K in SingleKeys]: number[] };
  readonly multiple: { [K in MultipleKeys]: number[] };
  readonly skillTags: { [K in SkillTagsKeys]: any };
}

export interface SearchState {
  readonly id2idx: any;
  readonly filter: Filter;
  readonly listSpinning: boolean;
  readonly data: any[];
  readonly dataGot: boolean;
  readonly generatedAt: any;
  readonly filteredData: any[];
  readonly page: any[];
  readonly pageSize: number;
  readonly currentPage: number;
  readonly selectedIdxs: number[];
  readonly isSelected: any;
  readonly isCartDrawerOpen: boolean;
  readonly isListDrawerOpen: boolean;
  readonly employersModalVisible: boolean;
  readonly selectedList: any;
}

export enum SearchActionTypes {
  START_TOUCHING = "SEARCH/START_TOUCHING",
  GET_DATA_DONE = "SEARCH/GET_DATA_DONE",
  SET_FILTER_DONE = "SEARCH/SET_FILTER_DONE",
  PAGE_CHANGE = "SEARCH/PAGE_CHANGE",
  SELECT_STUDENTS = "SEARCH/SELECT_STUDENTS",
  DESELECT_STUDENTS = "SEARCH/DESELECT_STUDENTS",
  TOGGLE_SELECT_STUDENT = "SEARCH/TOGGLE_SELECT_STUDENT",
  OPEN_CART_DRAWER = "SEARCH/OPEN_CART_DRAWER",
  CLOSE_CART_DRAWER = "SEARCH/CLOSE_CART_DRAWER",
  OPEN_LIST_DRAWER = "SEARCH/OPEN_LIST_DRAWER",
  CLOSE_LIST_DRAWER = "SEARCH/CLOSE_LIST_DRAWER",
  OPEN_EMPLOYER_MODAL = "SEARCH/OPEN_EMPLOYER_MODAL",
  CLOSE_EMPLOYER_MODAL = "SEARCH/CLOSE_EMPLOYER_MODAL"
}

export class StartTouching implements Action {
  readonly type = SearchActionTypes.START_TOUCHING;
}
export class GetDataDone implements Action {
  readonly type = SearchActionTypes.GET_DATA_DONE;
  constructor(public generatedAt: any, public data: any[], public id2idx: any) {}
}
export class SetFilterDone implements Action {
  readonly type = SearchActionTypes.SET_FILTER_DONE;
  constructor(public filter: any, public filteredData: any[]) {}
}
export class PageChange implements Action {
  readonly type = SearchActionTypes.PAGE_CHANGE;
  constructor(public currentPage: number, public pageSize: number) {}
}
export class SelectStudents implements Action {
  readonly type = SearchActionTypes.SELECT_STUDENTS;
  constructor(public idxs: number[]) {}
}
export class DeSelectStudents implements Action {
  readonly type = SearchActionTypes.DESELECT_STUDENTS;
  constructor(public idxs: number[]) {}
}
export class ToggleSelectStudent implements Action {
  readonly type = SearchActionTypes.TOGGLE_SELECT_STUDENT;
  constructor(public idx: number) {}
}
export class OpenCartDrawer implements Action {
  readonly type = SearchActionTypes.OPEN_CART_DRAWER;
}
export class CloseCartDrawer implements Action {
  readonly type = SearchActionTypes.CLOSE_CART_DRAWER;
}
export class OpenListDrawer implements Action {
  readonly type = SearchActionTypes.OPEN_LIST_DRAWER;
}
export class CloseListDrawer implements Action {
  readonly type = SearchActionTypes.CLOSE_LIST_DRAWER;
}
export class OpenEmployerModal implements Action {
  readonly type = SearchActionTypes.OPEN_EMPLOYER_MODAL;
  constructor(public list: any) {}
}
export class CloaseEmployerModal implements Action {
  readonly type = SearchActionTypes.CLOSE_EMPLOYER_MODAL;
}

export type SearchActions =
  | GetDataDone
  | StartTouching
  | SetFilterDone
  | PageChange
  | SelectStudents
  | DeSelectStudents
  | ToggleSelectStudent
  | OpenCartDrawer
  | CloseCartDrawer
  | OpenListDrawer
  | CloseListDrawer
  | OpenEmployerModal
  | CloaseEmployerModal;
