import * as React from "react";
import { Divider, Form, Spin, message, Input, Collapse, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { connect } from "react-redux";

import * as api from "../../../api";

import Step from "./Step";
import { ComponentProps } from "../../../store/types";
import { actions } from "../../../store";

class Resume extends React.Component<
  ComponentProps & FormComponentProps & { readonly facultyPreview: boolean },
  { resumeLink: string; spinning: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { resumeLink: "", spinning: true };
    api.resume.retrieve().then(({ link }) => this.setState({ resumeLink: link, spinning: false }));
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.props.facultyPreview) {
          message.info("[Faculty Demo] Resume link submitted.");
          this.props.dispatch(actions.push("done"));
        } else {
          api.resume.submit(values).then(() => {
            message.success("Resume link submitted");
            this.props.dispatch(actions.push("done"));
          });
        }
      }
    });
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const customPanelStyle = {
      background: "#f7f7f7",
      borderRadius: 4,
      marginBottom: 24,
      border: 0,
      overflow: "hidden"
    };
    return (
      <Form onSubmit={e => this.onSubmit(e)}>
        <Form.Item label="Resume Link">
          {getFieldDecorator("link", {
            initialValue: this.state.resumeLink,
            rules: [
              { required: true, message: "Please input your resume link" },
              {
                pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
                message: "Please input a valid link"
              }
            ]
          })(<Input />)}
        </Form.Item>
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Collapse.Panel header="About Resume Link" key="1" style={customPanelStyle}>
            <p>
              Upload your resume to a cloud storage, and paste the <b>public view-only</b> link here.
            </p>
            <p>Helper docs of some cloud storage services:</p>
            <ul>
              <li>
                <a href="https://support.google.com/drive/answer/2494822" rel="noopener noreferrer" target="_blank">
                  Google Drive(Recommended)
                </a>
              </li>
              <li>
                <a
                  href="https://support.office.com/en-us/article/share-onedrive-files-and-folders-9fcc2f7d-de0c-4cec-93b0-a82024800c07"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  OneDrive
                </a>
              </li>
              <li>
                <a
                  href="https://www.dropbox.com/help/files-folders/view-only-access"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Dropbox
                </a>
              </li>
            </ul>
          </Collapse.Panel>
        </Collapse>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Submit
        </Button>
      </Form>
    );
  }

  render() {
    return (
      <div style={{ margin: 16 }}>
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <Step currentStep={2} />
          <Divider />
          <Spin spinning={this.state.spinning}>{!this.state.spinning && this.renderForm()}</Spin>
          <div />
        </div>
      </div>
    );
  }
}

export default connect(state => state)(Form.create()(Resume));
