import * as React from "react";

import { connect } from "react-redux";
import { Form, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { SurveyFieldItem, HelpfulCoursesItem, CoopSourceItem } from "./SurveyFieldItem";
import { ComponentProps } from "../../../store/types";
import { actions } from "../../../store";

class SurveyFormComp extends React.Component<
  ({ surveyFields: any[]; editable: boolean; data?: any }) & ComponentProps & FormComponentProps
> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(actions.selection.getAllSelections());
  }

  getInitialValue(field) {
    const answers = this.props.data && this.props.data.answers.filter(a => a.field === field.id);
    if (!(answers && answers.length)) {
      return "";
    }

    if (field.fieldType.endsWith("SELECTION")) {
      return answers.map(a => a.answer);
    } else {
      return answers[0].answer;
    }
  }

  render() {
    if (!this.props.surveyFields) {
      return <div />;
    }

    return (
      <Form className="narrow-form">
        <HelpfulCoursesItem
          coursesRawSelection={this.props.selection.rawSelection.course || []}
          coursesSelectionMap={this.props.selection.selectionMap.course}
          editable={this.props.editable}
          helpfulCoursesData={this.props.data && this.props.data.helpfulCourses}
          form={this.props.form}
        />
        <CoopSourceItem
          editable={this.props.editable}
          coopSourceData={this.props.data && this.props.data.coopSource}
          form={this.props.form}
        />
        {this.props.surveyFields
          .filter(field => !field.isHidden)
          .map(field => (
            <SurveyFieldItem
              initialValue={this.getInitialValue(field)}
              editable={this.props.editable}
              key={`${field.id}-${this.props.data && this.props.data.user}`}
              field={field}
              form={this.props.form}
            />
          ))}
      </Form>
    );
  }
}

export default connect(state => state)(Form.create()(SurveyFormComp));
