import * as React from "react";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";

const Changelog = () => {
  return (
    <Row>
      <Col sm={0} md={2} lg={4} xl={6} />
      <Col sm={24} md={20} lg={16} xl={12}>
        <h1>Changelog</h1>
        <h2>v20231008</h2>
        <p>Fixed pending/historical loading when switching page</p>
        <h2>v20231007</h2>
        <p>Faculty approval separated into two pages -- Pending and All.</p>
        <h2>v20200517</h2>
        <ul>
          <li>
            <h3>Email Migration</h3>
            <h4>DB Remains Untouched</h4>
            <ul>
              <li>
                Husky email is still the student’s username and it will remain
                so for the future created student accounts
              </li>
              <li>Northeastern email is the stuff’s username</li>
            </ul>
            <h4>
              <b>send_email</b> Change
            </h4>
            <p>
              <code>send_email</code> will automatically replace husky with
              northeastern and also cc husky.
              <br />
              In this way, both inboxes will have at most 1 email received.
            </p>
            <h4>Frontend UI Change</h4>
            <p>
              In the Sign-In page, students now see the{" "}
              <code>@northeastern.edu</code> displayed. The email address in the
              api call is also updated.
            </p>
            <h4>Backend Adapter</h4>
            <p>
              An adapter is implemented to replace email from
              <code>@northeastern.edu</code> to <code>@husky.neu.edu</code>
              while reading frontend api call and do the reversed job while
              sending data to frontend from database.
            </p>
            <h4>Extra Notes</h4>
            <ol>
              <li>
                Another email field is the <code>information.email</code>. This
                is an extra email address student provides while creating their
                detailed profile, although most of students put their husky
                email in this field, it could be any email address and is not
                updated at all in this migration.
              </li>
              <li>
                In all the api endpoints that contains a user model, the
                <code>username</code> is still the original username in db(husky
                email for students), only the <code>email</code> field is
                injected by the adapter.
              </li>
            </ol>
          </li>
          <li>
            <h3>Selection API Permission Fixed</h3>
            All the selection APIs are now set to authenticated user only, they
            were public before.
          </li>
        </ul>
        <h2>v20200412</h2>
        <ul>
          <li>search results export</li>
        </ul>
        <h2>v20200107</h2>
        <ul>
          <li>approval table action and modal buttons fixed</li>
        </ul>
        <h2>v20191211</h2>
        <ul>
          <li>finished at fixed</li>
          <li>hourly wage now accepts decimal</li>
        </ul>
        <h2>v20191124</h2>
        <ul>
          <li>Students table export</li>
        </ul>
        <h2>v20191110</h2>
        <ul>buf fix</ul>
        <h2>v20191105</h2>
        <ul>
          <li>approval table export xlsx</li>
        </ul>
        <h2>v20191030</h2>
        <ul>
          <li>self-developed co-op helper removed</li>
          <li>unfinished approval are hidden</li>
        </ul>
        <h2>v20191012</h2>
        <ul>
          <li>Course admin page added</li>
        </ul>
        <h2>v20191004</h2>
        <ul>
          <li>Hidden students now searchable</li>
        </ul>
        <h2>v20190923</h2>
        <ul>
          <li>is_hidden default to True</li>
        </ul>
        <h2>v20190919</h2>
        <ul>
          <li>expandable detail in students page</li>
          <li>faculty preview students page</li>
        </ul>
        <h2>v20190902</h2>
        <ul>
          <li>Sign In links added</li>
        </ul>
        <h2>v20190825</h2>
        <ul>Faculty can now assign new adviser to students.</ul>
        <h2>v20190824</h2>
        <ul>
          <li>
            <h3>Approval Enhancement</h3>
            <ul>
              <li>Position info now requires manager info.</li>
              <li>HR info added.</li>
              <li>NUcareers job id now added in the Survey.</li>
              <li>Co-op extension implemented.</li>
            </ul>
          </li>
          <li>
            <h3>Bugs fixed.</h3>
          </li>
        </ul>
        <h2>v20190426</h2>
        <ul>
          <li>Top 5 Skill Tags</li>
        </ul>

        <h2>v20190422</h2>
        <ul>
          <li>Approval</li>
          <li>Multi-account</li>
          <li>Adviser and Program Selection</li>
        </ul>

        <h2>v20190226</h2>
        <ul>
          <li>Typescript rewrite</li>
          <li>Student admin page</li>
          <li>Employer search re-enabled</li>
        </ul>

        <h2>v20190207</h2>
        <ul>
          <li>Fix multiFilter</li>
        </ul>
        <h2>v20190126</h2>
        <ul>
          <li>Campus selection in profile.</li>
          <li>Email sending updated.</li>
          <li>Disabled `I Got a Co-op` entrance.</li>
        </ul>
        <h2>v20190114</h2>
        <ul>
          <li>Sign In Helper and Wechat Helper.</li>
          <li>
            <a
              href="https://github.com/eric6356/coop-react/issues/1"
              rel="noopener noreferrer"
              target="_blank"
            >
              #1
            </a>{" "}
            Fixed.
          </li>
          <li>
            <a
              href="https://github.com/eric6356/coop-react/issues/2"
              rel="noopener noreferrer"
              target="_blank"
            >
              #2
            </a>{" "}
            Fixed.
          </li>
        </ul>
        <h2>v20190109</h2>
        <ul>
          <li>Student Profile Photo</li>
          <li>Key Expiration fixed</li>
          <li>Profile update fixed</li>
        </ul>

        <h2>v20190104</h2>
        <ul>
          <li>Search feature is now faculty only.</li>
          <li>Student list feature added.</li>
          <li>Employer management feature added.</li>
        </ul>
        <h2>v20181220</h2>
        <ul>
          <li>Basic profile is required when first login into the system.</li>
          <li>Fields in approval form and suvery were re-arranged.</li>
          <li>Course filter is added to the search page.</li>
        </ul>

        <h2>v0.99999beta - 11/29/2018</h2>
        <ul>
          <h3>Now allows courses with identical names</h3>
        </ul>
        <h2>v0.9999beta - 11/27/2018</h2>
        <ul>
          <li>
            <h3>API</h3>
            <ul>
              <li>
                Program Selection and Co-op Adviser Selections now associates
                with user.
              </li>
            </ul>
          </li>
          <li>
            <h3>Frontend</h3>
            <ul>
              <li>File uploading animation.</li>
            </ul>
          </li>
        </ul>
        <h2>v0.999beta - 10/22/2018</h2>
        <ul>
          <li>Resume is now a link.</li>
          <li>Accesses to uploaded files are now under control of Django.</li>
          <li>
            <Link to="/coop/howto">How-Tos</Link> page added.
          </li>
          <li>
            <h3>Deploy</h3>
            <ul>
              <li>Deploy script with Fabric.</li>
            </ul>
          </li>
        </ul>
        <h2>v0.99beta - 09/16/2018</h2>
        <ul>
          <li>Department added in survey.</li>
          <li>Advisor emails.</li>
          <li>Faculty sign in.</li>
          <li>Faculty password change.</li>
        </ul>
        <h2>v0.9beta - 09/14/2018</h2>
        <ul>
          <li>
            <h3>Frontend</h3>
            <ul>
              <li>Changelog page added.</li>
              <li>Help link in footer.</li>
            </ul>
          </li>
        </ul>
      </Col>
      <Col sm={0} md={2} lg={4} xl={6} />
    </Row>
  );
};

export default Changelog;
