import { Reducer } from "redux";

import { AdviserUserState, AdviserUserActionTypes as AT, AdviserUserActions } from "./adviser-user.types";
import { ThunkActionCreator } from "./types";

import * as api from "../api";

const initialState: AdviserUserState = {
  loading: false,
  dataGot: false,
  data: [],
  id2idx: {}
};

const loadAllAdviser: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return api.adviserUser.list().then(data => {
    dispatch({ type: AT.LOAD_ALL_DONE, data });
    dispatch({ type: AT.TOUCH_DONE });
  });
};

export const actions = { loadAllAdviser };

export const reducer: Reducer<AdviserUserState, AdviserUserActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case AT.LOAD_ALL_DONE: {
      const id2idx = {};
      action.data.forEach((x, i) => (id2idx[x.id] = i));
      return {
        ...state,
        data: action.data,
        id2idx,
        dataGot: true
      };
    }
    case AT.TOUCH_START: {
      return {
        ...state,
        loading: true
      };
    }
    case AT.TOUCH_DONE: {
      return {
        ...state,
        loading: false
      };
    }
    case AT.MODIFY: {
      return state; // TODO:
    }
    default:
      return state;
  }
};
