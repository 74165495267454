import * as React from "react";
import { connect } from "react-redux";
import moment = require("moment");
import {
  Tag,
  Tooltip,
  Icon,
  Row,
  Col,
  Table,
  Input,
  Button,
  Divider,
  Avatar,
  Radio,
  message,
  Popconfirm,
  Menu,
  Dropdown,
  Select,
  Spin,
  List,
  Modal,
  Popover,
  Typography,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import Highlighter from "react-highlight-words";

import { ComponentProps } from "../../store/types";
import { actions } from "../../store";
import ListItem, { Item as StudentDetail } from "./search/ListItem";
import * as api from "../../api";
import SelfAssessment from "./search/SelfAssessment";
import { downloadExcel } from "../../utils";
const HiddenTag: React.FC<{ isHidden: boolean; onSwap?: any }> = ({ isHidden, onSwap }) => (
  <React.Fragment>
    <Tooltip
      title={
        isHidden
          ? "This student is hidden from search page and generated lists."
          : "This student is shown in search page and generated lists"
      }
    >
      <Tag color={isHidden ? "volcano" : "green"}>{isHidden ? "Yes" : "No"}</Tag>
    </Tooltip>
    {onSwap && (
      <Popconfirm okText="Submit" title={`Set Hidden to ${isHidden ? "No" : "Yes"}?`} onConfirm={onSwap}>
        <a>
          <Icon type="swap" />
        </a>
      </Popconfirm>
    )}
  </React.Fragment>
);

const ActiveTag: React.FC<{ isActive: boolean; onSwap?: any }> = ({ isActive, onSwap }) => (
  <React.Fragment>
    <Tooltip title={isActive ? "This student can use this App." : "This student is blocked out"}>
      <Tag color={isActive ? "green" : "volcano"}>{isActive ? "Yes" : "No"}</Tag>
    </Tooltip>
    {onSwap && (
      <Popconfirm okText="Submit" title={`Set Active to ${isActive ? "No" : "Yes"}?`} onConfirm={onSwap}>
        <a>
          <Icon type="swap" />
        </a>
      </Popconfirm>
    )}
  </React.Fragment>
);

const NUIDHelp: React.FC = () => (
  <div style={{ maxWidth: 440, textAlign: "justify" }}>
    <Typography.Paragraph>
      To bulk hide/unhide or activate/inactivate students by NUIDs, you can use this to select students first, and then
      use bulk actions below to perform.
    </Typography.Paragraph>
    <Typography.Paragraph>
      NUIDs shall be separated by comma, space or line-break(one NUID per line). Leading zeros can be omitted.
      Unrecognized NUIDs would be ignored.
    </Typography.Paragraph>
    <Typography.Paragraph>You can copy a whole colum from excel and paste it in the input field.</Typography.Paragraph>
    <Typography.Paragraph>
      Once finished inputing NUIDs, click the blue <Typography.Text code>Apply NUID Selection</Typography.Text> button
      to apply selections. Note that students from all pages (not only the current page) can be selected.
    </Typography.Paragraph>
  </div>
);

class PUComp extends React.Component<
  ComponentProps,
  {
    tableData: any[];
    searchTexts: { [key: string]: string };
    tableLoading: boolean;
    size: "small" | "middle" | "default";
    selectedRowKeys: any[];
    bulkAdviserSelected: { id: any; text: string };
    singleStudents: { [key: number]: StudentDetail };
    assessmentModalVisible: boolean;
    assessmentModalData: any;
    NUIDText: string;
  }
> {
  searchInputs = { nuid: null, fullName: null, "user.email": null };
  constructor(props) {
    super(props);
    this.state = {
      tableData: [],
      searchTexts: { nuid: "", fullName: "", "user.email": "" },
      tableLoading: true,
      selectedRowKeys: [],
      size: "small",
      bulkAdviserSelected: null,
      singleStudents: {},
      assessmentModalVisible: false,
      assessmentModalData: {},
      NUIDText: "",
    };
  }

  get avatarSize() {
    switch (this.state.size) {
      case "default":
        return "large";
      case "middle":
        return "default";
      default:
        return this.state.size;
    }
  }

  componentDidMount() {
    Promise.all([
      this.props.dispatch(actions.selection.getAllSelections()),
      this.props.dispatch(actions.assessment.loadSections()),
    ])
      .then(() => this.props.dispatch(actions.pu.load()))
      .then(() => this.initTableData())
      .then(() => this.setState({ tableLoading: false }));
  }

  initTableData() {
    this.setState({
      tableData: [...this.props.pu.data],
    });
  }

  initStudentDetail(item: any): StudentDetail {
    const m = this.props.selection.selectionMap;
    const information = item.information && {
      ...item.information,
      skillTags: { data: item.information ? item.information.skillTags.map((id) => m.skillTags[id]) : [] },
      topSkillTags: {
        data: item.information.topSkillTags.map(({ skillTag: id, rating }) => ({ skillTag: m.skillTags[id], rating })),
      },
      coursesCompleted: item.information.coursesCompleted.map((one) => m.course[one]),
      entranceDate: m.entranceDate[item.information.entranceDate],
      expGraduationDate: m.expGraduationDate[item.information.expGraduationDate],
      availability: item.information.availability.map((one) => m.availability[one]),
    };
    const profile = item.profile && {
      ...item.profile,
      campus: m.campus[item.profile.campus],
      program: m.program[item.profile.program],
    };
    return { ...item, information, profile, idx: [0] };
  }

  searchableColumn(title: string, dataIndex: string, getRecordValue: any = null) {
    const searchInput = this.searchInputs[dataIndex];
    return {
      title,
      dataIndex,
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.select());
        }
      },
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        return (
          <Input.Search
            ref={(node) => {
              this.searchInputs[dataIndex] = node && (node as any).input;
            }}
            onSearch={(name) => {
              setSelectedKeys([name]);
              this.setState({ searchTexts: { ...this.state.searchTexts, [dataIndex]: name } });
              confirm();
            }}
          />
        );
      },
      onFilter: (value, record) => {
        const recordValue = getRecordValue ? getRecordValue(record) : record[dataIndex];
        return recordValue.toLowerCase().includes(value.toLowerCase());
      },
      render: (value) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchTexts[dataIndex]]}
          autoEscape
          textToHighlight={value.toString()}
        />
      ),
    };
  }

  bulkAction(attr: "isHidden" | "isActive", value: boolean) {
    if (this.state.selectedRowKeys.length === 0) {
      message.warning("Please select at least 1 student.");
    } else {
      this.props.dispatch(actions.pu.bulkUpdateHA({ profileIds: this.state.selectedRowKeys, attr, value }));
    }
  }

  bulkAdviser(profileIds, adviser) {
    if (profileIds.length == 0) {
      message.warning("Please select at least 1 student.");
    } else {
      this.props.dispatch(actions.pu.bulkAdviser({ profileIds, adviser }));
    }
  }

  renderAdviserMenu(record) {
    return (
      <Menu>
        {this.props.selection.rawSelection["coopAdviserSelection"]
          .filter(({ isHidden }) => !isHidden)
          .map(({ id, text }) => (
            <Menu.Item key={id}>
              <Popconfirm
                okText="Switch"
                title={
                  <span>
                    Switch <b>{record.fullName}</b>'s adviser to <b>{text}</b>?
                  </span>
                }
                onConfirm={() => this.bulkAdviser([record.id], id)}
              >
                {text}
              </Popconfirm>
            </Menu.Item>
          ))}
      </Menu>
    );
  }

  renderAdviserSwitch(record) {
    return (
      <Dropdown overlay={this.renderAdviserMenu(record)}>
        <a>
          <Icon type="swap" />
        </a>
      </Dropdown>
    );
  }

  getShowModal(item) {
    return () => {
      const assessmentModalData = {
        name: `${item.profile.firstName} ${item.profile.lastName}`,
        sections: this.props.assessment.sections,
        answers: item.assessmentAnswers,
      };
      this.setState({ assessmentModalVisible: !this.state.assessmentModalVisible, assessmentModalData });
    };
  }

  renderExpand(record) {
    const item = this.state.singleStudents[record.user.id];
    if (!item) {
      api.search
        .getSingleStudent({ id: record.user.id })
        .then((item) => this.initStudentDetail(item))
        .then((item) => this.setState({ singleStudents: { ...this.state.singleStudents, [record.user.id]: item } }));
    }
    return (
      <Spin spinning={!item}>
        {item && (
          <List
            itemLayout="vertical"
            size="large"
            dataSource={[item]}
            renderItem={(item) => (
              <ListItem item={item} getShowModal={() => this.getShowModal(item)} selectable={false}></ListItem>
            )}
          ></List>
        )}
      </Spin>
    );
  }

  export() {
    const headers = [
      "NUID",
      "First Name",
      "Last Name",
      "Husky Email",
      "Program",
      "Campus",
      "Adviser",
      "Last Sign In",
      "Is Hidden",
      "Is Active",
    ];

    const aoa = [headers];
    for (const record of this.state.tableData) {
      try {
        const row = [];
        row.push(record.nuid);
        row.push(record.firstName);
        row.push(record.lastName);
        row.push(record.user.username);
        row.push(record.program ? record.program.text : null);
        row.push(record.campus ? record.campus.text : null);
        row.push(record.coopAdviser ? record.coopAdviser.text : null);
        row.push(record.user.lastLogin);
        row.push(record.user.isHidden);
        row.push(record.user.isActive);
        aoa.push(row);
      } catch (error) {
        message.warning(`Export Error: ${error}`);
        console.error(error);
      }
    }

    const missingRows = this.state.tableData.length + 1 - aoa.length;
    if (missingRows) {
      message.warning(`${missingRows} row(s) omitted due to error.`);
    }
    message.info(`Exporting ${aoa.length - 1} rows...`);
    downloadExcel("Students", { sheetName: "Students", aoa });
  }

  getNUIDs(): [Set<string>, Set<string>] {
    const NUIDs: Set<string> = new Set();
    const invalidNUIDs: Set<string> = new Set();
    this.state.NUIDText.split(/[\s,\n]+/).forEach((nuid) => {
      const padded = "0".repeat(9 - nuid.length) + nuid;
      if (!/\d+/.test(padded)) {
        invalidNUIDs.add(nuid);
      } else {
        NUIDs.add(padded);
      }
    });
    return [NUIDs, invalidNUIDs];
  }

  applyNUID() {
    const [NUIDs, invalidNUIDs] = this.getNUIDs();
    console.log({ NUIDs, invalidNUIDs });
    const newSelectedRowKeys = [];
    this.state.tableData.forEach((row) => {
      if (NUIDs.has(row.nuid)) {
        newSelectedRowKeys.push(row.id);
        NUIDs.delete(row.nuid);
      }
    });
    const unknownNUIDs = Array.from(NUIDs);
    this.setState({ selectedRowKeys: newSelectedRowKeys });
    message.info(`${newSelectedRowKeys.length} selected from NUIDs`);
    if (unknownNUIDs.length > 0) {
      let msg = `${unknownNUIDs.length} unknown NUIDs ignored: ${unknownNUIDs}`;
      if (msg.length > 100) {
        msg = msg.slice(0, 100) + "...";
      }
      message.warning(msg);
    }
    if (invalidNUIDs.size > 0) {
      let msg = `${invalidNUIDs.size} invalid NUIDs ignored: ${Array.from(invalidNUIDs)}`;
      if (msg.length > 100) {
        msg = msg.slice(0, 100) + "...";
      }
      message.warning(msg);
    }
  }

  render() {
    const columns: ColumnProps<any>[] = [
      {
        dataIndex: "profilePhoto",
        title: "",
        render: (p) => (
          <a href={p && p.photo} target="_blank">
            <Avatar shape="square" size={this.avatarSize} src={p && p.photo} />
          </a>
        ),
      },
      this.searchableColumn("NUID", "nuid"),
      this.searchableColumn("Full Name", "fullName"),
      {
        title: "Campus",
        dataIndex: "campus.text",
        filters: this.props.selection.rawSelection.campus.map((s) => ({ text: s.text, value: s.id })),
        onFilter: (value, record) => record.campus.id == value,
      },
      {
        title: "Program",
        dataIndex: "program.text",
        filters: this.props.selection.rawSelection.program.map((s) => ({ text: s.text, value: s.id })),
        onFilter: (value, record) => record.program.id == value,
      },
      {
        title: (
          <React.Fragment>
            Adviser{" "}
            <Tooltip
              title={
                <span>
                  When a co-op approval is submitted by a student, the corresponding advisor will be notified.
                  <br />
                  Click <Icon type="swap" /> icon to switch.
                </span>
              }
            >
              <a>
                <Icon type="question-circle" />
              </a>
            </Tooltip>
          </React.Fragment>
        ),
        dataIndex: "coopAdviser.text",
        filters: this.props.selection.rawSelection.coopAdviser.map((s) => ({ text: s.text, value: s.id })),
        onFilter: (value, record) => record.coopAdviser === null || record.coopAdviser.id == value, // FIXME: 001499106 no adviser
        render: (text, record) => (
          <React.Fragment>
            {text} {this.renderAdviserSwitch(record)}
          </React.Fragment>
        ),
      },
      this.searchableColumn("Email", "user.email", (r) => r.user.email),
      {
        title: "Last Sign In",
        key: "user.lastLogin",
        render: (_, record) => (record.user.lastLogin ? moment(record.user.lastLogin).fromNow() : "Never"),
      },
      {
        title: (
          <React.Fragment>
            Hidden{" "}
            <Tooltip
              title={
                <span>
                  Designates whether the student is hidden from from search page and generated lists.
                  <br />
                  Click <Icon type="swap" /> icon to toggle.
                </span>
              }
            >
              <a>
                <Icon type="question-circle" />
              </a>
            </Tooltip>
          </React.Fragment>
        ),
        key: "user.isHidden",
        filters: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
        onFilter: (value, record) => (value === "Yes") === record.user.isHidden,
        render: (_, record) => (
          <HiddenTag
            {...record.user}
            onSwap={() =>
              this.props.dispatch(
                actions.pu.updateHA({ id: record.id, isHidden: !record.user.isHidden, isActive: record.user.isActive })
              )
            }
          />
        ),
      },
      {
        title: (
          <React.Fragment>
            Active{" "}
            <Tooltip
              title={
                <span>
                  Designates whether the student can sign-in to this App.
                  <br />
                  Click <Icon type="swap" /> icon to toggle.
                </span>
              }
            >
              <a>
                <Icon type="question-circle" />
              </a>
            </Tooltip>
          </React.Fragment>
        ),
        key: "user.isActive",
        filters: [
          { text: "Yes", value: "Yes" },
          { text: "No", value: "No" },
        ],
        onFilter: (value, record) => (value === "Yes") === record.user.isActive,
        render: (_, record) => (
          <ActiveTag
            {...record.user}
            onSwap={() =>
              this.props.dispatch(
                actions.pu.updateHA({ id: record.id, isHidden: record.user.isHidden, isActive: !record.user.isActive })
              )
            }
          />
        ),
      },
    ];

    const rowSelection = {
      selectedRowKeys: this.state.selectedRowKeys,
      onChange: (keys) => this.setState({ selectedRowKeys: keys }),
    };

    return (
      <div className="white-content">
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <h1>
              Students{" "}
              <span style={{ fontSize: 14 }}>
                ({this.state.selectedRowKeys.length} selected.{" "}
                {this.state.selectedRowKeys.length > 0 && (
                  <a onClick={() => this.setState({ selectedRowKeys: [] })}>clear</a>
                )}
                )
              </span>
            </h1>
          </Col>
          <Col>
            <span style={{ marginRight: 10 }}>Size:</span>
            <Radio.Group
              size="default"
              value={this.state.size}
              onChange={(e) => this.setState({ size: e.target.value })}
            >
              <Radio.Button value="small">Small</Radio.Button>
              <Radio.Button value="middle">Middle</Radio.Button>
              <Radio.Button value="default">Large</Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
        <Row type="flex" style={{ marginBottom: 6, marginTop: 6, alignItems: "center" }} gutter={8}>
          <Col>
            <span>Select students by NUIDs:</span>
          </Col>
          <Col>
            <Popover title="Help" content={<NUIDHelp />}>
              <a>
                <Icon type="question-circle"></Icon>
              </a>
            </Popover>
          </Col>
          <Col style={{ flexGrow: 1 }}>
            <Input.TextArea
              autoSize
              rows={1}
              placeholder="NUIDs separated by comma, space or line-break. Leading zeros can be omitted. e.g. 001885806,1885807,1885808"
              value={this.state.NUIDText}
              onChange={(e) => this.setState({ NUIDText: e.target.value })}
            ></Input.TextArea>
          </Col>
          <Col>
            <Button type="primary" size="small" onClick={() => this.applyNUID()}>
              Apply NUID Selection
            </Button>
          </Col>
        </Row>
        <Row type="flex" style={{ marginBottom: 6, alignItems: "baseline" }}>
          <Col>
            <span style={{ marginRight: 8 }}>Bulk actions on selected:</span>
          </Col>
          <Col>
            <Popconfirm
              okText="Submit"
              title="Bulk set Hidden to No?"
              onConfirm={() => this.bulkAction("isHidden", false)}
            >
              <a>
                Set Hidden to: <HiddenTag isHidden={false} />
              </a>
            </Popconfirm>
          </Col>
          <Col>
            <Divider type="vertical" style={{ marginRight: 14 }} />
          </Col>
          <Col>
            <Popconfirm
              okText="Submit"
              title="Bulk set Hidden to Yes?"
              onConfirm={() => this.bulkAction("isHidden", true)}
            >
              <a>
                Set Hidden to: <HiddenTag isHidden={true} />
              </a>
            </Popconfirm>
          </Col>
          <Col>
            <Divider type="vertical" style={{ marginRight: 14 }} />
          </Col>
          <Col>
            <Popconfirm
              okText="Submit"
              title="Bulk set Active to Yes?"
              onConfirm={() => this.bulkAction("isActive", true)}
            >
              <a>
                Set Active to: <ActiveTag isActive={true} />
              </a>
            </Popconfirm>
          </Col>
          <Col>
            <Divider type="vertical" style={{ marginRight: 14 }} />
          </Col>
          <Col>
            <Popconfirm
              okText="Submit"
              title="Bulk set Active to No?"
              onConfirm={() => this.bulkAction("isActive", false)}
            >
              <a>
                Set Active to: <ActiveTag isActive={false} />
              </a>
            </Popconfirm>
          </Col>
          <Col>
            <Divider type="vertical" style={{ marginRight: 14 }} />
          </Col>
          <Col>
            {this.state.bulkAdviserSelected ? (
              <Popconfirm
                okText="Switch"
                onConfirm={() => this.bulkAdviser(this.state.selectedRowKeys, this.state.bulkAdviserSelected.id)}
                title={`Bulk switch adviser to ${this.state.bulkAdviserSelected.text}`}
              >
                <a>Switch Adviser to: </a>
              </Popconfirm>
            ) : (
              "Switch Adviser to: "
            )}

            <Select
              placeholder="Select an adviser"
              size="small"
              style={{ width: 160 }}
              onChange={(id) =>
                this.setState({
                  bulkAdviserSelected: {
                    id,
                    text: this.props.selection.selectionMap["coopAdviser"][id as string].text,
                  },
                })
              }
            >
              {this.props.selection.rawSelection["coopAdviser"]
                .filter(({ isHidden }) => !isHidden)
                .map(({ id, text }) => (
                  <Select.Option key={id} value={id}>
                    {text}
                  </Select.Option>
                ))}
            </Select>
          </Col>
        </Row>
        <Table
          rowSelection={rowSelection}
          size={this.state.size}
          columns={columns}
          dataSource={this.state.tableData}
          loading={this.state.tableLoading}
          rowKey="id"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["10", "20", "50"],
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
          }}
          expandedRowRender={(record) => this.renderExpand(record)}
          footer={() => (
            <Button icon="download" size="small" block={true} type="primary" onClick={() => this.export()}>
              Export Table Data
            </Button>
          )}
        />
        <Modal
          visible={this.state.assessmentModalVisible}
          title={this.state.assessmentModalData.name}
          footer={null}
          onCancel={() => this.setState({ assessmentModalVisible: false })}
        >
          <SelfAssessment {...this.state.assessmentModalData} />
        </Modal>
      </div>
    );
  }
}

export default connect((state) => state)(PUComp);
