import { get, post, put, patch, del, buildURL, toJSON } from "./utils";
import moment from "moment";

export const userList = {
  list: () => get("/api/user-list/"),
  // retrieve: ({ id }) => get(`/api/user-list/${id}`),
  create: data => post("/api/user-list/", data),
  // update: (id, data) => put(`/api/user-list/${id}/`, data),
  del: id => del(`/api/user-list/${id}/`)
};

export const employer = {
  list: () => get("/api/employer/"),
  create: data => post("/api/employer/", data),
  update: (id, data) => put(`/api/employer/${id}/`, data),
  del: id => put(`/api/employer/${id}/`, { isActive: false })
};

const buildGetStudents = url => () =>
  fetch(buildURL(url), {
    credentials: "include"
  }).then(res => {
    const expires = res.headers.get("expires");
    const generatedAt = (expires ? moment(expires).subtract(60, "s") : moment()).format("LTS");
    return toJSON(res).then(data => ({ data, generatedAt }));
  });

export const search = {
  getStudents: buildGetStudents("/api/search/"),
  getAllStudents: buildGetStudents("/api/search-all/"),
  getSingleStudent: ({ id }) => get(`/api/search/${id}/`)
};

const buildCLRUP = path => ({
  create: data => post(`/api/admin/${path}/`, data),
  list: () => get(`/api/admin/${path}/`),
  retrieve: id => get(`/api/admin/${path}/${id}/`),
  update: (id, data) => put(`/api/admin/${path}/${id}/`, data),
  patch: (id, data) => patch(`/api/admin/${path}/${id}/`, data)
});

export const admin = {
  listPU: () => get("/api/admin/p-u/"),
  user: buildCLRUP("user"),
  bulkHA: data => put("/api/admin/bulk-ha/", data),
  bulkAdviser: data => put("/api/admin/bulk-adviser/", data),
  profile: buildCLRUP("profile"),
  surveyField: {
    order: (fieldId, direction) => post(`/api/admin/survey-field/${fieldId}/order/${direction}/`, {}),
    create: data => post("/api/admin/survey-field/", data),
    hide: (id, isHidden) => patch(`/api/admin/survey-field/${id}/`, { isHidden })
  },
  course: buildCLRUP("course"),
  dashboard: () => get("/api/admin/dashboard/")
};

export const facultyApproval = {
  badgeCount: () => get("/api/admin/approval/badge-count/"),
  list: ({ pendingOnly }) => get(pendingOnly ? "/api/admin/pending-approval/" : "/api/admin/approval/"),
  reject: ({ id, comment }) => post(`/api/admin/approval/${id}/reject/`, { comment }),
  academicApproval: ({ id, comment }) => post(`/api/admin/approval/${id}/academic-approval/`, { comment }),
  coopApproval: ({ id, comment }) => post(`/api/admin/approval/${id}/coop-approval/`, { comment }),
  extendCoop: ({ id, comment, newEndDate }) => post(`/api/admin/approval/${id}/extend/`, { comment, newEndDate })
};

export const adviserUser = {
  list: () => get("/api/admin/adviser/user/"),
  create: data => post("/api/admin/adviser/user/", data),
  update: (id, data) => put(`/api/admin/adviser/user/${id}/`, data)
};

export const adviserSelection = {
  createCoop: data => post("/api/admin/adviser/coop/", data),
  updateCoop: (id, data) => put(`/api/admin/adviser/coop/${id}/`, data),
  updateProgram: (id, data) => put(`/api/admin/adviser/program/${id}/`, data),
  createProgram: data => post("/api/admin/adviser/program/", data)
};
