import * as React from "react";
import { connect } from "react-redux";
import { List, Timeline, Tag, Tooltip, Icon, Checkbox, Avatar, Rate, Row, Col, Alert } from "antd";

import { actions } from "../../../store";
import desc from "../../topDescription";

const CourseTag = ({ number, name, description }) => (
  <Tooltip title={description}>
    <Tag>
      {number} - {name}
    </Tag>
  </Tooltip>
);

const sectionStyle = { marginTop: 10 };
const ItemContent = ({ skillTags, topSkillTags, academicProjects, workExperience, coursesCompleted }) => (
  <div>
    <h5>Tech Skills</h5>
    {skillTags.data.map((one, i) => (
      <Tag key={i}>{one.text}</Tag>
    ))}
    <h5>Top Tech Skills</h5>
    <Row type="flex" gutter={8}>
      {topSkillTags.data.map((one, i) => (
        <Col key={i}>
          {one.skillTag.text}: <Rate disabled defaultValue={one.rating} tooltips={desc} />
        </Col>
      ))}
    </Row>
    <h5 style={sectionStyle}>Courses Completed</h5>
    {coursesCompleted.map((one, i) => (
      <CourseTag key={i} {...one} />
    ))}
    <h5 style={sectionStyle}>Academic Projects</h5>
    <div>{academicProjects || "no data"}</div>
    <h5 style={sectionStyle}>Work Experience</h5>
    <div>{workExperience || "no data"}</div>
  </div>
);

const IconLink = ({ type, onClick, link }) => (
  <span onClick={onClick}>
    <Icon type={type} style={{ marginRight: 8 }} />
    {link}
  </span>
);

const getActions = ({ information, resume, profile, assessmentAnswers }, showAssessment, selectable) => {
  let actions = [
    <IconLink
      type="bars"
      link={assessmentAnswers.length ? <a>Tech Self Assessment</a> : "No Tech Self Accessment"}
      onClick={showAssessment}
    />,
    <IconLink
      onClick={() => {}}
      type="file-pdf"
      link={
        resume ? (
          <a target="_blank" href={resume.link}>
            Resume
          </a>
        ) : (
          "No Resume"
        )
      }
    />,
    <IconLink
      onClick={() => {}}
      type="mail"
      link={information ? <a href={`mailto:${information.email}`}>{information.email}</a> : "No Email"}
    />,
    <IconLink onClick={() => {}} type="mobile" link={information ? information.phone : "No Phone"} />
  ];
  if (information && information.github) {
    const github = information.github;
    actions.push(
      <IconLink
        onClick={() => {}}
        type="github"
        link={
          <a target="_blank" href={`https://github.com/${github}`}>
            {github}
          </a>
        }
      />
    );
  }
  if (information && information.linkedin) {
    const linkedin = information.linkedin;
    actions.push(
      <IconLink
        onClick={() => {}}
        type="linkedin"
        link={
          <a target="_blank" href={`https://www.linkedin.com/in/${linkedin}`}>
            {linkedin}
          </a>
        }
      />
    );
  }
  if (selectable) {
    actions = actions.concat(
      ["program", "campus"]
        .map(x => profile[x])
        .filter(x => x)
        .map(x => x.text)
    );
  }
  return actions;
};

const ItemTitleComp = ({ item, isSelected, dispatch, selectable }) => {
  const selected = isSelected[item.idx];
  const title = `${item.profile.firstName} ${item.profile.lastName}`;
  const maybeCheckbox = selectable ? (
    <Checkbox checked={selected} onChange={() => dispatch(actions.search.toggleSelectStudent(item.idx))}>
      {title}
    </Checkbox>
  ) : (
    title
  );
  return (
    <Row type="flex" gutter={8} align="middle">
      <Col>{maybeCheckbox}</Col>
      {item.isHidden && (
        <Col>
          <Alert message={<p>Hidden Student</p>} showIcon type="warning"></Alert>
        </Col>
      )}
    </Row>
  );
};

const ItemTitle = connect(state => state.search)(ItemTitleComp);

export type Item = {
  readonly assessmentAnswers: any[];
  readonly userId: Number;
  readonly information: any;
  readonly resume: string;
  readonly profile: any;
  readonly profilePhoto: { readonly photo: string };
};

export type ListItemProps = {
  readonly item: Item;
  readonly getShowModal: (item: Item) => any;
  readonly selectable: boolean;
};

const ListItem = ({ item, getShowModal, selectable = true }: ListItemProps) => (
  <List.Item
    key={item.userId.toString()}
    actions={getActions(item, getShowModal(item), selectable)}
    extra={
      item.information && (
        <Timeline style={{ width: 100 }}>
          <Timeline.Item>
            Entrance
            <Tag>{item.information.entranceDate.text}</Tag>
          </Timeline.Item>
          <Timeline.Item>
            Availability
            {item.information.availability.map((one, i) => (
              <Tag key={i}>{one.text}</Tag>
            ))}
          </Timeline.Item>
          <Timeline.Item>
            Graduation
            <Tag>{item.information.expGraduationDate.text}</Tag>
          </Timeline.Item>
        </Timeline>
      )
    }
  >
    <List.Item.Meta
      title={<ItemTitle item={item} selectable={selectable} />}
      avatar={
        item.profilePhoto ? (
          <a href={item.profilePhoto.photo} target="_blank">
            <Avatar shape="square" size={96} src={item.profilePhoto.photo} />
          </a>
        ) : (
          <Avatar shape="square" size={96} icon="user"></Avatar>
        )
      }
      description={item.information && item.information.aboutMe}
    />
    {item.information && <ItemContent {...item.information} />}
  </List.Item>
);

export default ListItem;
