import * as React from "react";
import { Steps } from "antd";

const { Step } = Steps;

const Component = ({ currentStep }) => (
  <Steps current={currentStep}>
    <Step title="Tech Self Assessment" description="" />
    <Step title="Information" description="" />
    <Step title="Resume" description="" />
  </Steps>
);

export default Component;
