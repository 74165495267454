import * as React from "react";
import { Form, Layout, Divider, Input, Button, message } from "antd";
import { connect } from "react-redux";

import * as api from "../../api";
import { RootDispatch } from "../../store/types";
import { FormComponentProps } from "antd/lib/form";
import { actions } from "../../store";

const { Content } = Layout;
const FormItem = Form.Item;

class PasswordComponent extends React.Component<
  FormComponentProps & { dispatch: RootDispatch },
  { confirmDirty: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { confirmDirty: false };
  }

  submit() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { password } = values;
        api.auth.changePassword({ password }).then(() => {
          message.success("Password changed, please sign in again.");
          this.props.dispatch(actions.signOut());
        });
      } else {
        message.error(err);
      }
    });
  }

  validateToNextPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirm"], { force: true });
    }
    callback();
  }

  compareToFirstPassword(rule, value, callback) {
    const form = this.props.form;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords inconsistent.");
    } else {
      callback();
    }
  }

  onConfirmBlur(e) {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout>
        <Content style={{ width: 360, margin: "64px auto 0", background: "white", padding: 24 }}>
          <h1 style={{ textAlign: "center" }}>Change Password</h1>
          <Divider style={{ marginTop: 0 }} />
          <Form>
            <FormItem style={{ marginBottom: 0 }} label="New Password: ">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input your password!"
                  },
                  {
                    validator: (rule, value, cb) => this.validateToNextPassword(rule, value, cb)
                  }
                ]
              })(<Input size="large" type="password" />)}
            </FormItem>
            <FormItem label="Confirm Password: ">
              {getFieldDecorator("confirm", {
                rules: [
                  {
                    required: true,
                    message: "Please confirm your password!"
                  },
                  {
                    validator: (rule, value, cb) => this.compareToFirstPassword(rule, value, cb)
                  }
                ]
              })(<Input size="large" type="password" onBlur={e => this.onConfirmBlur(e)} />)}
            </FormItem>
            <Button type="primary" size="large" style={{ width: "100%" }} onClick={() => this.submit()}>
              Submit
            </Button>
          </Form>
        </Content>
      </Layout>
    );
  }
}

const Password = connect()(Form.create()(PasswordComponent));
export default Password;
