import * as React from "react";
import { Divider, Row, Col, Switch, Badge, Radio, Spin, Tag } from "antd";
import { connect } from "react-redux";

import { ComponentProps } from "../../../store/types";
import { actions } from "../../../store";
import ApprovalTable from "./ApprovalTable";

class PendingApproval extends React.Component<ComponentProps, { radioValue: string }> {
  constructor(props) {
    super(props);
    this.state = { radioValue: "my-pending" };
  }

  componentDidMount() {
    this.props.dispatch(actions.selection.getAllSelections());
    this.props.dispatch(actions.selection.getSurveyFields());
    this.props.dispatch(actions.approvalFaculty.loadPendingApproval());
  }

  getTableData(value) {
    const data = this.props.approvalFaculty.data;
    switch (value) {
      case "all-pending":
        return data.filter(a => a.pending);
      case "my-pending":
        const me = this.props.auth.user.id;
        return data.filter(
          a =>
            (a.studentprofile.coopAdviser.coopAdviserUser === me ||
              a.studentprofile.program.academicAdviserUser == me) &&
            a.pending
        );
    }
  }

  getDownloadFilename(value) {
    switch (value) {
      case "all-pending":
        return "Approval(All Pending)";
      case "my-pending":
        return "Approval(My Pending)";
      default:
        return "Approval";
    }
  }

  getTableDataLength(value) {
    return this.getTableData(value).length;
  }

  render() {
    return (
      <div className="white-content">
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <h1>Approval</h1>
          </Col>
          <Col>
            <Radio.Group
              buttonStyle="solid"
              defaultValue="my-pending"
              onChange={e => this.setState({ radioValue: e.target.value })}
            >
              {[
                { value: "all-pending", text: "All Pending" },
                { value: "my-pending", text: "My Pending" }
              ].map(({ value, text }) => (
                <Radio.Button key={value} value={value}>
                  {text}({this.getTableDataLength(value)})
                </Radio.Button>
              ))}
            </Radio.Group>
          </Col>
        </Row>
        <Divider />
        <Spin spinning={!this.props.approvalFaculty.dataGot}>
          <ApprovalTable
            {...this.props}
            data={this.getTableData(this.state.radioValue)}
            downloadFilename={this.getDownloadFilename(this.state.radioValue)}
          />
        </Spin>
      </div>
    );
  }
}

export default connect(state => state)(PendingApproval);
