import * as React from "react";
import { Link } from "react-router-dom";
import { Layout } from "antd";

const Footer = () => (
  <Layout.Footer style={{ textAlign: "center", flex: "none" }}>
    Copyright © 2018 - 2023 <Link to="/changelog">v20231008</Link>
  </Layout.Footer>
);

export default Footer;
