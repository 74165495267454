import * as api from "../api";
import { AssessmentActionTypes as AT, LoadSectionsDone, AssessmentState, AssessmentActions } from "./assessment.types";
import { ThunkActionCreator } from "./types";
import { ActionCreator, Reducer } from "redux";

const loadSectionsDone: ActionCreator<LoadSectionsDone> = sections => ({
  type: AT.LOAD_SECTIONS_DONE,
  sections
});

const loadSections: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return Promise.resolve()
    .then(() => api.assessment.getSections())
    .then(sections => dispatch(loadSectionsDone(sections)));
};

const initialState: AssessmentState = {
  sections: [],
  sectionGot: false,
  spinning: false,
  id2idx: {}
};

export const reducer: Reducer<AssessmentState, AssessmentActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case AT.TOUCH_START: {
      return {
        ...state,
        spinning: true
      };
    }
    case AT.LOAD_SECTIONS_DONE: {
      const { sections } = action;
      const id2idx = {};
      sections.forEach((s, idx) => {
        id2idx[s.id] = idx;
      });
      return {
        ...state,
        sections,
        id2idx,
        sectionGot: true,
        spinning: false
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = { loadSections };
