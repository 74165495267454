import { Reducer, ActionCreator } from "redux";
import { PUState, PUActions, PUActionTypes as AT, TouchStart } from "./profile-user.types";
import { ThunkActionCreator } from "./types";

import * as api from "../api";

const initialState: PUState = {
  data: [],
  dataGot: false,
  spinning: false,
  id2idx: {}
};

const touchStart: TouchStart = { type: AT.TOUCH_START };

const getId2idx = (data: any[]) => {
  const id2idx = {};
  data.forEach((one, idx) => {
    id2idx[one.id] = idx;
  });
  return id2idx;
};

const load: ThunkActionCreator = () => dispatch => {
  dispatch(touchStart);
  return api.admin.listPU().then(data => dispatch({ type: AT.LOAD_DONE, data }));
};

const bulkUpdateHA: ThunkActionCreator = ({ profileIds, attr, value }) => (dispatch, getState) => {
  dispatch(touchStart);
  const pu = getState().pu;
  const userIds = profileIds.map(id => pu.data[pu.id2idx[id]].user.id);
  return api.admin
    .bulkHA({ ids: userIds, attr, value })
    .then(() => dispatch({ type: AT.BULK_UPDATE_HA_DONE, profileIds, attr, value }));
};

const updateHA: ThunkActionCreator = ({ id, isHidden, isActive }) => (dispatch, getState) => {
  dispatch(touchStart);
  const pu = getState().pu;
  const userId = pu.data[pu.id2idx[id]].user.id;
  return api.admin.user
    .patch(userId, { isHidden, isActive })
    .then(() => dispatch({ type: AT.UPDATE_HA_DONE, id, isHidden, isActive }));
};

const bulkAdviser: ThunkActionCreator = ({ profileIds, adviser }) => (dispatch, getState) => {
  dispatch(touchStart);
  const adviserObj = getState().selection.selectionMap["coopAdviser"][adviser];
  return api.admin.bulkAdviser({ profileIds, adviser }).then(() => {
    dispatch({ type: AT.BULK_ADVISER_DONE, profileIds, adviserObj });
  });
};

export const reducer: Reducer<PUState, PUActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case AT.LOAD_DONE: {
      return {
        ...state,
        data: action.data.map(one => ({ ...one, fullName: `${one.firstName} ${one.lastName}` })),
        id2idx: getId2idx(action.data),
        dataGot: true,
        spinning: false
      };
    }
    case AT.UPDATE_HA_DONE: {
      const { id, isHidden, isActive } = action;
      const idx = state.id2idx[id];
      const data = state.data;
      data[idx].user.isActive = isActive;
      data[idx].user.isHidden = isHidden;
      return {
        ...state,
        data: [...data],
        spinning: false
      };
    }
    case AT.BULK_UPDATE_HA_DONE: {
      const { profileIds, attr, value } = action;
      const data = state.data;
      profileIds.forEach(id => {
        const idx = state.id2idx[id];
        data[idx].user[attr] = value;
      });
      return {
        ...state,
        data: [...data],
        spinning: false
      };
    }
    case AT.BULK_ADVISER_DONE: {
      const { profileIds, adviserObj } = action;
      const data = state.data;
      profileIds.forEach(id => {
        const idx = state.id2idx[id];
        data[idx].coopAdviser = adviserObj;
      });
      return {
        ...state,
        data: [...data],
        spinning: false
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = { load, updateHA, bulkUpdateHA, bulkAdviser };
