import * as React from "react";
import { connect } from "react-redux";

import * as api from "../../../api";
import { Form, Table, Input, Switch, Icon, Tag, Modal, Button, message, Row, Col } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { ComponentProps } from "../../../store/types";
import { actions } from "../../../store";

function Courses(props: ComponentProps) {
  const [courses, setCourses] = React.useState([]);
  const [dataGot, setDataGot] = React.useState(false);
  const [editingIdx, setEditingIdx] = React.useState<number>(null);
  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  function init() {
    setDataGot(false);
    api.admin.course
      .list()
      .then(setCourses)
      .then(() => setDataGot(true));
  }

  React.useEffect(init, []);

  const columns = [
    {
      title: "Course Number",
      dataIndex: "number"
    },
    {
      title: "Course Name",
      dataIndex: "name"
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "50%"
    },
    {
      title: "Hidden",
      dataIndex: "isHidden",
      render: isHidden => (isHidden ? <Tag color="volcano">Yes</Tag> : <Tag color="green">No</Tag>)
    },
    {
      title: "Action",
      dataIndex: "operation",
      render: (text, record, idx) => (
        <a href="#" onClick={() => onEdit(idx)}>
          Edit
        </a>
      )
    }
  ];

  const formRef = React.useRef(null);

  const editingCourse = editingIdx !== null && courses[editingIdx];

  function add(values) {
    return api.admin.course.create(values);
  }

  function edit(values) {
    return api.admin.course.update(editingCourse.id, values);
  }

  function onOk() {
    const form: WrappedFormUtils = formRef.current;
    form.validateFields((errors, values) => {
      if (!errors) {
        const action = editingIdx === null ? add : edit;
        action(values)
          .then(init)
          .then(onCancel)
          .then(() => message.success("Success"));
      }
    });
  }

  function onEdit(idx) {
    setEditingIdx(idx);
    setModalVisible(true);
  }

  function onCancel() {
    setEditingIdx(null);
    setModalVisible(false);
  }

  function afterClose() {
    formRef.current.resetFields();
    setEditingIdx(null);
  }

  function onAdd() {
    setEditingIdx(null);
    setModalVisible(true);
  }

  return (
    <React.Fragment>
      <Row type="flex" justify="end" style={{ marginBottom: "1em" }}>
        <Col>
          <Button onClick={() => props.dispatch(actions.push("/faculty/preview/2-information"))}>
            Goto Students Information Page
            <Icon type="select"></Icon>
          </Button>
        </Col>
      </Row>
      <Table
        size="small"
        loading={!dataGot}
        columns={columns}
        dataSource={courses}
        pagination={false}
        rowKey="id"
        footer={() => (
          <Button icon="plus" block type="primary" onClick={() => onAdd()}>
            Add New Course
          </Button>
        )}
      />
      <Modal
        title={editingIdx === null ? "Add New Course" : "Edit Course"}
        visible={modalVisible}
        okText="Submit"
        onOk={() => onOk()}
        onCancel={() => onCancel()}
        afterClose={() => afterClose()}
      >
        <CourseForm ref={formRef} editingCourse={editingCourse} />
      </Modal>
    </React.Fragment>
  );
}

function CourseFormComp(props: FormComponentProps & { editingCourse: any }) {
  const { editingCourse } = props;
  const { getFieldDecorator } = props.form;
  return (
    <Form>
      <Form.Item label="Number">
        {getFieldDecorator("number", {
          initialValue: (editingCourse && editingCourse.number) || "",
          rules: [{ required: true }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Name">
        {getFieldDecorator("name", {
          initialValue: (editingCourse && editingCourse.name) || "",
          rules: [{ required: true }]
        })(<Input />)}
      </Form.Item>
      <Form.Item label="Description">
        {getFieldDecorator("description", {
          initialValue: (editingCourse && editingCourse.description) || ""
        })(<Input.TextArea />)}
      </Form.Item>
      <Form.Item label="Hidden">
        {getFieldDecorator("isHidden", {
          initialValue: Boolean(editingCourse && editingCourse.isHidden),
          valuePropName: "checked"
        })(<Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} />)}
      </Form.Item>
    </Form>
  );
}

const CourseForm = Form.create()(CourseFormComp);

export default connect()(Courses);
