import moment = require("moment");
import * as React from "react";
import { connect } from "react-redux";
import {
  Form,
  Card,
  Input,
  Button,
  Row,
  Col,
  InputNumber,
  message,
  Tooltip,
  DatePicker,
  Upload,
  Icon,
  Popover
} from "antd";
import { FormComponentProps } from "antd/lib/form";

import SurveyForm from "./SurveyForm";
import { ComponentProps } from "../../../store/types";
import * as api from "../../../api";
import { actions } from "../../../store";

class PositionInfoFormComp extends React.Component<FormComponentProps> {
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="narrow-form">
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item label="Company Name">
              {getFieldDecorator("companyName", {
                rules: [{ required: true, whitespace: true, message: "Please input company name." }]
              })(<Input size="small" style={{ width: 210 }} />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Department">
              {getFieldDecorator("department")(<Input size="small" style={{ width: 210 }} />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Position Title">
              {getFieldDecorator("positionTitle", {
                rules: [{ required: true, whitespace: true, message: "Please input position title." }]
              })(<Input size="small" style={{ width: 210 }} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item label="Co-op Dates">
              {getFieldDecorator("coopDates", {
                rules: [{ required: true, message: "Please select co-op dates." }]
              })(<DatePicker.RangePicker size="small" style={{ width: 450 }} format="MM/DD/YYYY" />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Hourly Wage($)">
              {getFieldDecorator("hourlyWage", {
                rules: [{ required: true, message: "Please input hourly wage." }]
              })(
                <InputNumber style={{ width: 210 }} size="small" step={0.1} min={0} placeholder="Input hourly wage." />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item label="Manager Name">
              {getFieldDecorator("managerName", {
                rules: [{ required: true, message: "Please input manager name." }]
              })(<Input size="small" style={{ width: 210 }} />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Manager Email">
              {getFieldDecorator("managerEmail", {
                rules: [
                  { required: true, message: "Please input manager email." },
                  { type: "email", message: "Please input a valid email." }
                ]
              })(<Input size="small" style={{ width: 210 }} type="email" />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Manager Phone">
              {getFieldDecorator("managerPhone")(<Input size="small" style={{ width: 210 }} type="tel" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col>
            <Form.Item label="Recruiter Name">
              {getFieldDecorator("recruiterName")(<Input size="small" style={{ width: 210 }} />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Recruiter Email">
              {getFieldDecorator("recruiterEmail", {
                rules: [{ type: "email", message: "Please input a valid email." }]
              })(<Input size="small" style={{ width: 210 }} type="email" />)}
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Recruiter Phone">
              {getFieldDecorator("recruiterEmail")(<Input size="small" style={{ width: 210 }} type="tel" />)}
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}

const PositionInfoForm = Form.create()(PositionInfoFormComp);

class ApprovalForm extends React.Component<
  ComponentProps & { readonly facultyPreview: boolean },
  { uploading: boolean; jobDescriptionFile: any; offerLetterFile: any; uploaded: boolean }
> {
  constructor(props, private positionInfoForm: Form = null, private surveyForm: Form = null) {
    super(props);
    this.state = {
      uploading: false,
      jobDescriptionFile: null,
      offerLetterFile: null,
      uploaded: false
    };
  }

  handleUpload(e) {
    this.setState({ uploading: true });
    if (this.props.facultyPreview) {
      this.setState({ uploaded: true, uploading: false });
      message.info("[Faculty Demo] Documents Uploaded.");
    } else {
      const formData = new (window as any).FormData();
      formData.append("jobDescription", this.state.jobDescriptionFile);
      formData.append("offerLetter", this.state.offerLetterFile);
      api.studentApproval
        .uploadDoc({ data: formData, approvalId: this.props.approvalStudent.currentApproval.id })
        .then(() => {
          this.setState({ uploaded: true, uploading: false });
          message.success("Documents Uploaded!");
        });
    }
  }

  renderUpload() {
    const getProps = fileName => {
      const key = `${fileName}File`;
      const fileList = this.state[key] ? [this.state[key]] : [];
      return {
        beforeUpload: () => false,
        accept: ".pdf",
        multiple: false,
        onChange: ({ file }) => {
          // @ts-ignore
          this.setState({ [key]: file, uploaded: false, uploading: false });
        },
        fileList
      };
    };

    const { uploading, jobDescriptionFile, offerLetterFile } = this.state;

    return (
      <div>
        <Row>
          <Col span={6}>
            <h3>
              <span style={{ color: "red" }}>*</span>Job Description
            </h3>
            <p>
              Support <code>.pdf</code> file only
            </p>
          </Col>
          <Col span={18}>
            <Upload {...getProps("jobDescription")}>
              <Button>
                <Icon type="upload" /> Select Job Description
              </Button>
            </Upload>
          </Col>
        </Row>
        <Row style={{ marginTop: 24 }}>
          <Col span={6}>
            <h3>
              <span style={{ color: "red" }}>*</span>Offer Letter
            </h3>
            <p>
              Support <code>.pdf</code> file only
            </p>
          </Col>
          <Col span={18}>
            <Upload {...getProps("offerLetter")}>
              <Button>
                <Icon type="upload" /> Select Offer letter
              </Button>
            </Upload>
          </Col>
        </Row>

        <Row style={{ marginTop: 24 }}>
          <Col span={18}>
            <a href="../aoriskform.pdf" target="_blank">Acknowledgment of Risk for Student Travel and Off-Campus Programming</a>
          </Col>
        </Row>
        
        <Row style={{ marginTop: 24 }}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={e => this.handleUpload(e)}
            disabled={jobDescriptionFile === null || offerLetterFile === null}
            loading={uploading}
          >
            {this.state.uploaded ? "Upload Again" : "Start Upload"}
          </Button>
        </Row>
      </div>
    );
  }

  submit() {
    this.positionInfoForm.props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        const form = values;
        form.startDate = form.coopDates[0].format("YYYY-MM-DD");
        form.endDate = form.coopDates[1].format("YYYY-MM-DD");
        this.surveyForm.props.form.validateFieldsAndScroll((errors, values) => {
          if (!errors) {
            const helpfulCourses = values.helpfulCourses.map(x => parseInt(x.split("-").splice(-1)));
            const answers = [];
            this.props.selection.surveyFields.forEach(({ id, fieldType }) => {
              if (values[id] !== undefined) {
                switch (fieldType) {
                  case "NUMBER":
                    answers.push({ field: id, answer: parseFloat(values[id]) });
                    break;
                  case "SELECTION":
                  case "MULTI_SELECTION":
                    if (values[id] === "other") {
                      answers.push({ field: id, answer: values[`${id}_other`], isOther: true });
                    } else if (fieldType === "SELECTION") {
                      answers.push({ field: id, answer: parseInt(values[id]) });
                    } else {
                      // fieldType === 'MULTI_SELECTION'
                      values[id].forEach(value => {
                        answers.push({ field: id, answer: parseInt(value) });
                      });
                    }
                    break;
                  default:
                    if (values[id]) {
                      answers.push({ field: id, answer: values[id] });
                    }
                }
              }
            });
            if (this.props.facultyPreview) {
              message.info("[Faculty Demo] Approval Request Submitted.");
              this.props.dispatch(actions.push("approval-after-submission"));
            } else {
              api.studentApproval
                .submit({
                  data: {
                    form,
                    helpfulCourses,
                    answers,
                    coopSource: {
                      source: values.coopSource,
                      sourceOther: values.coopSourceOther,
                      nucareersJobId: values.coopSourceNucareersJobId
                    }
                  },
                  approvalId: this.props.approvalStudent.currentApproval.id
                })
                .then(() => {
                  message.success("Approval Request Submitted.");
                  this.props.dispatch(actions.approvalStudent.clearCurrent());
                  this.props.dispatch(actions.approvalStudent.retrieveMy());
                });
            }
          }
        });
      } else {
        this.surveyForm.props.form.validateFields(() => {});
      }
    });
  }

  render() {
    const approval = this.props.approvalStudent.currentApproval;
    if (!approval) {
      return;
    }
    return (
      <React.Fragment>
        <Row type="flex" justify="start">
          <Col>
            <div style={{ marginBottom: 5 }}>
              <b>Created At: </b>
              {moment(approval.createdAt).format("LLL")}
            </div>
          </Col>
        </Row>

        <Card style={{ marginBottom: 15 }} title="Position Information">
          <PositionInfoForm wrappedComponentRef={form => (this.positionInfoForm = form)} />
        </Card>
        <Card
          style={{ marginBottom: 15 }}
          title={
            <div>
              Document Upload
              {this.state.uploaded && <Icon style={{ color: "#52c41a", marginLeft: 5 }} type="check-circle" />}
            </div>
          }
        >
          {this.renderUpload()}
        </Card>
        <Card style={{ marginBottom: 15 }} title="Survey">
          <SurveyForm
            editable={true}
            wrappedComponentRef={form => (this.surveyForm = form)}
            surveyFields={this.props.selection.surveyFields}
          />
        </Card>
        <Row>
          <Button
            onClick={() => this.submit()}
            style={{ width: "100%" }}
            type="primary"
            disabled={!this.state.uploaded}
          >
            {this.state.uploaded ? "Submit" : "Please upload documents first."}
          </Button>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(state => state)(Form.create()(ApprovalForm));
