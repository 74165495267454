import { Reducer, ActionCreator } from "redux";
import { push } from "connected-react-router";
import { message } from "antd";
import * as api from "../api";

import { AuthState, SignedIn, AuthActions, AuthActionTypes as AT } from "./auth.types";
import { ThunkActionCreator } from "./types";

const whoami: ThunkActionCreator = () => (dispatch, getState) => {
  dispatch({ type: AT.WHOAMI_START });
  return api.auth.whoami().then(({ user, profile, profilePhoto }) => {
    user = user || {};
    profile = profile || {};
    const pathname = getState().router.location.pathname;
    const pathnames = pathname.split("/");
    const r = pathnames[1];
    const roleMismatch = (r === "student" || r === "faculty") && !user[`is${r[0].toUpperCase()}${r.slice(1)}`];
    const profileCompleted = profile && profile.campus && profile.program && profile.coopAdviser;
    const studentNoProfile = r === "student" && (!profilePhoto || !profileCompleted);
    if (roleMismatch) {
      user = initialState.user;
      profile = initialState.profile;
      profilePhoto = initialState.photoUrl;
      if (pathnames.slice(-1)[0] !== "sign-in") {
        message.warn("Please Sign In.");
        dispatch(push(`/${r}/sign-in`, { from: pathname }));
      }
    } else if (studentNoProfile && pathname !== "/student/profile") {
      message.warn("Please Submit Profile First.");
      dispatch(push("/student/profile", { from: pathname }));
    }
    return dispatch({ type: AT.WHOAMI_DONE, user, profile, profilePhoto });
  });
};

const signOut: ThunkActionCreator = () => (dispatch, getState) => {
  const role = getState().auth.role;
  return api.auth
    .signOut()
    .then(() => dispatch({ type: AT.SIGNED_OUT }))
    .then(() => message.success("Signed Out."))
    .then(() => dispatch(push("/")))
    .then(() => dispatch(push(`/${role}`)));
};

const uploadPhoto: ThunkActionCreator = (data) => (dispatch) => {
  dispatch({ type: AT.UPLOAD_PHOTO_START });
  return api.auth.photo.submit(data).then(({ photo }) => dispatch({ type: AT.UPLOAD_PHOTO_DONE, photoUrl: photo }));
};

const signInDone: ActionCreator<SignedIn> = (role: "student" | "faculty" | "employer") => ({
  type: AT.SIGNED_IN,
  role,
});

export const actions = {
  whoami,
  signOut,
  uploadPhoto,
  signInDone,
};

const initialState: AuthState = {
  whoamiLoading: false,
  user: {},
  profile: {},
  photoUrl: null,
  photoUploading: false,
  role: null,
};

export const reducer: Reducer<AuthState, AuthActions> = (state: AuthState = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case AT.SIGNED_IN: {
      return {
        ...state,
        role: action.role,
      };
    }
    case AT.SIGNED_OUT: {
      return {
        ...state,
        role: null,
      };
    }
    case AT.WHOAMI_START: {
      return {
        ...state,
        whoamiLoading: true,
      };
    }
    case AT.WHOAMI_DONE: {
      const { user, profile, profilePhoto } = action;
      return {
        ...state,
        user: user,
        profile: profile,
        whoamiLoading: false,
        photoUrl: profilePhoto && profilePhoto.photo,
      };
    }
    case AT.UPLOAD_PHOTO_START: {
      return {
        ...state,
        photoUploading: true,
      };
    }
    case AT.UPLOAD_PHOTO_DONE: {
      return {
        ...state,
        photoUploading: false,
        photoUrl: action.photoUrl,
      };
    }
    default:
      return { ...state };
  }
};
