import * as React from "react";
import { Spin, message, Divider, Select, Form, Input, Button } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { connect } from "react-redux";

import * as api from "../../../api";
import { ComponentProps } from "../../../store/types";
import { actions } from "../../../store";
import Step from "./Step";
import Top5SkillTags from "./InformationTopSkillTags";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
    md: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
    md: { span: 18 }
  }
};

class Information extends React.Component<
  ComponentProps & FormComponentProps & { readonly facultyPreview: boolean },
  {
    selectionGot: boolean;
    selection: any;
    information: any;
    informationGot: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      selection: null,
      information: null,
      selectionGot: false,
      informationGot: false
    };
    api.information.getSelection().then(selection => this.setState({ selection, selectionGot: true }));
    api.information.retrieveInformation().then(information => this.setState({ information, informationGot: true }));
  }

  isSpinning() {
    return !(this.state.selectionGot && this.state.informationGot);
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.props.facultyPreview) {
          message.info("[Faculty Demo] Information submitted.");
          this.props.dispatch(actions.push("3-resume"));
        } else {
          values.coursesCompleted = values.coursesCompleted.map(x => parseInt(x.split("-").slice(-1)[0], 10));
          api.information.submit(values).then(() => {
            message.success("Information submitted");
            this.props.dispatch(actions.push("3-resume"));
          });
        }
      }
    });
  }

  validateTags(rule, value, cb) {
    for (const tag of value) {
      if (tag.split(",").length > 1) {
        cb(`Tech Skill tag should not contain ',': ${tag}`);
        return;
      }
    }
    cb();
  }

  renderForm() {
    const { getFieldDecorator } = this.props.form;
    const initial = this.state.information;
    const skillMap = {};
    this.state.selection.skillTags.forEach(one => {
      skillMap[one.id] = one;
    });
    const courseMap = {};
    this.state.selection.courseSelection.forEach(cs => {
      courseMap[cs.id] = cs;
    });
    const getOption = (idField, textField) => (one, i) => (
      <Select.Option key={i} value={one[idField]}>
        {one[textField]}
      </Select.Option>
    );

    const topSkillTagsInitialValue = initial.topSkillTags
      ? initial.topSkillTags.map(({ skillTag, rating }) => ({ text: skillMap[skillTag].text, rating }))
      : [];
    return (
      <Form onSubmit={e => this.onSubmit(e)}>
        <section>
          <Form.Item {...formItemLayout} label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: initial.firstName,
              rules: [{ whitespace: true, required: true, message: "Please input your first name" }]
            })(<Input />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: initial.lastName,
              rules: [{ whitespace: true, required: true, message: "Please input your last name" }]
            })(<Input />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="NUID">
            {getFieldDecorator("NUID", {
              initialValue: initial.NUID,
              rules: [
                { required: true, message: "Please input your NUID" },
                { pattern: /\d{9}/, message: "NUID must be 9 digits" }
              ]
            })(<Input placeholder="9-digit student number starting with “00”." />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Phone">
            {getFieldDecorator("phone", {
              initialValue: initial.phone,
              rules: [{ required: true, whitespace: true, message: "Please input your phone number" }]
            })(<Input type="tel" />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Email">
            {getFieldDecorator("email", {
              initialValue: initial.email,
              rules: [
                { type: "email", message: "Please input a valid Email" },
                { required: true, whitespace: true, message: "Please input your Email" }
              ]
            })(<Input />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Entrance Date">
            {getFieldDecorator("entranceDate", {
              initialValue: initial.entranceDate,
              rules: [{ required: true, message: "Please input Entrance Date" }]
            })(<Select>{this.state.selection.entranceDateSelection.map(getOption("id", "text"))}</Select>)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Exp Graduation Date">
            {getFieldDecorator("expGraduationDate", {
              initialValue: initial.expGraduationDate,
              rules: [{ required: true, message: "Please input Exp Graduation Date" }]
            })(<Select>{this.state.selection.expGraduationDateSelection.map(getOption("id", "text"))}</Select>)}
          </Form.Item>

          <Form.Item {...formItemLayout} label="GitHub Link">
            {getFieldDecorator("github", {
              initialValue: initial.github,
              rules: [
                {
                  pattern: /^[a-z\d](?:[a-z\d]|-(?=[a-z\d])){0,38}$/i,
                  message:
                    "GitHub username may only contain alphanumeric characters or single hyphens, and cannot begin or end with a hyphen"
                }
              ]
            })(<Input addonBefore="https://github.com/" placeholder="Input your GitHub username" />)}
          </Form.Item>

          <Form.Item {...formItemLayout} label="LinkdIn URL">
            {getFieldDecorator("linkedin", {
              initialValue: initial.linkedin,
              rules: [
                { required: true, message: "Please input your LinkedIn link." },
                {
                  pattern: /^[-a-z\d]{3,100}$/i,
                  message:
                    "Your custom URL must contain 3-100 letters or numbers. Please do not use spaces, symbols, or special characters."
                }
              ]
            })(<Input addonBefore="https://www.linkedin.com/in/" placeholder="Input your LinkedIn URL" />)}
          </Form.Item>
        </section>

        <section>
          <Form.Item {...formItemLayout} label="About Me">
            {getFieldDecorator("aboutMe", {
              initialValue: initial.aboutMe,
              rules: [{ required: true, whitespace: true, message: "Please input this field." }]
            })(<Input.TextArea placeholder="A few lines about you" autosize />)}
          </Form.Item>
        </section>

        <section>
          <Form.Item {...formItemLayout} label="Tech Skills">
            {getFieldDecorator("skillTags", {
              initialValue: initial.skillTags ? initial.skillTags.map(id => skillMap[id].text) : [],
              rules: [
                { required: true, message: "Please input this field" },
                { validator: (rule, value, cb) => this.validateTags(rule, value, cb) }
              ]
            })(
              <Select mode="multiple" placeholder="Type in and select your skills">
                {this.state.selection.skillTags.map(getOption("text", "text"))}
              </Select>
            )}
          </Form.Item>
        </section>

        <section>
          <Form.Item
            {...formItemLayout}
            label="Top 5 Tech Skills"
            help="Select and rate your top Tech Skills, at most 5."
          >
            {getFieldDecorator("topSkillTags", { initialValue: topSkillTagsInitialValue })(<Input hidden />)}
            <Top5SkillTags
              initialValue={topSkillTagsInitialValue}
              skillTags={this.props.form.getFieldValue("skillTags")}
              onChange={value => this.props.form.setFieldsValue({ topSkillTags: value })}
            />
          </Form.Item>
        </section>

        <section>
          <Form.Item {...formItemLayout} label="Academic Projects">
            {getFieldDecorator("academicProjects", {
              initialValue: initial.academicProjects
            })(<Input.TextArea placeholder="List your academic projects here" autosize />)}
          </Form.Item>
        </section>

        <section>
          <Form.Item {...formItemLayout} label="Work Experience">
            {getFieldDecorator("workExperience", {
              initialValue: initial.workExperience
            })(<Input.TextArea placeholder="List your work experience here" autosize />)}
          </Form.Item>
        </section>

        <section>
          <Form.Item {...formItemLayout} label="Availability">
            {getFieldDecorator("availability", {
              initialValue: initial.availability,
              rules: [{ required: true, message: "Please select your availability" }]
            })(
              <Select mode="multiple" placeholder="Please select availability">
                {this.state.selection.availabilitySelection.map(getOption("id", "text"))}
              </Select>
            )}
          </Form.Item>
        </section>

        <section>
          <Form.Item {...formItemLayout} label="Courses Completed">
            {getFieldDecorator("coursesCompleted", {
              initialValue: initial.coursesCompleted
                ? initial.coursesCompleted.map(id => courseMap[id].number + "-" + id)
                : [],
              rules: [{ required: true, message: "Please select your completed courses" }]
            })(
              <Select mode="multiple" placeholder="Please type or select courses">
                {this.state.selection.courseSelection.map(cs => (
                  <Select.Option key={cs.id} value={`${cs.number}-${cs.id}`}>
                    {cs.number} - {cs.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </section>

        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Submit
        </Button>
      </Form>
    );
  }

  render() {
    return (
      <Spin spinning={this.isSpinning()} style={{ width: "100%" }}>
        <div style={{ margin: 16 }}>
          <div style={{ maxWidth: 800, margin: "0 auto" }}>
            <Step currentStep={1} />
            <Divider />
            {!this.isSpinning() && this.renderForm()}
          </div>
        </div>
      </Spin>
    );
  }
}

export default connect(state => state)(Form.create()(Information));
