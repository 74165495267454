import { Reducer, ActionCreator } from "redux";
import {
  ApprovalStudentState,
  ApprovalStudentActionTypes as AT,
  TouchStart,
  TouchDone,
  ApprovalStudentActions,
  SetCurrentApproval
} from "./approval-student.types";
import * as api from "../api";
import { ThunkActionCreator } from "./types";

const initialState: ApprovalStudentState = {
  my: [],
  myLoading: false,
  currentApproval: null
};

const touchStart: TouchStart = { type: AT.TOUCH_START };
const touchDone: TouchDone = { type: AT.TOUCH_DONE };

const retrieveMy: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.LOAD_MY_START });
  return api.studentApproval.retrieveMy().then(data => dispatch({ type: AT.LOAD_MY_DONE, data }));
};

const clearCurrent: ActionCreator<SetCurrentApproval> = () => ({
  type: AT.SET_CURRENT_APPROVAL,
  approval: null
});

const newApproval: ThunkActionCreator = () => dispatch => {
  return api.studentApproval.startNew().then(data => dispatch({ type: AT.SET_CURRENT_APPROVAL, approval: data }));
};

export const actions = { retrieveMy, newApproval, clearCurrent };

export const reducer: Reducer<ApprovalStudentState, ApprovalStudentActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case AT.LOAD_MY_START: {
      return {
        ...state,
        myLoading: true
      };
    }
    case AT.LOAD_MY_DONE: {
      return {
        ...state,
        my: [...action.data],
        myLoading: false
      };
    }
    case AT.SET_CURRENT_APPROVAL: {
      return {
        ...state,
        currentApproval: action.approval
      };
    }
    default: {
      return state;
    }
  }
};
