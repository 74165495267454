import { Action } from "redux";

export enum AdviserUserActionTypes {
  TOUCH_START = "ADVISERUSER/TOUCH_START",
  TOUCH_DONE = "ADVISERUSER/TOUCH_DONE",
  LOAD_ALL_DONE = "ADVISERUSER/LOAD_ALL_DONE",
  MODIFY = "ADVISERUSER/MODIFY"
}

export interface AdviserUserState {
  readonly loading: boolean;
  readonly dataGot: boolean;
  readonly data: any[];
  readonly id2idx: any;
}

export class TouchStart implements Action {
  readonly type = AdviserUserActionTypes.TOUCH_START;
}

export class TouchDone implements Action {
  readonly type = AdviserUserActionTypes.TOUCH_DONE;
}

export class LoadAllDone implements Action {
  readonly type = AdviserUserActionTypes.LOAD_ALL_DONE;
  constructor(public data: any[]) {}
}

export class Modify implements Action {
  readonly type = AdviserUserActionTypes.MODIFY;
  constructor(public data: any) {}
}

export type AdviserUserActions = TouchStart | TouchDone | LoadAllDone | Modify;
