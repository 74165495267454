import * as React from "react";
import { connect } from "react-redux";
import Cookies from "js-cookie";

import { Row, Col, Switch, Typography, Dropdown, Menu, Icon, Spin } from "antd";
import { actions } from "../../../store";
import { ComponentProps } from "../../../store/types";
import { Button } from "antd/lib/radio";

function AllToggler(props: ComponentProps) {
  const { dispatch } = props;
  const [all, setAll] = React.useState(Boolean(Cookies.get("searchAll")));
  const [spinning, setSpinning] = React.useState(false);

  const text = `Now ${all ? "Including" : "Excluding"} Hidden Students`;
  const menuText = `${all ? "Exclude" : "Include"} Hidden Students`;

  function handleMenuClick() {
    setSpinning(true);
    const newAll = !all;
    if (newAll) {
      Cookies.set("searchAll", 1);
    } else {
      Cookies.remove("searchAll");
    }
    dispatch(actions.search.initSearch()).then(() => {
      setAll(newAll);
      setSpinning(false);
    });
  }

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item>
        <Icon type="swap" />
        {menuText}
      </Menu.Item>
    </Menu>
  );

  return (
    <Spin spinning={spinning}>
      <Dropdown overlay={menu}>
        <Button>
          {text} <Icon type="down" />
        </Button>
      </Dropdown>
    </Spin>
  );
}

export default connect()(AllToggler);
