import Cookies from "js-cookie";

import { get, post, firstOrEmptyObj, postForm } from "./utils";

window.Cookies = Cookies;

const getEmail = emailPrefix => `${emailPrefix}@${Cookies.get("emailSuffix") || "northeastern.edu"}`;

export const auth = {
  sendToken: ({ emailPrefix }) => post("/api/send-token/", { email: getEmail(emailPrefix) }),
  signInToken: ({ emailPrefix, token }) => post("/api/sign-in-token/", { email: getEmail(emailPrefix), token }),
  signInKey: ({ key }) => post("/api/sign-in-key/", { key }),
  signInPassword: ({ username, password }) => post("/api/sign-in-password/", { username, password }),
  changePassword: ({ password }) => post("/api/change-password/", { password }),
  resetPassword: ({ username }) => post("/api/reset-password/", { username }),
  whoami: () => get("/api/whoami/"),
  signOut: () => post("/api/sign-out/"),
  photo: {
    retrieve: () => get("/api/profile-photo/").then(firstOrEmptyObj),
    submit: data => postForm("/api/profile-photo/", data).then(firstOrEmptyObj)
  }
};
