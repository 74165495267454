import * as React from "react";
import { Alert } from "antd";

import Approval from "../../student/approve/Approval";

export default function ApprovalBefore() {
  return (
    <div style={{ maxWidth: 800, margin: "0 auto" }}>
      <Alert
        description={
          <span>
            Students can start a new co-op approval request here by clicking the Start New button. <br />
            All the validations works the same as is. After uploading docs and filling all the fields correctly and
            clicking the Submit button, you will be redirected to the After Submission preview page.
          </span>
        }
        type="info"
        message="This page is a preview."
        showIcon
        closable
        style={{ marginBottom: "1em" }}
      ></Alert>
      <Approval facultyPreview></Approval>
    </div>
  );
}
