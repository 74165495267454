import * as React from "react";
import { connect } from "react-redux";
import { Spin } from "antd";

import { ComponentProps } from "../../store/types";
import { actions } from "../../store";
import Filter from "../faculty/search/Filter";
import List from "../faculty/search/List";

class SearchComp extends React.Component<ComponentProps> {
  componentDidMount() {
    Promise.all([
      this.props.dispatch(actions.selection.getAllSelections()),
      this.props.dispatch(actions.assessment.loadSections())
    ]).then(() => this.props.dispatch(actions.search.initSearch()));
  }

  isSpinning() {
    return !(this.props.selection.selectionGot && this.props.search.dataGot);
  }

  render() {
    return (
      <Spin spinning={this.isSpinning()} style={{ width: "100%" }}>
        {!this.isSpinning() && (
          <div>
            <Filter />
            <List selectable={true} />
          </div>
        )}
      </Spin>
    );
  }
}

export default connect(state => state)(SearchComp);
