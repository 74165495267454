import { message } from "antd";
import Cookies from "js-cookie";

export const buildURL = url => url;

export const toJSON = res => {
  if (!res.ok) {
    if (res.status === 403) {
      // message.error("Your session has expired. Please start from the beginning.");
      // TODO:
    } else if (res.status === 401) {
      message.error("Authentication failed, reason: ");
    } else {
      res.text().then(t => message.error(res.statusText + "\n" + t));
    }
    return Promise.reject(res);
  } else {
    return res.json();
  }
};

export const get = url =>
  fetch(buildURL(url), {
    credentials: "include"
  }).then(toJSON);

const _update = method => (url, data) =>
  fetch(buildURL(url), {
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    credentials: "include",
    method
  }).then(toJSON);

export const post = _update("POST");
export const put = _update("PUT");
export const patch = _update("PATCH");
export const del = (url, data) =>
  fetch(buildURL(url), {
    body: JSON.stringify(data),
    headers: {
      "content-type": "application/json",
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    credentials: "include",
    method: "DELETE"
  });

// export const del = url => fetch(buildURL(url), {
//   credentials: 'include',
//   method: 'DELETE'
// }).then(toJSON)

export const postForm = (url, data) =>
  fetch(buildURL(url), {
    body: data,
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken")
    },
    credentials: "include",
    method: "POST"
  }).then(toJSON);

export const firstOrEmptyObj = x => (x.length ? x[0] : {});

export const makeGetSelection = path => param =>
  get(path).then(selection => {
    const all = param && param.all;
    if (!all) {
      Object.keys(selection).forEach(key => {
        selection[key] = selection[key].filter(one => !one.isHidden);
      });
    }
    return selection;
  });
