import * as React from "react";
import { Spin, Button, Icon, Badge } from "antd";
import { connect } from "react-redux";

import List from "./List";
import Filter from "./Filter";
import SearchDrawer from "./CartDrawer";
import ExportButton from "./ExportButton";
import EmployerLinkModal from "./EmployerLinkModal";
import { actions } from "../../../store";
import { ComponentProps, RootState } from "../../../store/types";

class SearchComponent extends React.Component<ComponentProps> {
  componentDidMount() {
    Promise.all([
      this.props.dispatch(actions.assessment.loadSections()),
      this.props.dispatch(actions.selection.getAllSelections()),
      this.props.dispatch(actions.userList.load()),
      this.props.dispatch(actions.loadEmployer()),
    ]).then(() => this.props.dispatch(actions.search.initSearch()));
  }

  isSpinning() {
    return !(this.props.selection.selectionGot && this.props.userList.dataGot && this.props.search.dataGot);
  }

  render() {
    return (
      <Spin spinning={this.isSpinning()} style={{ width: "100%" }}>
        {!this.isSpinning() && (
          <div>
            <Filter />
            <List selectable={true} />
            <Handle />
            <ExportButton />
            <SearchDrawer />
            <EmployerLinkModal />
          </div>
        )}
      </Spin>
    );
  }
}

const HandleComp = ({ isCartDrawerOpen, number, dispatch }) => (
  <div
    style={{
      position: "fixed",
      bottom: 24,
      right: 0,
    }}
  >
    <Button
      type="primary"
      shape="circle"
      hidden={isCartDrawerOpen}
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: 48,
        height: 48,
      }}
      onClick={() =>
        number ? dispatch(actions.search.toggleCartDrawer()) : dispatch(actions.search.toggleListDrawer())
      }
    >
      {" "}
      {number ? (
        <Badge offset={[0, -10]} count={number}>
          <Icon type="shopping-cart" style={{ fontSize: 24 }} />
        </Badge>
      ) : (
        <Icon type="menu-fold" style={{ fontSize: 24 }} />
      )}
    </Button>
  </div>
);

const Handle = connect((state: RootState) => ({
  isCartDrawerOpen: state.search.isCartDrawerOpen,
  number: state.search.selectedIdxs.length,
}))(HandleComp);

const Search = connect((state) => state)(SearchComponent);

export default Search;
