import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../store/types";
import { message } from "antd";
import Cookies from "js-cookie";

import Fab from "./Fab";
import { downloadExcel } from "../../../utils";

const exportSearchResult = (data) => {
  const allCoursesSet = new Set<string>();
  for (const record of data) {
    const { information: info } = record;
    if (!info) {
      continue;
    }
    info.coursesCompleted.forEach((x) => allCoursesSet.add(x.number));
  }
  const allCourses = Array.from(allCoursesSet);
  const coursesMap = {};
  allCourses.forEach((c, i) => {
    coursesMap[c] = i;
  });

  const headers = [
    "NUID",
    "First Name",
    "Last Name",
    "Program",
    "Campus",
    "Adviser",
    "Is Hidden",
    "Phone",
    "Email",
    "Entrance Date",
    "Availability",
    "Exp Graduation Date",
    "About Me",
    "Academic Projects",
    "Work Experiences",
    "GitHub",
    "LinkedIn",
    "Courses Completed",
    "Skill Tags",
    "Top Skill Tags",
    "Resume",
  ];
  const aoa = [headers];
  const coursesAoa = [["NUID", "First Name", "Last Name"].concat(allCourses)];
  for (const record of data) {
    try {
      const row = [];
      let coursesRow = allCourses.map((x) => false);
      const { profile, information: info } = record;
      row.push(profile.nuid);
      row.push(profile.firstName);
      row.push(profile.lastName);
      row.push(profile.program ? profile.program.text : null);
      row.push(profile.campus ? profile.campus.text : null);
      row.push(profile.coopAdviser ? profile.coopAdviser.text : null);
      row.push(profile.isHidden ? "Yes" : "No");

      if (info) {
        row.push(info.phone);
        row.push(info.email);
        row.push(info.entranceDate);
        row.push(info.availability.map((x) => x.text).join(", "));
        row.push(info.expGraduationDate);
        row.push(info.aboutMe);
        row.push(info.academicProjects);
        row.push(info.workExperience);
        row.push(info.github ? `https://github.com/${info.github}/` : null);
        row.push(info.linkedin ? `https://linkedin.com/in/${info.linkedin}/` : null);
        row.push(info.coursesCompleted.map((x) => x.number).join(", "));
        info.coursesCompleted.forEach((x) => {
          coursesRow[coursesMap[x.number]] = true;
        });
        row.push(info.skillTags.data.map((x) => x.text).join(", "));
        row.push(info.topSkillTags.data.map((x) => x.skillTag.text).join(", "));
      } else {
        for (let i = 0; i < 13; i++) {
          row.push(null);
        }
        coursesRow = allCourses.map((x) => null);
      }

      row.push(record.resume.link);

      aoa.push(row);
      coursesAoa.push([profile.nuid, profile.firstName, profile.lastName].concat(coursesRow));
    } catch (error) {
      message.warning(`Export Error: ${error}`);
      console.error(error);
    }
  }
  const missingRows = data.length + 1 - aoa.length;
  if (missingRows) {
    message.warning(`${missingRows} row(s) omitted due to error.`);
  }
  message.info(`Exporting ${aoa.length - 1} rows...`);

  const all = Boolean(Cookies.get("searchAll"));
  const filename = `Search Result(${all ? "include hidden" : "exclude hidden"})`;
  downloadExcel(filename, { aoa, sheetName: "Search Results" }, { aoa: coursesAoa, sheetName: "Courses" });
};

const ExportButton = connect((state: RootState) => ({
  hidden: state.search.isListDrawerOpen,
  iconType: "download",
  idx: 1,
  onClick: () => exportSearchResult(state.search.data),
}))(Fab);

export default ExportButton;
