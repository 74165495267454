import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Layout, Menu, Row, Col, Icon, Spin, Badge } from "antd";

import { actions } from "../store";
import { ComponentProps } from "../store/types";

class HeaderMenuComp extends React.Component<ComponentProps> {
  renderMainMenu() {
    const homeLink = this.props.auth.role !== null ? `/${this.props.auth.role}` : "/";
    if (this.props.auth.role !== "faculty") {
      return (
        <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }} selectedKeys={["home"]}>
          <Menu.Item key="home">
            <Link to={homeLink}>Co-op App</Link>
          </Menu.Item>
        </Menu>
      );
    } else {
      // { text: "Entrance Date", link: "/faculty/selection/entrance-date", key: "entrance-date" },
      // { text: "Exp Graduation Date", link: "/faculty/selection/exp-graduation-date", key: "exp-graduation-date" },
      // { text: "Availibility", link: "/faculty/selection/availability", key: "availability" },
      // { text: "Course", link: "/faculty/selection/course", key: "course" },
      // { text: "Skill Tag", link: "/faculty/selection/skill-tag", key: "skill-tag" },
      // { text: "Program", link: "/faculty/selection/program", key: "program" },
      // { text: "Campus", link: "/faculty/selection/campus", key: "campus" }
      return (
        <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }} selectedKeys={["home"]}>
          <Menu.Item key="home">
            <Link to={homeLink}>Co-op App</Link>
          </Menu.Item>
          <Menu.Item key="dashboard">
            <Link to="/faculty/dashboard">Dashboard(WIP)</Link>
          </Menu.Item>
          <Menu.Item key="search">
            <Link to="/faculty/search">Search</Link>
          </Menu.Item>
          <Menu.Item key="employer">
            <Link to="/faculty/employer">Employers</Link>
          </Menu.Item>
          <Menu.Item key="students">
            <Link to="/faculty/students">Students</Link>
          </Menu.Item>
          <Menu.SubMenu key="approval" title={
            <Badge count={this.props.approvalFaculty.badgeCount} offset={[10, -5]}>
              Approval
            </Badge>
          }>
            <Menu.Item key="pending-approval">
              <Link to="/faculty/pending-approval">Pending</Link>
            </Menu.Item>
            <Menu.Item key="approval-old">
              <Link to="/faculty/approval">Historical</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="admin" title="Admin">
            <Menu.Item key="survey-fields">
              <Link to="/faculty/survey-fields">Survey Fields</Link>
            </Menu.Item>
            <Menu.Item key="advisers">
              <Link to="/faculty/selection/advisers">Advisers</Link>
            </Menu.Item>
            <Menu.Item key="courses">
              <Link to="/faculty/selection/courses">Courses</Link>
            </Menu.Item>
            <Menu.Item key="assessments">
              <Link to="/faculty/selection/assessments">Assessments</Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu key="student-page-preview" title="Student Page Preview">
            <Menu.ItemGroup title="Profile">
              <Menu.Item key="p1">
                <Link to="/faculty/preview/0-basic-profile">0-Basic Profile</Link>
              </Menu.Item>
              <Menu.Item key="p2">
                <Link to="/faculty/preview/1-tech-self-assessment">1-Tech Self Assessment</Link>
              </Menu.Item>
              <Menu.Item key="p3">
                <Link to="/faculty/preview/2-information">2-Information</Link>
              </Menu.Item>
              <Menu.Item key="p4">
                <Link to="/faculty/preview/3-resume">3-Resume</Link>
              </Menu.Item>
            </Menu.ItemGroup>
            <Menu.ItemGroup title="Approval">
              <Menu.Item key="s1">
                <Link to="/faculty/preview/approval-before-submission">Before Submission</Link>
              </Menu.Item>
              <Menu.Item key="s2">
                <Link to="/faculty/preview/approval-after-submission">After Submission</Link>
              </Menu.Item>
            </Menu.ItemGroup>
          </Menu.SubMenu>
        </Menu>
      );
    }
  }

  renderExtraMenu() {
    switch (this.props.auth.role) {
      case "student":
        return (
          <Menu.Item key="profile">
            <Link to="/student/profile">
              <Icon type="user" /> Profile
            </Link>
          </Menu.Item>
        );
      case "faculty":
        return (
          <Menu.Item key="password">
            <Link to="/faculty/password">
              <Icon type="lock" /> Password
            </Link>
          </Menu.Item>
        );
      default:
        return;
    }
  }

  render() {
    return (
      <Layout.Header style={{ padding: 0 }}>
        <Row type="flex" justify="space-between">
          <Col>{this.renderMainMenu()}</Col>
          {this.props.auth.user && this.props.auth.user.id && (
            <Col>
              <Spin spinning={this.props.auth.whoamiLoading}>
                <Menu theme="dark" mode="horizontal" style={{ lineHeight: "64px" }} selectedKeys={[]}>
                  <Menu.SubMenu
                    title={
                      <div>
                        {this.props.auth.user.email} <Icon type="down" />
                      </div>
                    }
                  >
                    {this.renderExtraMenu()}
                    <Menu.Item key="logout" onClick={() => this.props.dispatch(actions.signOut())}>
                      <Icon type="logout" /> Sign Out
                    </Menu.Item>
                  </Menu.SubMenu>
                </Menu>
              </Spin>
            </Col>
          )}
        </Row>
      </Layout.Header>
    );
  }
}

const HeaderMenu = connect(state => state)(HeaderMenuComp);

export default HeaderMenu;
