import * as React from "react";
import {
  Table,
  Tag,
  Button,
  Dropdown,
  Menu,
  Icon,
  Modal,
  Card,
  Avatar,
  Row,
  Col,
  Tooltip,
  Form,
  Input,
  message,
  DatePicker,
} from "antd";
import { ColumnProps } from "antd/lib/table";
import Highlighter from "react-highlight-words";
import moment = require("moment");
import SurveyForm from "../../student/approve/SurveyForm";
import { ComponentProps } from "../../../store/types";
import ApprovalTimeline from "./ApprovalTimeline";
import "./ApprovalTable.css";
import { FormComponentProps } from "antd/lib/form";
import { ModalProps } from "antd/lib/modal";
import { actions } from "../../../store";
import { downloadExcel } from "../../../utils";

const Description: React.FC<{ term: string; span?: number }> = ({ term, span = 8, children }) => (
  <Col span={span} className="approval-description">
    <span className="term">{term}</span>
    <span>{children}</span>
  </Col>
);

const getDuritionDisplay = (approval) => {
  if (!approval.form) {
    return;
  }
  const start = moment(approval.form.startDate);
  if (approval.extensions.length === 0) {
    const end = moment(approval.form.endDate);
    const diff = end.diff(start, "days") + 1;
    return `${start.format("L")} ~ ${end.format("L")} (${diff} days)`;
  } else {
    const end = moment(approval.extensions.slice(-1)[0].newEndDate);
    const diff = end.diff(start, "days") + 1;
    const preEnds = [moment(approval.form.endDate)]
      .concat(approval.extensions.slice(0, -1).map(({ newEndDate }) => newEndDate))
      .map((date) => moment(date).format("L"));
    return (
      <span>
        {`${start.format("L")} ~ ${end.format("L")} (${diff} days, `}
        <Tooltip
          title={
            <s>
              {preEnds.map((x) => (
                <span>
                  {x}
                  <br />
                </span>
              ))}
            </s>
          }
        >
          <a>Extended</a>
        </Tooltip>
        )
      </span>
    );
  }
};

interface ActionModalFormCompProps extends FormComponentProps, ModalProps {
  type: string;
  approval: any;
  dispatch: any;
}

class ActionModalFormComp extends React.Component<ActionModalFormCompProps> {
  get actionModalTitle() {
    switch (this.props.type) {
      case "coop":
        return "Approve(Co-op)";
      case "academic":
        return "Approve(academic)";
      case "reject":
        return "Reject";
      case "extend":
        const { firstName, lastName } = this.props.approval.studentprofile;
        return `Extend Co-op for ${firstName} ${lastName}`;
      default:
        return "";
    }
  }
  get description() {
    const p = this.props.approval.studentprofile;
    const from = `${p.firstName} ${p.lastName}(${p.nuid})`;
    switch (this.props.type) {
      case "coop":
      case "academic":
        const as = this.props.type === "coop" ? "the Co-op adviser" : "the program director";
        return (
          <p>
            You are going to approve the request from <b>{from}</b> as {as}
          </p>
        );
      case "reject":
        return (
          <p>
            You are gong to <b>reject</b> the request from <b>{from}</b>
          </p>
        );
      default:
        return "";
    }
  }

  get okText() {
    switch (this.props.type) {
      case "coop":
        return "Approve(Co-op)";
      case "academic":
        return "Approve(Academic)";
      case "reject":
        return "Reject";
      case "extend":
        return "Extend";
      default:
        return "";
    }
  }

  ok(e) {
    const { form, dispatch } = this.props;
    form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        let p;
        if (this.props.type === "extend") {
          p = dispatch(
            actions.approvalFaculty.extendCoop({
              id: this.props.approval.id,
              comment: values.comment || "",
              newEndDate: values.newEndDate.format("YYYY-MM-DD"),
            })
          ).then(() => {
            message.success("Submitted.");
            form.resetFields();
            this.props.onCancel(e);
          });
        } else {
          p = dispatch(
            actions.approvalFaculty.modifyApproval(this.props.type, this.props.approval.id, values.comment)
          ).then(() => dispatch(actions.approvalFaculty.updateApprovalBadge()));
        }
        p.then(() => {
          message.success("Submitted");
          form.resetFields();
          this.props.onCancel(e);
        });
      }
    });
  }

  renderContent() {
    const { getFieldDecorator } = this.props.form;
    if (this.props.type === "extend") {
      return (
        <Form>
          <Row>
            <Form.Item label="New End Date">
              {getFieldDecorator("newEndDate", {
                rules: [{ required: true, message: "Please select new end date." }],
              })(
                <DatePicker
                  showToday={false}
                  format="MM/DD/YYYY"
                  style={{ width: "100%" }}
                  placeholder="Select new end date"
                />
              )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Comment">{getFieldDecorator("comment")(<Input.TextArea />)}</Form.Item>
          </Row>
        </Form>
      );
    } else {
      return (
        <React.Fragment>
          {this.description}
          <Row style={{ marginTop: 10 }}>
            <Description span={24} term="Company">
              {this.props.approval.form.companyName}
            </Description>
            <Description span={24} term="Position">
              {this.props.approval.form.positionTitle}
            </Description>
            <Description span={24} term="Dates">
              {getDuritionDisplay(this.props.approval)}
            </Description>
          </Row>
          <Form>
            <Form.Item label="Comment">
              {getFieldDecorator("comment", {
                initialValue: "",
                rules: [{ required: this.props.type === "reject" }],
              })(<Input.TextArea />)}
            </Form.Item>
          </Form>
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <Modal
        {...this.props}
        title={this.actionModalTitle}
        onCancel={(e) => {
          this.props.form.resetFields();
          this.props.onCancel(e);
        }}
        okText={this.okText}
        okType={this.props.type === "reject" ? "danger" : "primary"}
        onOk={(e) => this.ok(e)}
      >
        {this.props.approval && this.renderContent()}
      </Modal>
    );
  }
}

const ActionModalForm = Form.create<ActionModalFormCompProps>()(ActionModalFormComp);

export default class ApprovalTable extends React.Component<
  ComponentProps & { data: any[]; downloadFilename: string },
  {
    actionModalVisible: boolean;
    actionModalType: string;
    actionModalData: any;
    surveyModalVisible: boolean;
    surveyModalData: any;
    searchTexts: { [key: string]: string };
  }
> {
  searchInputs = { "studentprofile.fullName": null, "studentprofile.nuid": null, "form.companyName": null };
  constructor(props) {
    super(props);
    this.state = {
      actionModalVisible: false,
      actionModalType: "",
      actionModalData: null,
      surveyModalVisible: false,
      surveyModalData: null,
      searchTexts: { "studentprofile.fullName": "", "studentprofile.nuid": "", "form.companyName": "" },
    };
  }

  get surveyModalTitle() {
    if (this.state.surveyModalData === null) {
      return "";
    }
    const { firstName, lastName, nuid } = this.state.surveyModalData.studentprofile;
    return `${firstName} ${lastName}(${nuid})`;
  }

  get id2idx() {
    const id2idx = {};
    this.props.data.forEach((approval, idx) => {
      id2idx[approval.id] = idx;
    });
    return id2idx;
  }

  searchableColumn(title, dataIndex, getRecordValue) {
    const searchInput = this.searchInputs[dataIndex];
    return {
      title,
      dataIndex,
      filterIcon: (filtered) => <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />,
      onFilterDropdownVisibleChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput && searchInput.select());
        }
      },
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        return (
          <Input.Search
            ref={(node) => {
              this.searchInputs[dataIndex] = node && (node as any).input;
            }}
            onSearch={(name) => {
              setSelectedKeys([name]);
              this.setState({ searchTexts: { ...this.state.searchTexts, [dataIndex]: name } });
              confirm();
            }}
          />
        );
      },
      onFilter: (value, record) => {
        const recordValue = getRecordValue(record);
        return recordValue.toLowerCase().includes(value.toLowerCase());
      },
      render: (value) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchTexts[dataIndex]]}
          autoEscape
          textToHighlight={(value || "").toString()}
        />
      ),
    };
  }

  getColumns(): ColumnProps<any>[] {
    return [
      this.searchableColumn("NUID", "studentprofile.nuid", (r) => r.studentprofile.nuid),
      this.searchableColumn("Full Name", "studentprofile.fullName", (r) => r.studentprofile.fullName),
      this.searchableColumn("Company", "form.companyName", (r) => (r.form ? r.form.companyName : "")),
      // { title: "Company", dataIndex: "form.companyName" },
      { title: "Position", dataIndex: "form.positionTitle" },
      { title: "Wage", dataIndex: "form.hourlyWage" },
      {
        title: "Dates",
        key: "dates",
        render: (_, record) => getDuritionDisplay(record),
      },
      {
        title: "Adviser",
        dataIndex: "studentprofile.coopAdviser.text",
        filters: this.props.selection.rawSelection["coopAdviser"].map(({ id, text }) => ({ text, value: id })),
        onFilter: (value, record) => record.studentprofile.coopAdviser.id === value,
      },
      {
        title: "Campus",
        dataIndex: "studentprofile.campus.text",
        filters: this.props.selection.rawSelection["campus"].map(({ id, text }) => ({ text, value: id })),
        onFilter: (value, record) => record.studentprofile.campus.id === value,
      },
      {
        title: "Survey",
        key: "survey",
        render: (t, record) => <a onClick={() => this.showSurveyModal(record.id)}>Survey</a>,
      },
      { title: "Submitted", dataIndex: "finishedAt", render: (x) => (x ? moment(x).fromNow() : "") },
      {
        title: "",
        key: "action",
        render: (_, record) => {
          if (record.pending) {
            return (
              <Dropdown
                overlay={
                  <Menu>
                    {!record.academicAdviserApproved && (
                      <Menu.Item onClick={() => this.showActionModal("academic", record.id)}>
                        Approve(Academic)
                      </Menu.Item>
                    )}
                    {!record.coopAdviserApproved && (
                      <Menu.Item onClick={() => this.showActionModal("coop", record.id)}>Approve(Co-op)</Menu.Item>
                    )}
                    <Menu.Item onClick={() => this.showActionModal("reject", record.id)}>Reject</Menu.Item>
                  </Menu>
                }
              >
                <Button type="primary" size="small">
                  Actions <Icon type="down" />
                </Button>
              </Dropdown>
            );
          } else if (record.rejected) {
            return <Tag color="red">Rejected</Tag>;
          } else if (record.approved) {
            return <Tag color="green">Approved</Tag>;
          } else {
            return <Tag color="blue">Unfinished</Tag>;
          }
        },
      },
    ];
  }

  showActionModal(type, id) {
    const actionModalData = this.props.data.find((approval) => approval.id === id);
    this.setState({ actionModalVisible: true, actionModalType: type, actionModalData: actionModalData });
  }

  showSurveyModal(id) {
    const surveyModalData = this.props.data.find((approval) => approval.id === id);
    this.setState({ surveyModalVisible: true, surveyModalData });
  }

  expandedRowRender(record) {
    if (!record.finished) {
      return "Unfinished...";
    }
    const x = { ...record };
    delete x["survey"];
    delete x["doc"];
    delete x["form"];
    delete x["profilePhoto"];
    const profile = record.studentprofile;
    const { isHidden, isActive } = profile.user;
    return (
      <React.Fragment>
        <Card>
          <Card.Meta
            avatar={<Avatar shape="square" size={96} src={record.profilePhoto.photo} />}
            description={
              <Row>
                <Col span={17}>
                  <h3>Position Detail</h3>
                  <Row>
                    <Description term="Company Name">{record.form.companyName}</Description>
                    <Description term="Department">{record.form.department}</Description>
                    <Description term="Position Title">{record.form.positionTitle}</Description>
                    <Description term="Hourly Wage">{record.form.hourlyWage}</Description>
                  </Row>
                  <Row>
                    <Description term="Manager Name">{record.form.managerName}</Description>
                    <Description term="Manager Email">{record.form.managerEmail}</Description>
                    <Description term="Manager Phone">{record.form.managerPhone}</Description>
                  </Row>
                  <Row>
                    <Description term="Recruiter Name">{record.form.recruiterName}</Description>
                    <Description term="Recruiter Email">{record.form.recruiterEmail}</Description>
                    <Description term="Recruiter Phone">{record.form.recruiterPhone}</Description>
                  </Row>
                  <h3 style={{ marginTop: 10 }}>Student Profile</h3>
                  <Row>
                    <Description term="NUID">{profile.nuid}</Description>
                    <Description term="Email">{profile.user.email}</Description>
                    <Description term="First Name">{profile.firstName}</Description>
                    <Description term="Last Name">{profile.lastName}</Description>
                    <Description term="Program">{profile.program.text}</Description>
                    <Description term="Campus">{profile.campus && profile.campus.text}</Description>
                    <Description term="Last Sign In">
                      {profile.user.lastLogin && moment(profile.user.lastLogin).format("lll")}
                    </Description>
                  </Row>
                  <Row type="flex" align="bottom">
                    <Col>
                      <h3 style={{ marginTop: 10 }}>Student Account Status</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Description term="Is Hidden">
                      {
                        <Tooltip
                          title={
                            isHidden
                              ? "This student is hidden from search page and generated lists."
                              : "This student is shown from search page and generated lists"
                          }
                        >
                          <Tag color={isHidden ? "volcano" : "green"}>{isHidden ? "Yes" : "No"}</Tag>
                        </Tooltip>
                      }
                    </Description>
                    <Description term="Is Active">
                      {
                        <Tooltip title={isActive ? "This student can use this App." : "This student is blocked out"}>
                          <Tag color={isActive ? "green" : "volcano"}>{isActive ? "Yes" : "No"}</Tag>
                        </Tooltip>
                      }
                    </Description>
                  </Row>
                  <h3 style={{ marginTop: 10 }}>Docs</h3>
                  <Button icon="download" size="small" href={record.doc.offerLetter} target="_blank">
                    Offer Letter
                  </Button>
                  <br />
                  <Button icon="download" size="small" href={record.doc.jobDescription} target="_blank">
                    Job Description
                  </Button>
                </Col>
                <Col span={7}>
                  <ApprovalTimeline onAction={(type) => this.showActionModal(type, record.id)} approval={record} />
                </Col>
              </Row>
            }
          />
        </Card>
      </React.Fragment>
    );
  }

  export() {
    const headers = [
      "NUID",
      "First Name",
      "Last Name",
      "Husky Email",
      "Program",
      "Campus",
      "Adviser",
      "Last Sign In",
      "Is Hidden",
      "Is Active",
      "Company",
      "Department",
      "Position Title",
      "Hourly Wage",
      "Start Date",
      "End Date",
      "Manager Name",
      "Manager Email",
      "Manager Phone",
      "Recruiter Name",
      "Recruiter Email",
      "Recruiter Phone",
      "Submitted At",
      "Pending",
      "Co-op Adviser Approved",
      "Co-op Adviser Approved At",
      "Co-op Adviser Approved By",
      "Co-op Adviser Approved Comment",
      "Program Director Approved",
      "Program Director Approved At",
      "Program Director Approved By",
      "Program Director Approved Comment",
      "Rejected",
      "Rejected At",
      "Rejected By",
      "Rejected Reason",
      "Offer Letter",
      "Job Description",
      "Survey Helpful Courses",
      "Survey Co-op Source",
      "Survey Co-op Source NUcareers Job Id",
      "Survey Co-op Source Other",
      // below survey fields are dynamic
      "Survey Company Applied",
      "Survey Company Interviewed",
      "Job Category",
      "Remarks",
    ];

    // TODO: make this dynamic
    // const surveyFields = this.props.selection.surveyFields.filter(x => x.isHidden === false);
    // surveyFields.forEach(surveyField => {
    //   headers.push(`Survey ${surveyField.label}`);
    //   if (surveyField.selectionHasOther) {
    //     headers.push(`Survey ${surveyField.label} Other`);
    //   }
    // });

    const { selections: jobCategorySelections } = this.props.selection.surveyFields.find(
      (surveyField) => surveyField.id === 4
    );
    const aoa = [headers];
    for (const record of this.props.data) {
      try {
        const { studentprofile: profile, user, form, doc, survey } = record;
        const row = [];
        row.push(profile.nuid);
        row.push(profile.firstName);
        row.push(profile.lastName);
        row.push(user.username);
        row.push(profile.program.text);
        row.push(profile.campus.text);
        row.push(profile.coopAdviser.text);
        row.push(user.lastLogin);
        row.push(user.isHidden);
        row.push(user.isActive);
        row.push(form.companyName);
        row.push(form.department);
        row.push(form.positionTitle);
        row.push(form.hourlyWage);
        row.push(form.startDate);
        row.push(form.endDate);
        row.push(form.managerName);
        row.push(form.managerEmail);
        row.push(form.managerPhone);
        row.push(form.recruiterName);
        row.push(form.recruiterEmail);
        row.push(form.recruiterPhone);
        row.push(record.finishedAt);
        row.push(record.pending);
        row.push(record.coopAdviserApproved);
        row.push(record.coopAdviserApprovedAt);
        row.push(record.coopAdviserApprovedBy && record.coopAdviserApprovedBy.email);
        row.push(record.coopAdviserApprovedComment);
        row.push(record.academicAdviserApproved);
        row.push(record.academicAdviserApprovedAt);
        row.push(record.academicAdviserApprovedBy && record.academicAdviserApprovedBy.email);
        row.push(record.academicAdviserApprovedComment);
        row.push(record.rejected);
        row.push(record.rejectedAt);
        row.push(record.rejectedBy);
        row.push(record.rejectedComment);
        row.push(doc.offerLetter);
        row.push(doc.jobDescription);
        row.push(
          survey.helpfulCourses
            .map((courseId) => {
              const course = this.props.selection.selectionMap.course[courseId];
              return `${course.number} ${course.name}`;
            })
            .join("\n")
        );
        row.push(
          { CF: "CCareer Fair", NC: "NUcareer", OL: "Online", NW: "Networking", OT: "Other" }[survey.coopSource.source]
        );
        row.push(survey.coopSource.nucareersJobId);
        row.push(survey.coopSource.sourceOther);

        // TODO: make this dynamic
        const companyAppliedAnswer = survey.answers.find((answer) => answer.field === 1);
        row.push(companyAppliedAnswer ? parseInt(companyAppliedAnswer.answer, 10) : null);

        const companyInterviewedAnswer = survey.answers.find((answer) => answer.field === 2);
        row.push(companyInterviewedAnswer ? parseInt(companyInterviewedAnswer.answer, 10) : null);

        const jobCategoryAnswer = survey.answers.find(({ field }) => field === 4);
        if (!jobCategoryAnswer || jobCategoryAnswer.isOther) {
          row.push(jobCategoryAnswer.answer);
        } else {
          const selection = jobCategorySelections.find(({ id }) => id === parseInt(jobCategoryAnswer.answer), 10);
          row.push(selection.text);
        }

        const remarksAnswer = survey.answers.find(({ field }) => field === 5);
        row.push(remarksAnswer ? remarksAnswer.answer : null);

        aoa.push(row);
      } catch (error) {
        message.warning(`Export Error: ${error}`);
        console.error(record);
      }
    }

    const missingRows = this.props.data.length + 1 - aoa.length;
    if (missingRows) {
      message.warning(`${missingRows} row(s) omitted due to error.`);
    }
    message.info(`Exporting ${aoa.length - 1} rows...`);
    downloadExcel(this.props.downloadFilename, { aoa });
  }

  render() {
    return (
      <React.Fragment>
        <Table
          dataSource={this.props.data}
          columns={this.getColumns()}
          rowKey="id"
          size="small"
          pagination={{
            showSizeChanger: true,
            pageSizeOptions: ["1", "10", "20", "50", "150", "500"],
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} records`,
          }}
          expandedRowRender={(record) => this.expandedRowRender(record)}
          footer={() => (
            <Button icon="download" size="small" block={true} type="primary" onClick={() => this.export()}>
              Export Table Data
            </Button>
          )}
        />
        <Modal
          visible={this.state.surveyModalVisible}
          onCancel={() => this.setState({ surveyModalVisible: false })}
          afterClose={() => this.setState({ surveyModalData: null })}
          title={this.surveyModalTitle}
          footer={null}
        >
          {this.state.surveyModalData && (
            <SurveyForm
              data={this.state.surveyModalData.survey}
              editable={false}
              surveyFields={this.props.selection.surveyFields}
            />
          )}
        </Modal>
        <ActionModalForm
          dispatch={this.props.dispatch}
          type={this.state.actionModalType}
          approval={this.state.actionModalData}
          visible={this.state.actionModalVisible}
          onCancel={() => this.setState({ actionModalVisible: false })}
          afterClose={() => this.setState({ actionModalData: null, actionModalType: null })}
        />
      </React.Fragment>
    );
  }
}
