import * as React from "react";
import { Row, Col, Radio, Modal } from "antd";

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const answers2Obj = answers => {
  const res = {};
  answers.forEach(one => {
    res[one.question] = one.answer;
  });
  return res;
};

const SelfAssessmentQuestion = ({ sectionNumber, number, question, answerObj }) => (
  <Row>
    <Col>{`${sectionNumber}.${number}. ${question.title}`}</Col>
    <Col>
      <RadioGroup value={answerObj[question.id] || "NE"} size="small">
        <RadioButton value="NE">No Experience</RadioButton>
        <RadioButton value="BG">Beginner</RadioButton>
        <RadioButton value="IM">Intermediate</RadioButton>
        <RadioButton value="AD">Advanced</RadioButton>
      </RadioGroup>
    </Col>
  </Row>
);

const SelfAssessmentSection = ({ number, section, answerObj }) => (
  <div>
    <h4 style={{ marginTop: 12 }}>{`${number}. ${section.title}`}</h4>
    {section.questions.map((question, i) => (
      <SelfAssessmentQuestion key={i} question={question} answerObj={answerObj} number={i + 1} sectionNumber={number} />
    ))}
  </div>
);

const SelfAssessment = ({ sections, answers }) => {
  const answerObj = answers2Obj(answers);
  return (
    <div>
      {sections.map((section, i) => (
        <SelfAssessmentSection key={i} section={section} answerObj={answerObj} number={i + 1} />
      ))}
    </div>
  );
};

export default SelfAssessment;
