import * as React from "react";
import { Row, Col, Card, Typography, Spin, Tooltip, Icon, Input } from "antd";

import * as api from "../../api";

const { Title } = Typography;

interface DashboardData {
  readonly approval: {
    readonly approved: number;
    readonly pending: number;
    readonly rejected: number;
    readonly total: number;
  };
}

export default function Dashboard() {
  const [data, setData] = React.useState<DashboardData>();
  React.useEffect(() => {
    api.admin.dashboard().then(setData);
  }, []);

  return (
    <Spin spinning={!data}>
      {data && (
        <Row gutter={16}>
          <Col>
            {/* <Card
              title="Approved Co-op Requests"
              extra={
                <Tooltip title="Approved / Pending">
                  <Icon type="question-circle"></Icon>
                </Tooltip>
              }
            >
              <Title level={2}>
                {data.approval.approved} / {data.approval.pending}
              </Title>
            </Card> */}
            <Input.TextArea autosize value={JSON.stringify(data, null, 4)}></Input.TextArea>
          </Col>
        </Row>
      )}
    </Spin>
  );
}
