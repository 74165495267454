import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

type SheetInput = {
  sheetName?: string;
  aoa: Array<Array<any>>;
};

export function downloadExcel(filename: string, ...inputs: Array<SheetInput>) {
  const wb = XLSX.utils.book_new();
  inputs.forEach(({ sheetName, aoa }) => {
    const ws = XLSX.utils.aoa_to_sheet(aoa);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
  });
  const wopts = { bookType: "xlsx", bookSST: false, type: "array" };
  // @ts-ignore
  const wbout = XLSX.write(wb, wopts);
  saveAs(new Blob([wbout], { type: "application/octet-stream" }), `${filename}.xlsx`);
}
