import * as React from "react";
import { connect } from "react-redux";
import { Form, Layout, Divider, Input, Button, message, Spin } from "antd";

import { actions } from "../../store";
import * as api from "../../api";
import { ComponentProps } from "../../store/types";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { Link } from "react-router-dom";

const { Content } = Layout;
const FormItem = Form.Item;

const SignInContent: React.FC<{ form: WrappedFormUtils; signIn: any; toggleReset: any }> = ({
  form,
  signIn,
  toggleReset
}) => {
  const { getFieldDecorator } = form;
  return (
    <React.Fragment>
      <h1 style={{ textAlign: "center" }}>Faculty Sign In</h1>
      <Divider style={{ marginTop: 0 }} />
      <Form>
        <FormItem style={{ marginBottom: 0 }} label="Username: " help="northeastern.edu email prefix by default.">
          {getFieldDecorator("username", { rules: [{ required: true }, { whitespace: true }] })(<Input size="large" />)}
        </FormItem>
        <FormItem label="Password: ">
          {getFieldDecorator("password", { rules: [{ required: true }, { whitespace: true }] })(
            <Input size="large" type="password" />
          )}
        </FormItem>
        <Button type="primary" size="large" block onClick={() => signIn()}>
          Sign In
        </Button>
        <Button size="large" block style={{ marginTop: 20 }} onClick={() => toggleReset()}>
          Reset Password
        </Button>
      </Form>
    </React.Fragment>
  );
};

const ResetContent: React.FC<{ form: WrappedFormUtils; reset: any; toggleReset: any; resetting: boolean }> = ({
  form,
  reset,
  toggleReset,
  resetting
}) => {
  const { getFieldDecorator } = form;
  return (
    <React.Fragment>
      <h1 style={{ textAlign: "center" }}>Reset Password</h1>
      <Divider style={{ marginTop: 0 }} />
      <Form>
        <FormItem label="Username: " help="northeastern.edu email prefix by default.">
          {getFieldDecorator("username", { rules: [{ required: true }, { whitespace: true }] })(<Input size="large" />)}
        </FormItem>
        <Spin spinning={resetting}>
          <Button type="primary" size="large" block onClick={() => reset()} disabled={resetting}>
            Reset
          </Button>
        </Spin>
        <Button size="large" style={{ marginTop: 20 }} block onClick={() => toggleReset()}>
          Back to Sign In
        </Button>
      </Form>
    </React.Fragment>
  );
};

class SignInComponent extends React.Component<
  ComponentProps & FormComponentProps,
  { reset: boolean; resetting: boolean }
> {
  constructor(props) {
    super(props);
    this.state = {
      reset: false,
      resetting: false
    };
  }

  gotoFrom(defaultFrom?) {
    const state = this.props.router.location.state;
    const from = defaultFrom || (state && state.from) || "/faculty";
    this.props.dispatch(actions.push(from));
  }

  signIn() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username, password } = values;
        api.auth.signInPassword({ username, password }).then(({ valid, err }) => {
          if (valid) {
            message.success("Signed in");
            this.gotoFrom();
          } else {
            message.error(err);
          }
        });
      } else {
        message.error(err);
      }
    });
  }

  reset() {
    this.setState({ resetting: true });
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { username } = values;
        api.auth.resetPassword({ username }).then(() => {
          message.success("Reset email sent.");
          this.setState({ resetting: false });
        });
      }
    });
  }

  componentDidMount() {
    const qp = new URLSearchParams(this.props.router.location.search);
    if (qp.has("key")) {
      api.auth.signInKey({ key: qp.get("key") }).then(({ valid, err }) => {
        if (valid) {
          this.gotoFrom(qp.get("from"));
        } else {
          message.error(err);
        }
      });
    }
  }

  toggleReset() {
    this.setState({ reset: !this.state.reset });
  }

  render() {
    const contentProps = {
      form: this.props.form,
      signIn: () => this.signIn(),
      toggleReset: () => this.toggleReset(),
      reset: () => this.reset(),
      resetting: this.state.resetting
    };
    return (
      <Layout>
        <Content style={{ width: 360, margin: "64px auto 0", background: "white", padding: 24 }}>
          {this.state.reset ? <ResetContent {...contentProps} /> : <SignInContent {...contentProps} />}
          <h4 style={{ textAlign: "center" }}>
            <Link to="/student/sign-in">Student Sign In</Link>
          </h4>
        </Content>
      </Layout>
    );
  }
}

export default connect(state => state)(Form.create()(SignInComponent));
