import * as React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { Layout, Alert } from "antd";

import "../node_modules/antd/dist/antd.css";
import "./App.css";

import HeaderMenu from "./components/HeaderMenu";
import Footer from "./components/Footer";
import Changelog from "./components/Changelog";

import {
  TokenSignIn,
  PasswordSignIn,
  StudentHome,
  Search,
  SelfAssessment,
  Information,
  Resume,
  CreateDone,
  StudentProfile,
  Employer,
  PU,
  ChangePassword,
  EmployerSearch,
  StudentList,
  StudentApproval,
  FacultyApproval,
  FacultyPendingApproval,
  SurveyFields,
  Advisers,
  AssessmentAdmin,
  CourseAdmin,
  PreviewProfile,
  PreviewAssessment,
  PreviewInformation,
  PreviewResume,
  PreviewApprovalAfter,
  PreviewApprovalBefore,
  PreviewDone,
  Dashboard
} from "./containers";

import { store, actions, history } from "./store";

export default class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          {/* <Router> */}
          <Layout style={{ minHeight: "100%" }}>
            {/* <Alert
              message="Co-op App is under maintenance tonight."
              description="Functions might not be available during maintenance, will be back on line before tomorrow(Thu, Feb 13, 2020 00:00 AM)."
              banner
            /> */}
            <HeaderMenu />
            <Layout.Content style={{ flex: "1 0 auto", padding: "24px 24px 0" }}>
              <Route exact path="/coop" render={() => <Redirect to="/" />} />
              <Route exact path="/coop/student" render={() => <Redirect to="/student" />} />
              <Route exact path="/coop/faculty" render={() => <Redirect to="/faculty" />} />

              <Route exact path="/" render={() => <Redirect to="/student" />} />
              <Route exact path="/student" component={StudentHome} />
              <Route exact path="/student/sign-in" component={TokenSignIn} />
              <Route exact path="/student/profile" component={StudentProfile} />

              <Route exact path="/student/create" render={() => <Redirect to="/student/create/1-self-assessment" />} />
              <Route exact path="/student/create/1-self-assessment" component={SelfAssessment} />
              <Route exact path="/student/create/2-information" component={Information} />
              <Route exact path="/student/create/3-resume" component={Resume} />
              <Route exact path="/student/create/done" component={CreateDone} />

              <Route exact path="/student/approval" component={StudentApproval} />

              <Route exact path="/faculty" render={() => <Redirect to="/faculty/search" />} />
              <Route exact path="/faculty/dashboard" component={Dashboard} />
              <Route exact path="/faculty/search" component={Search} />
              <Route exact path="/faculty/sign-in" component={PasswordSignIn} />
              <Route exact path="/faculty/password" component={ChangePassword} />
              <Route exact path="/faculty/employer" component={Employer} />
              <Route exact path="/faculty/students" component={PU} />
              <Route exact path="/faculty/approval" component={FacultyApproval} />
              <Route exact path="/faculty/pending-approval" component={FacultyPendingApproval} />

              <Route exact path="/faculty/survey-fields" component={SurveyFields} />
              <Route exact path="/faculty/selection/advisers" component={Advisers} />
              <Route exact path="/faculty/selection/assessments" component={AssessmentAdmin} />
              <Route exact path="/faculty/selection/courses" component={CourseAdmin} />

              <Route exact path="/faculty/preview/0-basic-profile" component={PreviewProfile}></Route>
              <Route exact path="/faculty/preview/1-tech-self-assessment" component={PreviewAssessment}></Route>
              <Route exact path="/faculty/preview/2-information" component={PreviewInformation}></Route>
              <Route exact path="/faculty/preview/3-resume" component={PreviewResume}></Route>
              <Route exact path="/faculty/preview/done" component={PreviewDone}></Route>
              <Route exact path="/faculty/preview/approval-before-submission" component={PreviewApprovalBefore}></Route>
              <Route exact path="/faculty/preview/approval-after-submission" component={PreviewApprovalAfter}></Route>

              <Route exact path="/employer" render={() => <Redirect to="/employer/search" />} />
              <Route exact path="/employer/search" component={EmployerSearch} />
              <Route exact path="/employer/student-list/:b58" component={StudentList} />

              <Route exact path="/changelog" component={Changelog} />
            </Layout.Content>
            <Footer />
          </Layout>
        </ConnectedRouter>
        {/* </Router> */}
      </Provider>
    );
  }
}
