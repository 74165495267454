import * as React from "react";
import { ComponentProps } from "../../../store/types";
import { actions } from "../../../store";
import {
  Divider,
  Table,
  Spin,
  Tag,
  Menu,
  Dropdown,
  Icon,
  Button,
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  Switch,
  message
} from "antd";
import SurveyForm from "../../student/approve/SurveyForm";

import "./SurveyFields.css";
import { FormComponentProps } from "antd/lib/form";
import { Link } from "react-router-dom";

class AddFieldFormComp extends React.Component<
  FormComponentProps,
  { fieldType: string; selections: string[]; selectionInputValue: string }
> {
  constructor(props) {
    super(props);
    this.state = {
      fieldType: "TEXT",
      selections: [],
      selectionInputValue: ""
    };
  }

  selectionInputConfirm() {
    if (this.state.selectionInputValue.length == 0) {
      return;
    }
    this.setState({
      selections: [...this.state.selections, this.state.selectionInputValue],
      selectionInputValue: ""
    });
  }

  renderSelections() {
    if (this.state.fieldType.endsWith("SELECTION")) {
      const { selections } = this.state;
      const { getFieldDecorator } = this.props.form;
      return (
        <React.Fragment>
          <Form.Item label="Selections" required={true}>
            {selections.map((s, i) => (
              <Tag
                closable={true}
                key={`${s}-${i}`}
                onClose={() =>
                  this.setState({
                    selections: this.state.selections.slice(0, i).concat(this.state.selections.slice(i + 1))
                  })
                }
              >
                {s}
              </Tag>
            ))}
            <Input
              placeholder="Type and press 'Enter' to add selection."
              type="text"
              size="small"
              value={this.state.selectionInputValue}
              onChange={e => this.setState({ selectionInputValue: e.target.value })}
              onBlur={() => this.selectionInputConfirm()}
              onPressEnter={() => this.selectionInputConfirm()}
            />
          </Form.Item>
          <Form.Item label="Has Other" help="Student Can Choose 'Other' and Input Any Value.">
            {getFieldDecorator("selectionHasOther", { initialValue: false })(<Switch />)}
          </Form.Item>
        </React.Fragment>
      );
    } else {
      return "";
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form>
        <Form.Item label="Label">
          {getFieldDecorator("label", { rules: [{ required: true }, { whitespace: true }] })(<Input />)}
        </Form.Item>
        <Form.Item label="Field Type" required={true}>
          {getFieldDecorator("fieldType", { initialValue: "TEXT" })(
            <Select<string> onChange={value => this.setState({ fieldType: value })}>
              <Select.Option value="TEXT">TEXT - Single Line Text</Select.Option>
              <Select.Option value="MULTI_TEXT">MULTI_TEXT - Multi-line Text</Select.Option>
              <Select.Option value="NUMBER">NUMBER - Integer Number</Select.Option>
              <Select.Option value="SELECTION">SELECTION - Single Selection</Select.Option>
              <Select.Option value="MULTI_SELECTION">MULTI_SELECTION - Multiple Selection</Select.Option>
            </Select>
          )}
        </Form.Item>
        {this.renderSelections()}
        <Form.Item label="Is Required">
          {getFieldDecorator("isRequired", { initialValue: false })(<Switch />)}
        </Form.Item>
        <Form.Item label="Help Text">{getFieldDecorator("helpText", { initialValue: "" })(<Input />)}</Form.Item>
        <Form.Item label="Placeholder">{getFieldDecorator("placeholder", { initialValue: "" })(<Input />)}</Form.Item>
      </Form>
    );
  }
}

const AddFieldForm = Form.create()(AddFieldFormComp);

export default class SurveyFieldsComp extends React.Component<
  ComponentProps,
  { previewModalVisible: boolean; addModalVisible: boolean; selectionsHasError: boolean }
> {
  constructor(props, private addFieldForm: AddFieldFormComp = null) {
    super(props);
    this.state = {
      previewModalVisible: false,
      addModalVisible: false,
      selectionsHasError: false
    };
  }

  componentDidMount(): void {
    this.props.dispatch(actions.selection.getAllSelections());
    this.props.dispatch(actions.selection.getSurveyFields());
  }

  hideField(id, hide) {
    this.props.dispatch(actions.selection.hideSurveyField(id, hide));
  }

  getColumns() {
    return [
      {
        dataIndex: "order",
        title: "Order",
        render: (o, record) => {
          if (o === 0) {
            return 0;
          }
          return (
            <Dropdown
              overlay={
                <Menu onClick={({ key }) => this.props.dispatch(actions.selection.reorderSurveyField(record.id, key))}>
                  <Menu.Item key="top">
                    <Icon type="vertical-right" rotate={90} /> Move Top
                  </Menu.Item>
                  <Menu.Item key="up">
                    <Icon type="up" /> Move Up
                  </Menu.Item>
                  <Menu.Item key="down">
                    <Icon type="down" /> Move Down
                  </Menu.Item>
                  <Menu.Item key="bottom">
                    <Icon type="vertical-left" rotate={90} /> Move Bottom
                  </Menu.Item>
                </Menu>
              }
            >
              <a>
                {o} <Icon type="down" />
              </a>
            </Dropdown>
          );
        }
      },
      { dataIndex: "label", title: "Label" },
      {
        dataIndex: "fieldType",
        title: "Field Type",
        render: (t, record) => {
          if (t.endsWith("SELECTION")) {
            const overlay = (
              <Menu>
                {record.selections.map(selection => (
                  <Menu.Item key={selection.id}>{selection.text}</Menu.Item>
                ))}
                {record.selectionHasOther && <Menu.Item>Other</Menu.Item>}
              </Menu>
            );
            return (
              <Dropdown overlay={overlay}>
                <Tag>
                  {t} <Icon type="down" />
                </Tag>
              </Dropdown>
            );
          } else {
            return <Tag>{t}</Tag>;
          }
        }
      },
      {
        dataIndex: "isRequired",
        title: "Required",
        render: r => (r ? <Tag>Yes</Tag> : <Tag>No</Tag>)
      },
      {
        dataIndex: "helpText",
        title: "Help Text"
      },
      {
        dataIndex: "placeholder",
        title: "Placeholder"
      },
      {
        dataIndex: "isHidden",
        title: "Is Hidden",
        render: h => (h ? <Tag>Yes</Tag> : <Tag>No</Tag>)
      },
      {
        key: "action",
        title: "Action",
        render: (_, record) => {
          if (record.id > 0) {
            return record.isHidden ? (
              <a onClick={() => this.hideField(record.id, false)}>Show</a>
            ) : (
              <a onClick={() => this.hideField(record.id, true)}>Hide</a>
            );
          } else {
            return "";
          }
        }
      }
    ];
  }

  submitAdd() {
    this.addFieldForm.props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        if (values.fieldType.endsWith("SELECTION") && this.addFieldForm.state.selections.length == 0) {
          message.error("Please input at least 1 selection.");
          return;
        }
        const order = this.props.selection.surveyFields.slice(-1)[0].order + 1;
        this.props
          .dispatch(
            actions.selection.addSurveyField({ ...values, order, selections: this.addFieldForm.state.selections })
          )
          .then(() => {
            message.success("Field Added.");
            this.setState({ addModalVisible: false });
          });
      }
    });
  }

  render() {
    const dataSource = [
      {
        id: "helpfulCourses",
        order: 0,
        isRequired: true,
        isHidden: false,
        fieldType: "MULTI_SELECTION",
        selections: this.props.selection.rawSelection["course"].map(({ id, number, name }) => ({
          id,
          text: `${number} - ${name}`
        })),
        label: "Helpful Courses",
        placeholder: "Please type or select courses",
        selectionHasOther: false
      },
      {
        id: "coopSource",
        order: 0,
        isRequired: true,
        isHidden: false,
        fieldType: "SELECTION",
        selections: [
          { id: 1, text: "NUcareers" },
          { id: 2, text: "Online" },
          { id: 3, text: "Networking" },
          { id: 4, text: "Career Fair" }
        ],
        label: "Co-op Source",
        selectionHasOther: true
      },
      ...this.props.selection.surveyFields
    ];
    return (
      <React.Fragment>
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <h1>Survey Fields</h1>
          </Col>
          <Col>
            <Row gutter={8} type="flex">
              <Col>
                <Button type="primary" onClick={() => this.setState({ previewModalVisible: true })}>
                  Preview Survey
                  <Icon type="eye" />
                </Button>
              </Col>
              <Col>
                <Button
                  onClick={() => this.props.dispatch(actions.push("/faculty/preview/approval-before-submission"))}
                >
                  Goto Students Approval Page
                  <Icon type="select"></Icon>
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <Spin spinning={!(this.props.selection.surveyFieldsGot && this.props.selection.selectionGot)}>
          <Table pagination={false} rowKey="id" columns={this.getColumns()} dataSource={dataSource} />
          <Button
            style={{ marginTop: "1rem" }}
            type="primary"
            size="small"
            block={true}
            onClick={() => this.setState({ addModalVisible: true })}
          >
            <Icon type="plus" />
            New Field
          </Button>
          {/* <ul>
            {dataSource.map(f => (
              <li key={f.id}>
                <pre>{JSON.stringify(f, null, 4)}</pre>
              </li>
            ))}
          </ul> */}
        </Spin>
        <Modal
          title="Preview Survey"
          onCancel={() => this.setState({ previewModalVisible: false })}
          visible={this.state.previewModalVisible}
          footer={null}
        >
          <div className="preview-survey">
            <SurveyForm editable={true} surveyFields={this.props.selection.surveyFields} />
          </div>
        </Modal>
        <Modal
          title="Add New Survey Field"
          onCancel={() => this.setState({ addModalVisible: false })}
          okText="Submit"
          visible={this.state.addModalVisible}
          onOk={() => this.submitAdd()}
        >
          <div className="add-field">
            <AddFieldForm wrappedComponentRef={form => (this.addFieldForm = form)} />
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}
