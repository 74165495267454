import * as api from "../api";
import { SelectionState, SelectionActions, SelectionKeys, SelectionActionTypes as AT } from "./selections.types";
import { ThunkActionCreator } from "./types";
import { Reducer } from "redux";

const initialState: SelectionState = {
  spinning: false,
  selectionGot: false,
  skillTagsITGot: false,
  skillTagsIT: { i: { raw: [], map: {} }, t: { raw: [], map: {} } },
  rawSelection: Object.keys(SelectionKeys).reduce((r, k) => {
    r[k] = [];
    return r;
  }, {}) as SelectionState["rawSelection"],
  selectionMap: Object.keys(SelectionKeys).reduce((m, k) => {
    m[k] = {};
    return m;
  }, {}) as SelectionState["selectionMap"],
  surveyFieldsGot: false,
  surveyFields: []
};

const getSurveyFields: ThunkActionCreator = () => dispatch => {
  return api.selection.getSurveyFields().then(data => dispatch({ type: AT.SURVEY_FIELDS_GOT, data }));
};

const getAllSelections: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.GET_SELECTION_START });
  return api.selection.getAllSelections({ all: true }).then(data => dispatch({ type: AT.GET_SELECTION_DONE, data }));
};

const getSkillTags: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.GET_SKILL_TAGS_START });
  return api.selection.getSkillTags().then(data => dispatch({ type: AT.GET_SKILL_TAGS_DONE, data }));
};

const getSelectionMaps = (rawSelection: SelectionState["rawSelection"]): SelectionState["selectionMap"] => {
  const selectionMap = {};

  Object.keys(SelectionKeys).forEach(k => {
    selectionMap[k] = getSelectionMap(rawSelection[k]);
  });

  return selectionMap as SelectionState["selectionMap"];
};

const getSelectionMap = (data: { id: any }[]) => {
  const m = {};
  data.forEach(one => {
    m[one.id] = one;
  });
  return m;
};

const addSurveyField: ThunkActionCreator = (data: any) => dispatch => {
  dispatch({ type: AT.TOUCH_SURVEY_FIELD_START });
  return api.admin.surveyField.create(data).then(() => dispatch(getSurveyFields()));
};

const reorderSurveyField: ThunkActionCreator = (fieldId, direction) => dispatch => {
  dispatch({ type: AT.TOUCH_SURVEY_FIELD_START });
  return api.admin.surveyField.order(fieldId, direction).then(() => dispatch(getSurveyFields()));
};

const hideSurveyField: ThunkActionCreator = (fieldId, hide) => dispatch => {
  dispatch({ type: AT.TOUCH_SURVEY_FIELD_START });
  return api.admin.surveyField.hide(fieldId, hide).then(() => dispatch(getSurveyFields()));
};

export const recuder: Reducer<SelectionState, SelectionActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case AT.GET_SELECTION_START: {
      return {
        ...state,
        spinning: true,
        selectionGot: false
      };
    }
    case AT.GET_SELECTION_DONE: {
      return {
        ...state,
        spinning: false,
        selectionGot: true,
        rawSelection: action.data,
        selectionMap: getSelectionMaps(action.data)
      };
    }
    case AT.SURVEY_FIELDS_GOT: {
      return {
        ...state,
        surveyFields: [...action.data],
        surveyFieldsGot: true
      };
    }
    case AT.TOUCH_SURVEY_FIELD_START: {
      return {
        ...state,
        surveyFieldsGot: false
      };
    }
    case AT.GET_SKILL_TAGS_START: {
      return {
        ...state,
        spinning: true,
        skillTagsITGot: false
      };
    }
    case AT.GET_SKILL_TAGS_DONE: {
      const { i, t } = action.data;
      return {
        ...state,
        spinning: false,
        skillTagsITGot: true,
        skillTagsIT: { i: { raw: i, map: getSelectionMap(i) }, t: { raw: t, map: getSelectionMap(t) } }
      };
    }
    default:
      return { ...state };
  }
};

export const actions = {
  getAllSelections,
  getSurveyFields,
  addSurveyField,
  reorderSurveyField,
  hideSurveyField,
  getSkillTags
};
