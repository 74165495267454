import { Action } from "redux";

export enum SelectionKeys {
  entranceDate = "entranceDate",
  expGraduationDate = "expGraduationDate",
  availability = "availability",
  course = "course",
  program = "program",
  campus = "campus",
  coopAdviser = "coopAdviser",
  skillTags = "skillTags"
}

interface SurveyField {
  fieldType: string;
  helpText: string;
  id: number;
  isHidden: boolean;
  isRequired: boolean;
  label: string;
  order: number;
  placeholder: string;
  selectionHasOther: boolean;
  selections: any[];
}

interface SkillTagsIT {
  readonly raw: any[];
  readonly map: any;
}

export interface SelectionState {
  readonly spinning: boolean;
  readonly selectionGot: boolean;
  readonly skillTagsITGot: boolean;
  readonly skillTagsIT: {
    readonly i: SkillTagsIT;
    readonly t: SkillTagsIT;
  };
  readonly rawSelection: { readonly [K in SelectionKeys]: any[] };
  readonly selectionMap: { readonly [K in SelectionKeys]: { [Id in string | number]: any } };
  readonly surveyFields: SurveyField[];
  readonly surveyFieldsGot: boolean;
}

export enum SelectionActionTypes {
  GET_SELECTION_START = "SELECTION/GET_START",
  GET_SELECTION_DONE = "SELECTION/GET_DONE",
  GET_SKILL_TAGS_START = "SELECTION/GET_SKILL_TAGS_START",
  GET_SKILL_TAGS_DONE = "SELECTION/GET_SKILL_TAGS_DONE",
  SURVEY_FIELDS_GOT = "SELECTION/SURVEY_FIELDS",
  // ADD_SURVEY_FIELD_DONE = "SELECTION/ADD_SURVEY_FIELD_DONE",
  // HIDE_SURVEY_FIELD_DONE = "SELECTION/HIDE_SURVEY_FIELD_DONE",
  // REORDER_SURVEY_FIELD_DONE = "SELECTION/REORDER_SURVEY_FIELD_DONE",
  TOUCH_SURVEY_FIELD_START = "SELECTION/TOUCH_SURVEY_FIELD_START"
}

export class GetSelectionStart implements Action {
  readonly type = SelectionActionTypes.GET_SELECTION_START;
}

export class GetSelectionDone implements Action {
  readonly type = SelectionActionTypes.GET_SELECTION_DONE;
  constructor(public data: { [K in SelectionKeys]: any[] }) {}
}

export class SurveyFieldsGot implements Action {
  readonly type = SelectionActionTypes.SURVEY_FIELDS_GOT;
  constructor(public data: any) {}
}

export class TouchingSurveyFieldStart implements Action {
  readonly type = SelectionActionTypes.TOUCH_SURVEY_FIELD_START;
}

// export class AddSurveyField implements Action {
//   readonly type = SelectionActionTypes.ADD_SURVEY_FIELD_DONE;
//   constructor(public data: any) {}
// }

// export class HideSurveyField implements Action {
//   readonly type = SelectionActionTypes.HIDE_SURVEY_FIELD_DONE;
//   constructor(public fieldId: number, public hide: boolean) {}
// }

// export class ReorderSurveyField implements Action {
//   readonly type = SelectionActionTypes.REORDER_SURVEY_FIELD_DONE;
//   constructor(public fieldId: number, public direction: "top" | "up" | "down" | "bottom") {}
// }

export class GetSkillTagsStart implements Action {
  readonly type = SelectionActionTypes.GET_SKILL_TAGS_START;
}

export class GetSkillTagsDone implements Action {
  readonly type = SelectionActionTypes.GET_SKILL_TAGS_DONE;
  constructor(public data: any) {}
}

export type SelectionActions =
  | GetSelectionStart
  | GetSelectionDone
  | SurveyFieldsGot
  // | AddSurveyField
  // | HideSurveyField
  // | ReorderSurveyField
  | GetSkillTagsStart
  | GetSkillTagsDone
  | TouchingSurveyFieldStart;
