import * as React from "react";
import { Table, Tag, Button, Modal, Input, Form, Icon, message, Switch, List, Divider } from "antd";
import { ComponentProps } from "../../../../store/types";

import { ColumnProps } from "antd/lib/table";
import { FormComponentProps } from "antd/lib/form";
import { WrappedFormUtils } from "antd/lib/form/Form";
import * as api from "../../../../api";
import { actions } from "../../../../store";
import { NewFacultyAccountModalProps } from "./types";

export default class FacultyAccounts extends React.Component<
  ComponentProps & NewFacultyAccountModalProps,
  { editModalVisible: boolean; editingIdx: number }
> {
  constructor(props, public newFormRef, public editFormRef) {
    super(props);
    this.state = {
      editModalVisible: false,
      editingIdx: null,
    };
    this.newFormRef = React.createRef();
    this.editFormRef = React.createRef();
  }
  componentDidMount() {
    this.props.dispatch(actions.adviser.loadAllAdviser());
  }

  // getColumns(): ColumnProps<any>[] {
  //   return [
  //     { title: "Username", dataIndex: "username", key: "username" },
  //     { title: "Email", dataIndex: "email", key: "email" },
  //     {
  //       title: "Is Active",
  //       dataIndex: "isActive",
  //       key: "isActive",
  //       render: x => (x ? <Tag color="green">Yes</Tag> : <Tag color="volcano">No</Tag>)
  //     },
  //     {
  //       title: "Action",
  //       key: "action",
  //       render: (_, record, idx) => (
  //         <a onClick={() => this.setState({ editModalVisible: true, editingIdx: idx })}>Edit</a>
  //       )
  //     }
  //   ];
  // }

  newOk() {
    const form: WrappedFormUtils = this.newFormRef.current;
    form.validateFields((errors, values) => {
      if (!errors) {
        const { emailPrefix, newPassword } = values;
        api.adviserUser
          .create({
            username: emailPrefix,
            email: `${emailPrefix}@northeastern.edu`,
            newPassword,
          })
          .then(() => {
            this.props.dispatch(actions.adviser.loadAllAdviser());
            message.success("New Faculty Account Created");
            this.props.closeNewFacultyAccountModal();
          });
      }
    });
  }

  afterNewModalClose() {
    const form: WrappedFormUtils = this.newFormRef.current;
    form.resetFields();
    form.setFieldsValue({ newPassword: randomPassword() });
  }

  get editingUser() {
    if (this.state.editingIdx === null) {
      return {};
    }
    return this.props.adviser.data[this.state.editingIdx];
  }

  editOk() {
    const form: WrappedFormUtils = this.editFormRef.current;
    form.validateFields((errors, values) => {
      if (!errors) {
        const { username, isActive, emailPrefix, newPassword } = values;
        const data = { username, email: `${emailPrefix}@northeastern.edu`, isActive };
        if (newPassword) {
          data["newPassword"] = newPassword;
        }
        api.adviserUser.update(this.editingUser.id, data).then(() => {
          this.props.dispatch(actions.adviser.loadAllAdviser());
          this.props.dispatch(actions.selection.getAllSelections());
          message.success("Faculty Updated");
          this.setState({ editModalVisible: false });
        });
      }
    });
  }

  afterEditModalClose() {
    this.setState({ editingIdx: null });
  }

  render() {
    return (
      <React.Fragment>
        {/* <Table
          loading={this.props.adviser.loading}
          columns={this.getColumns()}
          dataSource={this.props.adviser.data}
          pagination={false}
          rowKey="id"
          footer={() => (
            <Button
              icon="plus"
              size="small"
              type="primary"
              block
              onClick={() => this.setState({ newModalVisible: true })}
            >
              New Faculty Account
            </Button>
          )}
        /> */}
        <h2 style={{ marginTop: 11 }}>Faculty Accounts</h2>
        <Divider style={{ margin: 0 }} />
        <div style={{ padding: "0 15px 15px 15px", backgroundColor: "white", marginTop: 16 }}>
          <List
            dataSource={this.props.adviser.data}
            loading={this.props.adviser.loading}
            renderItem={(item) => (
              <List.Item
                actions={[
                  <a
                    onClick={() =>
                      this.setState({ editModalVisible: true, editingIdx: this.props.adviser.id2idx[item.id] })
                    }
                  >
                    Edit
                  </a>,
                ]}
              >
                <List.Item.Meta
                  title={
                    <span>
                      <span style={{ marginRight: 5 }}>{item.username}</span>
                      {item.isActive ? <Tag color="green">Active</Tag> : <Tag color="volcano">Inactive</Tag>}
                    </span>
                  }
                  description={item.email}
                />
              </List.Item>
            )}
          />
          <Button type="primary" block icon="plus" onClick={() => this.props.openNewFacultyAccountModal()}>
            New Faculty Account
          </Button>
        </div>
        <Modal
          title="New Faculty Account"
          visible={this.props.newFacultyAccountModalVisible}
          onCancel={() => this.props.closeNewFacultyAccountModal()}
          okText="Submit"
          onOk={() => this.newOk()}
          afterClose={() => this.afterNewModalClose()}
          zIndex={1001}
        >
          <FacultyAccountsNewForm {...this.props} ref={this.newFormRef} />
        </Modal>
        <Modal
          title="Edit Faculty Account"
          visible={this.state.editModalVisible}
          onCancel={() => this.setState({ editModalVisible: false })}
          okText="Submit"
          onOk={() => this.editOk()}
          afterClose={() => this.afterEditModalClose()}
        >
          <FacultyAccountEditForm {...this.props} ref={this.editFormRef} editingUser={this.editingUser} />
        </Modal>
      </React.Fragment>
    );
  }
}

function randomPassword(length = 4) {
  const possible = "abcdefghijklmnopqrstuvwxyz";
  let text = "";
  for (var i = 0; i < length; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
  return text;
}

class FacultyAccountsNewFormComp extends React.Component<ComponentProps & FormComponentProps> {
  constructor(props, private initialPassword: string) {
    super(props);
    this.initialPassword = randomPassword();
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="narrow-form">
        <Form.Item label="Email">
          {getFieldDecorator("emailPrefix", {
            rules: [{ required: true }, { pattern: /^[^@]+$/, message: "Please input email prefix only" }],
          })(<Input placeholder="Email Prefix" addonAfter="@northeastern.edu" />)}
        </Form.Item>
        <Form.Item label="Password" help="Random Generated Password.">
          {getFieldDecorator("newPassword", { initialValue: this.initialPassword, rules: [{ required: true }] })(
            <Input
              autoComplete="off"
              suffix={
                <Icon type="reload" onClick={() => this.props.form.setFieldsValue({ newPassword: randomPassword() })} />
              }
            />
          )}
        </Form.Item>
      </Form>
    );
  }
}

const FacultyAccountsNewForm = Form.create()(FacultyAccountsNewFormComp);

class FacultyAccountEditFormComp extends React.Component<ComponentProps & FormComponentProps & { editingUser: any }> {
  render() {
    const { username, email, isActive } = this.props.editingUser;
    const emailPrefix = email.split("@")[0];
    const { getFieldDecorator } = this.props.form;
    return (
      <Form className="norrow-form">
        <Form.Item label="Username">
          {getFieldDecorator("username", { initialValue: username, rules: [{ required: true }] })(<Input />)}
        </Form.Item>
        <Form.Item label="Email">
          {getFieldDecorator("emailPrefix", {
            initialValue: emailPrefix,
            rules: [{ required: true }, { pattern: /^[^@]+$/, message: "Please input email prefix only" }],
          })(<Input addonAfter="@northeastern.edu" />)}
          <Form.Item label="Is Active" help="Designates whether this student can sign in this App.">
            {getFieldDecorator("isActive", { initialValue: isActive, valuePropName: "checked" })(
              <Switch checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} />
            )}
          </Form.Item>
          <Form.Item label="New Password">
            {getFieldDecorator("newPassword")(<Input type="password" placeholder="Leave blank if not modified." />)}
          </Form.Item>
        </Form.Item>
      </Form>
    );
  }
}

const FacultyAccountEditForm = Form.create()(FacultyAccountEditFormComp);
