import { Action } from "redux";

export enum ApprovalFacultyActionTypes {
  TOUCH_START = "APPROVALF/TOUCH_START",
  TOUCH_DONE = "APPROVALF/TOUCH_DONE",
  LOAD_ALL_DONE = "APPROVALF/LOAD_ALL_DONE",
  CLEAR = "APPROVALF/CLEAR",
  MODIFY = "APPROVALF/MODIFY",
  BADGE_DONE = "APPROVALF/BADGE_DONE",
  EXTEND = "APPROVAL/EXTEND"
}

export interface ApprovalFacultyState {
  readonly loading: boolean;
  readonly dataGot: boolean;
  readonly data: any[];
  readonly id2idx: any;
  readonly badgeCount: number;
}

export class TouchStart implements Action {
  readonly type = ApprovalFacultyActionTypes.TOUCH_START;
}

export class TouchDone implements Action {
  readonly type = ApprovalFacultyActionTypes.TOUCH_DONE;
}

export class LoadAllDone implements Action {
  readonly type = ApprovalFacultyActionTypes.LOAD_ALL_DONE;
  constructor(public data: any[]) {}
}

export class Modify implements Action {
  readonly type = ApprovalFacultyActionTypes.MODIFY;
  constructor(public data: any) {}
}

export class BadgeDone implements Action {
  readonly type = ApprovalFacultyActionTypes.BADGE_DONE;
  constructor(public count: number) {}
}

export class Extend implements Action {
  readonly type = ApprovalFacultyActionTypes.EXTEND;
  constructor(public data: any) {}
}

export class Clear implements Action {
  readonly type = ApprovalFacultyActionTypes.CLEAR;
}

export type ApprovalFacultyActions = TouchStart | TouchDone | LoadAllDone | Modify | BadgeDone | Extend | Clear;
