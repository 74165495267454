import * as React from "react";
import { Tag, message, Rate } from "antd";

import desc from "../../topDescription";

export default class TopSkillTagsTransfer extends React.Component<
  { skillTags: string[]; initialValue: any[]; onChange: (value: { text: string; rating: number }[]) => void },
  { tagChedked: { [key: string]: boolean }; checkedCount: number; tagRating: { [key: string]: number } }
> {
  constructor(props) {
    super(props);
    const tagChedked = {};
    const tagRating = {};
    this.props.initialValue.forEach(({ text, rating }) => {
      tagChedked[text] = true;
      tagRating[text] = rating;
    });
    this.state = { tagChedked, checkedCount: this.props.initialValue.length, tagRating };
  }

  handleRemoveTag(e, t) {
    e.preventDefault();
    this.handleChange(t);
  }

  handleChange(t) {
    const preChecked = !!this.state.tagChedked[t];
    const checkedCount = this.state.checkedCount + (preChecked ? -1 : 1);
    if (checkedCount > 5) {
      message.warning("You can only choose at most 5 top tags.");
    } else {
      this.setState({ tagChedked: { ...this.state.tagChedked, [t]: !preChecked }, checkedCount }, () =>
        this.props.onChange(this.value)
      );
    }
  }

  handleRateChange(t: string, value) {
    this.setState({ tagRating: { ...this.state.tagRating, [t]: value } }, () => this.props.onChange(this.value));
  }

  get value() {
    return this.props.skillTags
      .filter(t => this.state.tagChedked[t])
      .map(text => ({ text, rating: this.state.tagRating[text] || 0 }));
  }

  render() {
    return (
      <div>
        {this.props.skillTags.map(t => (
          <Tag.CheckableTag key={t} checked={this.state.tagChedked[t]} onChange={() => this.handleChange(t)}>
            {t}
          </Tag.CheckableTag>
        ))}
        <br />
        <span>{this.state.checkedCount}/5 selected.</span>
        {this.props.skillTags
          .filter(t => this.state.tagChedked[t])
          .map(t => (
            <React.Fragment key={t}>
              <br />
              <Tag closable={true} onClose={e => this.handleRemoveTag(e, t)}>
                {t}
              </Tag>
              <Rate
                tooltips={desc}
                value={this.state.tagRating[t] || 0}
                onChange={value => this.handleRateChange(t, value)}
              />
            </React.Fragment>
          ))}
      </div>
    );
  }
}
