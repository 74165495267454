import * as React from "react";
import { connect } from "react-redux";
import { Checkbox } from "antd";

import { actions } from "../../../store";
import { RootState } from "../../../store/types";

const BulkComp = ({ indeterminate, checked, page, dispatch }) => (
  <div>
    <Checkbox
      indeterminate={indeterminate}
      checked={checked}
      onChange={e => {
        const action = e.target.checked ? actions.search.selectStudents : actions.search.deselectStudents;
        dispatch(action(page.map(one => one.idx)));
      }}
    >
      <b>Bulk Select for This Page</b>
    </Checkbox>
  </div>
);

const BulkCurrentPage = connect((state: RootState) => {
  const { selectedIdxs, page } = state.search;
  const selectedSet = new Set(selectedIdxs);

  let hasSelected = false;
  for (const one of page) {
    if (selectedSet.has(one.idx)) {
      hasSelected = true;
      break;
    }
  }
  if (!hasSelected) return { indeterminate: false, checked: false, page };

  let hasNonSelected = false;
  for (const one of page) {
    if (!selectedSet.has(one.idx)) {
      hasNonSelected = true;
      break;
    }
  }
  if (hasNonSelected) return { indeterminate: true, page };

  return { indeterminate: false, checked: true, page };
})(BulkComp);

export default BulkCurrentPage;
