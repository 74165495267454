import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Layout, Divider, Input, Icon, Form, Row, Col, Button, message, Spin, Popover } from "antd";
import { FormComponentProps } from "antd/lib/form";
import { Link } from "react-router-dom";

import * as api from "../../api";
import { ComponentProps } from "../../store/types";

const Content = Layout.Content;

const helpContent = (
  <ol style={{ margin: 0, paddingLeft: "1em" }}>
    <li>Input your northeastern email prefix.</li>
    <li>Click "Get Token" and a token will be sent to your email.</li>
    <li>Input that token and click "Sign In".</li>
  </ol>
);

const FormItem = Form.Item;

class TokenSignInComp extends React.Component<
  ComponentProps & FormComponentProps,
  { tokenSending: boolean; tokenSent: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { tokenSending: false, tokenSent: false };
  }

  getToken() {
    this.props.form.validateFields(["emailPrefix"], (err, values) => {
      if (!err) {
        const { emailPrefix } = values;
        this.setState({ tokenSending: true });
        api.auth.sendToken({ emailPrefix }).then(() => {
          this.setState({ tokenSending: false, tokenSent: true });
          message.success("Token sent.");
        });
      }
    });
  }

  signIn() {
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { token, emailPrefix } = values;
        api.auth.signInToken({ token, emailPrefix }).then(({ hasProfile, err }) => {
          if (err) {
            message.error(err);
          } else {
            this.goToFrom();
          }
        });
      }
    });
  }

  goToFrom() {
    const { from } = this.props.router.location.state || { from: "/student" };
    // this.props.dispatch(actions.showStudentHeader());
    this.props.dispatch(push(from));
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Layout>
        <Content style={{ width: 420, margin: "64px auto 0", background: "white", padding: 24 }}>
          <h1 style={{ textAlign: "center" }}>
            Student Sign In
            <span style={{ paddingLeft: 16, fontSize: 16, fontWeight: "normal" }}>
              <Popover placement="bottomLeft" title="Help" content={helpContent}>
                <a>
                  <Icon type="question-circle" />
                </a>
              </Popover>
            </span>
          </h1>
          <Divider style={{ marginTop: 0 }} />
          <Form>
            <FormItem>
              {getFieldDecorator("emailPrefix", {
                rules: [
                  { required: true, whitespace: true, message: "Please input your northeastern email prefix" },
                  { pattern: /^[^@]+$/, message: "Please input email prefix only" }
                ]
              })(
                <Input
                  size="large"
                  placeholder="Your northeastern email"
                  prefix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />}
                  addonAfter="@northeastern.edu"
                />
              )}
            </FormItem>
            <Row gutter={16}>
              <Col span={14}>
                <FormItem>
                  {getFieldDecorator("token", {
                    rules: [{ required: true, whitespace: true, message: "Please input the token sent to your email" }]
                  })(
                    <Input
                      type="password"
                      size="large"
                      placeholder="Your token"
                      prefix={<Icon type="code-o" style={{ color: "rgba(0,0,0,0.25)" }} />}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={10}>
                <Spin spinning={this.state.tokenSending}>
                  <Button size="large" style={{ width: "100%" }} onClick={() => this.getToken()}>
                    Get Token
                  </Button>
                </Spin>
              </Col>
            </Row>
            {this.state.tokenSent ? (
              <Button size="large" style={{ width: "100%" }} type="primary" onClick={() => this.signIn()}>
                Sign In
              </Button>
            ) : (
              <Popover placement="top" title="Help" content={helpContent}>
                <Button size="large" style={{ width: 372 }} disabled type="primary">
                  Sign In
                </Button>
              </Popover>
            )}
            <h4 style={{ textAlign: "center" }}>
              <Link to="/faculty/sign-in">Faculty Sign In</Link>
            </h4>
          </Form>
        </Content>
      </Layout>
    );
  }
}

const TokenSignIn = connect(state => state)(Form.create()(TokenSignInComp));

export default TokenSignIn;
