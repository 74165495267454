import * as React from "react";
import { Modal, Table, Popover, Icon, Row, Col, Button, Tooltip } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";

import PrivateLink from "./PrivateLink";
import { actions } from "../../../store";
import { ComponentProps } from "../../../store/types";

class EmployerLinkModalComp extends React.Component<ComponentProps> {
  render() {
    const selectedList = this.props.search.selectedList;
    let modalTitle, rawLink;
    if (selectedList) {
      modalTitle = `Employer Links for ${selectedList.title}`;
      rawLink = `${window.location.origin}/employer/student-list/${selectedList.uuidb58}`;
    }

    const columns = [
      {
        title: "Username",
        dataIndex: "user.username"
      },
      {
        title: "Email",
        dataIndex: "user.email",
        render: text => <a href={`mailto:${text}`}>{text}</a>
      },
      {
        title: "Key Expire At",
        dataIndex: "expireAt",
        render: text => {
          const m = moment(text);
          return `${m.format("L")} ${m.format("LT")}`;
        },
        width: 170
      },
      {
        title: "",
        dataIndex: "id",
        key: "action",
        render: (id, record) => {
          return (
            <span>
              <Popover
                trigger="click"
                placement="topRight"
                content={<PrivateLink rawLink={rawLink} secKey={record.key} />}
              >
                <a>
                  <Icon type="link" />
                </a>
              </Popover>
              <span style={{ padding: "0 5px" }}>|</span>
              <Link target="_blank" to={`/faculty/employer?edit=${id}`}>
                <Icon type="edit" />
              </Link>
            </span>
          );
        }
      }
    ];

    return (
      <Modal
        title={modalTitle}
        width={1000}
        visible={this.props.search.employersModalVisible}
        onCancel={() => this.props.dispatch(actions.search.closeEmployerModal())}
        footer={null}
      >
        <Row style={{ marginBottom: 10 }} type="flex" justify="space-between">
          <Col>
            <b>Raw Link:</b>{" "}
            <a href={rawLink} target="_blank">
              {rawLink}
            </a>
          </Col>
          <Col>
            <Tooltip title="Reload Employers">
              <Button
                type="primary"
                size="small"
                shape="circle"
                onClick={() => this.props.dispatch(actions.loadEmployer())}
              >
                <Icon type="reload" />
              </Button>
            </Tooltip>
          </Col>
        </Row>
        <Table
          size="small"
          pagination={false}
          columns={columns}
          dataSource={this.props.employer.data}
          loading={this.props.employer.spinning}
          expandedRowRender={record => {
            const createdAt = moment(record.createdAt);
            const lastLogin = record.user.lastLogin && moment(record.user.lastLogin);
            return (
              <div>
                <Row>
                  <b>Private Link:</b> <PrivateLink rawLink={rawLink} secKey={record.key} />
                </Row>
                <Row type="flex" gutter={8}>
                  <Col>
                    <b>ID:</b>
                    {record.id}
                  </Col>
                  <Col>
                    <b>Created at:</b> {`${createdAt.format("L")} ${createdAt.format("LT")}`}
                  </Col>
                  <Col>
                    <b>Last visit:</b> {lastLogin ? `${lastLogin.format("L")} ${lastLogin.format("LT")}` : "never"}{" "}
                  </Col>
                  <Col>
                    <b>Key:</b> {record.key}
                  </Col>
                  <Col>
                    <b>Key expired:</b> {record.isExpired ? "YES" : "NO"}
                  </Col>
                </Row>
              </div>
            );
          }}
        />
        <Row style={{ marginTop: 10 }}>
          <Button size="small" style={{ width: "100%" }}>
            <Link target="_blnk" to="/faculty/employer?create">
              <Icon type="plus" /> Add New Employer
            </Link>
          </Button>
        </Row>
      </Modal>
    );
  }
}

const EmployerLinkModal = connect(state => state)(EmployerLinkModalComp);

export default EmployerLinkModal;
