import { Reducer, ActionCreator } from "redux";
import {
  ApprovalFacultyState,
  ApprovalFacultyActionTypes as AT,
  ApprovalFacultyActions
} from "./approval-faculty.types";
import { ThunkActionCreator } from "./types";
import * as api from "../api";

const initialState: ApprovalFacultyState = {
  loading: false,
  dataGot: false,
  data: [],
  id2idx: {},
  badgeCount: 0
};

const loadAllApproval: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.CLEAR });
  dispatch({ type: AT.TOUCH_START });
  api.facultyApproval.list({ pendingOnly: false }).then(data => {
    dispatch({ type: AT.LOAD_ALL_DONE, data });
    dispatch({ type: AT.TOUCH_DONE });
  });
};

const loadPendingApproval: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.CLEAR });
  dispatch({ type: AT.TOUCH_START });
  api.facultyApproval.list({ pendingOnly: true }).then(data => {
    dispatch({ type: AT.LOAD_ALL_DONE, data });
    dispatch({ type: AT.TOUCH_DONE });
  });
};

const modifyApproval: ThunkActionCreator = (type: "academic" | "coop" | "reject", id, comment) => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  const method = {
    academic: api.facultyApproval.academicApproval,
    coop: api.facultyApproval.coopApproval,
    reject: api.facultyApproval.reject
  }[type];
  return method({ id, comment }).then(data => {
    dispatch({ type: AT.MODIFY, data });
    dispatch({ type: AT.TOUCH_DONE });
  });
};

const updateApprovalBadge: ThunkActionCreator = () => dispatch => {
  return api.facultyApproval.badgeCount().then(({ count }) => dispatch({ type: AT.BADGE_DONE, count }));
};

const extendCoop: ThunkActionCreator = ({ comment, newEndDate, id }) => dispatch => {
  dispatch({ type: AT.TOUCH_DONE });
  return api.facultyApproval.extendCoop({ comment, newEndDate, id }).then(data => {
    dispatch({ type: AT.MODIFY, data });
    dispatch({ type: AT.TOUCH_DONE });
  });
};

export const actions = { loadAllApproval, loadPendingApproval, modifyApproval, updateApprovalBadge, extendCoop };

const updateOne = (data: any[], id2idx: any, one: any) => {
  const idx = id2idx[one.id];
  return data
    .slice(0, idx)
    .concat([
      {
        ...one,
        studentprofile: {
          ...one.studentprofile,
          fullName: `${one.studentprofile.firstName} ${one.studentprofile.lastName}`
        }
      }
    ])
    .concat(data.slice(idx + 1));
};

const getId2idx = (data: any[]) => {
  const id2idx = {};
  data.forEach((one, idx) => {
    id2idx[one.id] = idx;
  });
  return id2idx;
};

export const reducer: Reducer<ApprovalFacultyState, ApprovalFacultyActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case AT.CLEAR: {
      return {
        ...state,
        dataGot: false,
        data: []
      }
    }
    case AT.TOUCH_START: {
      return {
        ...state,
        loading: true
      };
    }
    case AT.TOUCH_DONE: {
      return {
        ...state,
        loading: false
      };
    }
    case AT.LOAD_ALL_DONE: {
      return {
        ...state,
        dataGot: true,
        data: action.data.map(one => ({
          ...one,
          studentprofile: {
            ...one.studentprofile,
            fullName: `${one.studentprofile.firstName} ${one.studentprofile.lastName}`
          }
        })),
        id2idx: getId2idx(action.data)
      };
    }
    case AT.MODIFY: {
      return {
        ...state,
        data: updateOne(state.data, state.id2idx, action.data)
      };
    }
    case AT.BADGE_DONE: {
      return {
        ...state,
        badgeCount: action.count
      };
    }
    default: {
      return state;
    }
  }
};
