import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Form, Select } from "antd";

import { ComponentProps } from "../../../store/types";
import { FormComponentProps } from "antd/lib/form";

const { Option } = Select;

const FormItem = Form.Item;

const getOption = (idField, textField) => (one, i) => (
  <Select.Option key={i} value={one[idField]}>
    {one[textField]}
  </Select.Option>
);

class FilterBasic extends React.Component<ComponentProps & FormComponentProps> {
  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Row type="flex" gutter={24}>
        <Col>
          <FormItem label="Program">
            {getFieldDecorator("program", { initialValue: [] })(
              <Select mode="multiple" style={{ minWidth: 150 }} placeholder="Any">
                {this.props.selection.rawSelection.program.map(getOption("id", "text"))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col>
          <FormItem label="Campus">
            {getFieldDecorator("campus", { initialValue: [] })(
              <Select mode="multiple" style={{ minWidth: 150 }} placeholder="Any">
                {this.props.selection.rawSelection.campus.map(getOption("id", "text"))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col>
          <FormItem label="Entrance">
            {getFieldDecorator("entranceDate", { initialValue: [] })(
              <Select mode="multiple" placeholder="Any" style={{ minWidth: 150 }}>
                {this.props.selection.rawSelection.entranceDate.map(getOption("id", "text"))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col>
          <FormItem label="Exp Graduation">
            {getFieldDecorator("expGraduationDate", { initialValue: [] })(
              <Select mode="multiple" placeholder="Any" style={{ minWidth: 150 }}>
                {this.props.selection.rawSelection.expGraduationDate.map(getOption("id", "text"))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col>
          <FormItem label="Availability">
            {getFieldDecorator("availability", { initialValue: [] })(
              <Select mode="multiple" style={{ minWidth: 150 }} placeholder="Any">
                {this.props.selection.rawSelection.availability.map(getOption("id", "text"))}
              </Select>
            )}
          </FormItem>
        </Col>
        <Col>
          <FormItem label="Coursework">
            {getFieldDecorator("coursesCompleted", { initialValue: [] })(
              <Select mode="multiple" style={{ minWidth: 450, maxWidth: 600 }} placeholder="Any">
                {this.props.selection.rawSelection.course.map(cs => (
                  <Option key={cs.id} value={`${cs.number}-${cs.id}`}>
                    {cs.number} - {cs.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Col>
      </Row>
    );
  }
}

export default connect(state => state)(FilterBasic);
