import * as React from "react";
import { Alert } from "antd";
import { SelfAssessment } from "../../student/create";

export default function SelfAssessmentComp() {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <Alert
          type="info"
          message="This page is a preview."
          showIcon
          description={
            <span>
              This is the first of three steps students need to do. All the assessment answers are default to No
              Experience. If the students ever submitted this page to the server and visit it again, the previous
              submission will be pulled back to override the defaults.
              <br></br>
              By clicking the Submit button, you will be redirected to the information preview page, which is the next
              step.
            </span>
          }
          closable
          style={{ marginBottom: "1em" }}
        ></Alert>
      </div>
      <SelfAssessment facultyPreview></SelfAssessment>
    </React.Fragment>
  );
}
