import { Action } from "redux";

export interface EmployerState {
  readonly data: any[];
  readonly dataGot: boolean;
  readonly spinning: boolean;
  readonly id2idx: any;
}

export enum EmployerActionTypes {
  TOUCH_START = "EMPLOYER/TOUCH_START",
  LOAD_DONE = "EMPLOYER/LOAD_DONE",
  UPDATE_DONE = "EMPLOYER/UPDATE_DONE",
  DELETE_DONE = "EMPLOYER/DELETE_DONE",
  CREATE_DONE = "EMPLOYER/CREATE_DONE"
}

export class LoadEmployerDone implements Action {
  readonly type = EmployerActionTypes.LOAD_DONE;
  constructor(public data: any) {}
}

export class LoadEmployer implements Action {
  readonly type = EmployerActionTypes.TOUCH_START;
}

export class UpdateEmployerDone implements Action {
  readonly type = EmployerActionTypes.UPDATE_DONE;
  constructor(public idx: number, public data: any) {}
}

export class DeleteEmployerDone implements Action {
  readonly type = EmployerActionTypes.DELETE_DONE;
  constructor(public idx: number) {}
}

export class CreateEmployerDone implements Action {
  readonly type = EmployerActionTypes.CREATE_DONE;
  constructor(public data: any) {}
}

export type EmployerActions =
  | LoadEmployerDone
  | LoadEmployer
  | UpdateEmployerDone
  | DeleteEmployerDone
  | CreateEmployerDone;
