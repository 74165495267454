import { Action } from "redux";

export interface IMenu {
  text: string;
  link: string;
  key: string;
}

export interface IMenuWithChildren {
  title: string;
  key: string;
  children: IMenu[];
}

export interface AuthState {
  readonly whoamiLoading: boolean;
  readonly user: any;
  readonly profile: any;
  readonly photoUrl: string;
  readonly photoUploading: boolean;
  readonly role: "student" | "faculty" | "employer";
}

export enum AuthActionTypes {
  SIGNED_IN = "AUTH/SIGNED_IN",
  SIGNED_OUT = "AUTH/SIGNED_OUT",
  WHOAMI_START = "AUTH/WHOAMI_START",
  WHOAMI_DONE = "AUTH/WHOAMI_DONE",
  UPLOAD_PHOTO_START = "AUTH/UPLOAD_PHOTO_START",
  UPLOAD_PHOTO_DONE = "AUTH/UPLOAD_PHOTO_DONE"
}

export class SignedIn implements Action {
  readonly type = AuthActionTypes.SIGNED_IN;
  constructor(public role: "student" | "faculty" | "employer") {}
}

export class SignedOut implements Action {
  readonly type = AuthActionTypes.SIGNED_OUT;
}

export class WhoamiStart implements Action {
  readonly type = AuthActionTypes.WHOAMI_START;
}

export class WhoamiDone implements Action {
  readonly type = AuthActionTypes.WHOAMI_DONE;
  constructor(public user: any, public profile: any, public profilePhoto: any) {}
}

export class UploadPhotoStart implements Action {
  readonly type = AuthActionTypes.UPLOAD_PHOTO_START;
}

export class UploadPhotoDone implements Action {
  readonly type = AuthActionTypes.UPLOAD_PHOTO_DONE;
  constructor(public photoUrl: string) {}
}

export type AuthActions = SignedIn | SignedOut | WhoamiStart | WhoamiDone | UploadPhotoDone | UploadPhotoStart;
