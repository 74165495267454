import * as api from "../api";
import { ThunkActionCreator, ActionTypes as RAT } from "./types";
import { ActionCreator, Reducer } from "redux";
import Cookies from "js-cookie";

import {
  SearchState,
  SearchActionTypes as AT,
  SelectStudents,
  DeSelectStudents,
  ToggleSelectStudent,
  OpenCartDrawer,
  CloseListDrawer,
  OpenEmployerModal,
  CloaseEmployerModal,
  SearchActions,
  Filter,
  SingleKeys,
  MultipleKeys,
} from "./search.types";
import { SelectionState } from "./selections.types";
import { AssessmentSectionState } from "./assessment.types";

const initSearch: ThunkActionCreator = (b58: string = "") => (dispatch, getState) => {
  let getStudents;
  if (b58) {
    getStudents = () => api.sutdentList.retrieve(b58).then((data) => ({ data, generatedAt: new Date().toISOString() }));
  } else if (Cookies.get("searchAll")) {
    getStudents = api.search.getAllStudents;
  } else {
    getStudents = api.search.getStudents;
  }
  return Promise.resolve(() => dispatch({ type: AT.START_TOUCHING }))
    .then(() => getStudents())
    .then(({ data, generatedAt }) => {
      const { data: newData, id2idx } = initData(getState().selection.selectionMap, data);
      return dispatch({ type: AT.GET_DATA_DONE, data: newData, generatedAt, id2idx });
    })
    .then(() => dispatch(setFilter()));
};

const setFilter: ThunkActionCreator = (filter = emptyFilter) => (dispatch, getState) => {
  return Promise.resolve()
    .then(() => dispatch({ type: AT.START_TOUCHING }))
    .then(() => {
      const { sections } = getState().assessment;
      const { data } = getState().search;
      const filteredData = applyFilter(data, filter, sections);
      return dispatch({ type: AT.SET_FILTER_DONE, filter, filteredData });
    })
    .then(() => dispatch(pageChange({ currentPage: 1 })));
};

const pageChange: ThunkActionCreator = ({ currentPage, pageSize }) => (dispatch) => {
  const delay = (t) => new Promise((r) => setTimeout(r, t));
  return Promise.resolve()
    .then(() => dispatch({ type: AT.START_TOUCHING }))
    .then(() => delay(100))
    .then(() => dispatch({ type: AT.PAGE_CHANGE, currentPage, pageSize }));
};

const selectStudents: ActionCreator<SelectStudents> = (idxs) => ({ type: AT.SELECT_STUDENTS, idxs });
const deselectStudents: ActionCreator<DeSelectStudents> = (idxs) => ({ type: AT.DESELECT_STUDENTS, idxs });
const toggleSelectStudent: ActionCreator<ToggleSelectStudent> = (idx) => ({ type: AT.TOGGLE_SELECT_STUDENT, idx });

const closeCartDrawer: ThunkActionCreator = () => (dispatch) =>
  Promise.resolve()
    .then(() => dispatch({ type: AT.CLOSE_CART_DRAWER }))
    .then(() => dispatch({ type: AT.CLOSE_LIST_DRAWER }));
const openCartDrawer: ActionCreator<OpenCartDrawer> = () => ({ type: AT.OPEN_CART_DRAWER });
const toggleCartDrawer: ThunkActionCreator = () => (dispatch, getState) => {
  const { isCartDrawerOpen } = getState().search;
  return isCartDrawerOpen ? dispatch(closeCartDrawer()) : dispatch(openCartDrawer());
};

const closeListDrawer: ActionCreator<CloseListDrawer> = () => ({ type: AT.CLOSE_LIST_DRAWER });
const openListDrawer: ThunkActionCreator = () => (dispatch) =>
  Promise.resolve()
    .then(() => dispatch({ type: AT.OPEN_CART_DRAWER }))
    .then(() => dispatch({ type: AT.OPEN_LIST_DRAWER }));

const toggleListDrawer: ThunkActionCreator = () => (dispatch, getState) => {
  const { isListDrawerOpen } = getState().search;
  return isListDrawerOpen ? dispatch(closeListDrawer()) : dispatch(openListDrawer());
};

const saveStudentList: ThunkActionCreator = ({ users, title, comment }) => (dispatch, getState) => {
  dispatch({ type: RAT.UserList.TOUCH_START });
  const idxs = getState().search.selectedIdxs;
  return Promise.resolve()
    .then(() => api.userList.create({ users, title, comment }))
    .then((data) => dispatch({ type: RAT.UserList.CREATE_DONE, data }))
    .then(() => dispatch({ type: AT.OPEN_LIST_DRAWER }))
    .then(() => dispatch(deselectStudents(idxs)));
};

const deleteStudentList: ThunkActionCreator = (id) => (dispatch) => {
  dispatch({ type: RAT.UserList.TOUCH_START });
  return Promise.resolve()
    .then(() => api.userList.del(id))
    .then(() => dispatch({ type: RAT.UserList.DELETE_DONE, id }));
};

const openEmployerModal: ActionCreator<OpenEmployerModal> = (list) => ({ type: AT.OPEN_EMPLOYER_MODAL, list });
const closeEmployerModal: ActionCreator<CloaseEmployerModal> = () => ({ type: AT.CLOSE_EMPLOYER_MODAL });

const emptyFilter: Filter = {
  assessment: {},
  single: {
    entranceDate: [],
    expGraduationDate: [],
    program: [],
    campus: [],
    coopAdviser: [],
  },
  multiple: {
    availability: [],
    coursesCompleted: [],
  },
  skillTags: {
    techSkills: {},
    topTechSkills: {},
  },
};

const initialState: SearchState = {
  id2idx: {},
  filter: emptyFilter,
  listSpinning: false,
  data: [],
  dataGot: false,
  generatedAt: null,
  filteredData: [],
  page: [],
  currentPage: 1,
  pageSize: 10,
  selectedIdxs: [],
  isSelected: {},
  isCartDrawerOpen: false,
  isListDrawerOpen: false,
  employersModalVisible: false,
  selectedList: null,
};

const applyFilter = (data: any[], filter: Filter, assessmentSections: AssessmentSectionState[]) => {
  type StudentKeys = "information" | "profile" | "assessmentAnswers";

  const getSingleFilter = (studentKey: StudentKeys, filterKey: SingleKeys) => (student) => {
    if (!filter || !filter.single) {
      return true;
    }
    const f = filter.single[filterKey];
    if (!f || !f.length) {
      return true;
    }

    for (const e of f) {
      if (student[studentKey][filterKey].id == e) {
        return true;
      }
    }
    return false;
  };

  const getMulFilter = (key: MultipleKeys, aKey: string = "id") => ({ information }) => {
    if (!filter || !filter.multiple) {
      return true;
    }

    const f = filter.multiple[key];
    if (!f || !f.length) {
      return true;
    }

    for (const e of f) {
      for (const a of information[key]) {
        if (e == a[aKey]) {
          return true;
        }
      }
      return false;
    }
  };

  const getAssessmentFilter = () => {
    const searchMap = {};
    for (const section of assessmentSections) {
      for (const question of section.questions) {
        const questionKey = `assessmentQuestion${question.id}`;
        const answers = filter[questionKey];
        if (answers && answers.length) {
          searchMap[question.id] = { NE: false, BG: false, IM: false, AD: false };
          answers.forEach((answer) => {
            searchMap[question.id][answer] = true;
          });
        } else {
          searchMap[question.id] = { NE: true, BG: true, IM: true, AD: true };
        }
      }
    }
    // searchMap = {
    //   1: { 'NE': true, 'BG': true, 'IM': true, 'AD': true },
    //   2: { 'NE': false, 'BG': false, 'IM': false, 'AD': true },
    //   3: { 'NE': false, 'BG': false, 'IM': true, 'AD': true },
    //   ...
    // }
    return ({ assessmentAnswers }) => {
      const answerMap = {};
      for (const section of assessmentSections) {
        for (const question of section.questions) {
          answerMap[question.id] = "NE";
        }
      }
      for (const one of assessmentAnswers) {
        answerMap[one.question] = one.answer;
      }
      // answerMap = {
      //   1: 'NE',
      //   2: 'BG',
      //   3: 'NE',
      //   ...
      // }

      const filterByQuestionId = (questionId) => {
        const studentAnswer = answerMap[questionId];
        return searchMap[questionId][studentAnswer];
      };

      for (const questionId of Object.keys(searchMap)) {
        if (!filterByQuestionId(questionId)) {
          return false;
        }
      }
      return true;
    };
  };

  const getSkillTagsFilter = () => {
    return (one) => {
      const { skillTags, topSkillTags } = one.information;
      const { techSkills: filterTechSkills, topTechSkills: filterTopTechSkills } = filter.skillTags;
      const r1 = (() => {
        if (Object.values(filterTechSkills).filter((x) => x === true).length === 0) {
          return true;
        }
        for (const id of Object.keys(filterTechSkills)) {
          if (filterTechSkills[id] && skillTags.id2idx[id] !== undefined) {
            return true;
          }
        }
        return false;
      })();
      const r2 = (() => {
        if (Object.keys(filterTopTechSkills).length === 0) {
          return true;
        }
        for (const id of Object.keys(filterTopTechSkills)) {
          const { rating } = filterTopTechSkills[id];
          const idx = topSkillTags.id2idx[id];
          if (idx === undefined) {
            continue;
          }
          if (topSkillTags.data[idx].rating >= rating) {
            return true;
          }
        }
        return false;
      })();
      return r1 && r2;
    };
  };

  const filterAssessment = getAssessmentFilter();
  const filterSkillTags = getSkillTagsFilter();
  const filteredData = data.filter((one) => {
    return (
      getSingleFilter("information", "entranceDate")(one) &&
      getSingleFilter("information", "expGraduationDate")(one) &&
      getSingleFilter("profile", "campus")(one) &&
      getSingleFilter("profile", "program")(one) &&
      getMulFilter("availability")(one) &&
      getMulFilter("coursesCompleted")(one) &&
      filterSkillTags(one) &&
      filterAssessment(one)
    );
  });

  return filteredData;
};

const initData = (m: SelectionState["selectionMap"], data: any[]) => {
  const id2idx = {};
  const newData = data.map((one, i) => {
    const skillTags = { data: [], id2idx: {} };
    one.information.skillTags.forEach((id, i) => {
      skillTags.data.push(m.skillTags[id]);
      skillTags.id2idx[id] = i;
    });
    one.information.skillTags = skillTags;

    const topSkillTags = { data: [], id2idx: {} };
    one.information.topSkillTags.forEach(({ skillTag: id, rating }, i) => {
      topSkillTags.data.push({ skillTag: m.skillTags[id], rating });
      topSkillTags.id2idx[id] = i;
    });
    one.information.topSkillTags = topSkillTags;

    one.information.coursesCompleted = one.information.coursesCompleted.map((one) => m.course[one]);
    one.information.entranceDate = m.entranceDate[one.information.entranceDate];
    one.information.expGraduationDate = m.expGraduationDate[one.information.expGraduationDate];
    one.information.availability = one.information.availability.map((one) => m.availability[one]);
    one.profile.campus = m.campus[one.profile.campus];
    one.profile.program = m.program[one.profile.program];
    one.profile.coopAdviser = m.coopAdviser[one.profile.coopAdviser];
    one.idx = i;
    id2idx[one.userId] = i;
    return one;
  });
  return { data: newData, id2idx };
};

const getPage = (currentPage, pageSize, filteredData) => {
  const start = (currentPage - 1) * pageSize;
  const end = start + pageSize;
  return filteredData.slice(start, end);
};

export const reducer: Reducer<SearchState, SearchActions> = (state = initialState, action: SearchActions) => {
  if (typeof action === "undefined") {
    return initialState;
  }
  switch (action.type) {
    case AT.START_TOUCHING: {
      return {
        ...state,
        listSpinning: true,
      };
    }
    case AT.GET_DATA_DONE: {
      const { data, generatedAt, id2idx } = action;
      return {
        ...state,
        data,
        generatedAt,
        id2idx,
        dataGot: true,
      };
    }
    case AT.SET_FILTER_DONE: {
      const { filter, filteredData } = action;
      return {
        ...state,
        filter,
        filteredData,
      };
    }
    case AT.PAGE_CHANGE: {
      const pageSize = action.pageSize || state.pageSize;
      const currentPage = action.currentPage || state.currentPage;
      return {
        ...state,
        pageSize,
        currentPage,
        listSpinning: false,
        page: getPage(currentPage, pageSize, state.filteredData),
      };
    }
    case AT.SELECT_STUDENTS: {
      const newState = {
        ...state,
        isSelected: { ...state.isSelected },
        selectedIdxs: [...state.selectedIdxs],
      };
      for (const idx of action.idxs) {
        if (!newState.isSelected[idx]) {
          newState.isSelected[idx] = true;
          newState.selectedIdxs.push(idx);
        }
      }
      return newState;
    }
    case AT.DESELECT_STUDENTS: {
      const newState = {
        ...state,
        isSelected: { ...state.isSelected },
        selectedIdxs: [...state.selectedIdxs],
      };
      for (const idx of action.idxs) {
        if (newState.isSelected[idx]) {
          newState.isSelected[idx] = false;
          newState.selectedIdxs = newState.selectedIdxs.filter((one) => one !== idx);
        }
      }
      return newState;
    }
    case AT.TOGGLE_SELECT_STUDENT: {
      const { idx } = action;
      const newState = {
        ...state,
        isSelected: {
          ...state.isSelected,
          [idx]: !state.isSelected[idx],
        },
        selectedIdxs: [...state.selectedIdxs],
      };
      if (newState.isSelected[idx]) {
        newState.selectedIdxs.push(idx);
      } else {
        newState.selectedIdxs = newState.selectedIdxs.filter((one) => one !== idx);
      }
      return newState;
    }
    case AT.OPEN_CART_DRAWER: {
      return {
        ...state,
        isCartDrawerOpen: true,
      };
    }
    case AT.CLOSE_CART_DRAWER: {
      return {
        ...state,
        isCartDrawerOpen: false,
      };
    }
    case AT.OPEN_LIST_DRAWER: {
      return {
        ...state,
        isListDrawerOpen: true,
      };
    }
    case AT.CLOSE_LIST_DRAWER: {
      return {
        ...state,
        isListDrawerOpen: false,
      };
    }
    case AT.OPEN_EMPLOYER_MODAL: {
      return {
        ...state,
        employersModalVisible: true,
        selectedList: action.list,
      };
    }
    case AT.CLOSE_EMPLOYER_MODAL: {
      return {
        ...state,
        employersModalVisible: false,
        selectedList: null,
      };
    }
    default: {
      return state;
    }
  }
};

export const actions = {
  initSearch,
  setFilter,
  pageChange,
  selectStudents,
  deselectStudents,
  toggleSelectStudent,
  closeCartDrawer,
  openCartDrawer,
  toggleCartDrawer,
  closeListDrawer,
  openListDrawer,
  toggleListDrawer,
  saveStudentList,
  deleteStudentList,
  openEmployerModal,
  closeEmployerModal,
};
