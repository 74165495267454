import { Action } from "redux";

export interface PUState {
  readonly data: any[];
  readonly dataGot: boolean;
  readonly spinning: boolean;
  readonly id2idx: any;
}

export enum PUActionTypes {
  TOUCH_START = "PU/TOUCH_START",
  LOAD_DONE = "PU/LOAD_DONE",
  UPDATE_HA_DONE = "PU/UPDATE_HA",
  BULK_UPDATE_HA_DONE = "PU/BULK_UPDATE_HA",
  BULK_ADVISER_DONE = "PU/BULK_ADVISER_DONE"
}

export class TouchStart implements Action {
  readonly type = PUActionTypes.TOUCH_START;
}

export class LoadDone implements Action {
  readonly type = PUActionTypes.LOAD_DONE;
  constructor(public data: any) {}
}

export class UpdateHA implements Action {
  readonly type = PUActionTypes.UPDATE_HA_DONE;
  constructor(public id: number, public isHidden: boolean, public isActive: boolean) {}
}

export class BulkUpdateHA implements Action {
  readonly type = PUActionTypes.BULK_UPDATE_HA_DONE;
  constructor(public profileIds: number[], public attr: "isHidden" | "isActive", public value: boolean) {}
}

export class BulkAdviser implements Action {
  readonly type = PUActionTypes.BULK_ADVISER_DONE;
  constructor(public profileIds: number[], public adviserObj: any) {}
}

export type PUActions = TouchStart | LoadDone | UpdateHA | BulkUpdateHA | BulkAdviser;
