import * as React from "react";
import { connect } from "react-redux";
import { Divider, Input, Spin, Form, Button, Select, Radio, Upload, Icon, Avatar, message } from "antd";

import * as api from "../../api";
import { actions } from "../../store";
import { ComponentProps } from "../../store/types";
import { FormComponentProps } from "antd/lib/form";

class PhotoUploadComp extends React.Component<
  ComponentProps & { readonly facultyPreview: boolean; readonly onErrors: (errors: Error[]) => void }
> {
  beforeUpload(file) {
    if (!this.isValid(file)) {
      return false;
    }
    if (this.props.facultyPreview) {
      message.info("[Faculty Demo] Photo Uploaded");
      return false;
    }
    const formData = new (window as any).FormData();
    formData.append("photo", file);
    this.props.dispatch(actions.uploadPhoto(formData));
    return false;
  }

  isValid(file) {
    const msgs = [];

    const isJPG = file.type === "image/jpeg";
    if (!isJPG) {
      msgs.push("You can only upload JPG file.");
    }

    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      msgs.push("Image must smaller than 2MB!");
    }

    if (this.props.onErrors) {
      this.props.onErrors(msgs.map(msg => new Error(msg)));
    } else {
      msgs.forEach(msg => message.error(msg));
    }

    return isJPG && isLt2M;
  }

  render() {
    const photoUrl = this.props.auth.photoUrl;

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={file => this.beforeUpload(file)}
        // onChange={info => this.handleChange(info)} // parent get this from store
      >
        {photoUrl ? (
          <Avatar shape="square" size={96} src={photoUrl} />
        ) : (
          <div>
            <Icon type={this.props.auth.photoUploading ? "loading" : "plus"} />
            <div className="ant-upload-text">{this.props.auth.photoUploading ? "Uploading" : "Upload"}</div>
          </div>
        )}
      </Upload>
    );
  }
}

const { Option } = Select;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;

class ProfileForm extends React.Component<
  ComponentProps & FormComponentProps & { readonly facultyPreview: boolean },
  {
    selection: { coopAdviserSelection: any[]; programSelection: any[]; campusSelection: any[] };
    selectionGot: boolean;
  }
> {
  constructor(props) {
    super(props);
    this.state = {
      selection: { coopAdviserSelection: [], programSelection: [], campusSelection: [] },
      selectionGot: true
    };
  }

  onSubmit(e) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        if (this.props.facultyPreview) {
          message.info("[Faculty Demo] Profile Saved");
          this.props.dispatch(actions.push("1-tech-self-assessment"));
        } else {
          api.profile.submit(values).then(() => {
            message.success("Profile Saved");
            const { from } = this.props.router.location.state || { from: null };
            if (from) {
              this.props.dispatch(actions.push(from));
            }
          });
        }
      }
    });
  }

  onPhotoUpload() {
    this.props.form.setFieldsValue({ photo: true });
  }

  onPhotoErrors(errors) {
    this.props.form.setFields({ photo: { value: false, errors } });
  }

  componentDidMount() {
    api.profile.getSelection().then(selection => this.setState({ selection, selectionGot: true }));
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <Divider style={{ marginTop: 0 }} />
        <p>Please submit your profile before using Co-op App.</p>
        <Spin spinning={!this.state.selectionGot}>
          {this.state.selectionGot ? (
            <Form onSubmit={e => this.onSubmit(e)}>
              <FormItem label="First Name" style={{ marginBottom: "4px" }}>
                {getFieldDecorator("firstName", {
                  rules: [{ required: true, whitespace: true, message: "Please input your first name" }],
                  initialValue: this.props.auth.profile && this.props.auth.profile.firstName
                })(<Input />)}
              </FormItem>
              <FormItem label="Last Name" style={{ marginBottom: "4px" }}>
                {getFieldDecorator("lastName", {
                  rules: [{ required: true, whitespace: true, message: "Please input your last name" }],
                  initialValue: this.props.auth.profile && this.props.auth.profile.lastName
                })(<Input />)}
              </FormItem>
              <FormItem label="NUID" style={{ marginBottom: "4px" }}>
                {getFieldDecorator("NUID", {
                  rules: [
                    { required: true, message: "Please input your NUID" },
                    { pattern: /\d{9}/, message: "NUID must be 9 digits" }
                  ],
                  initialValue: this.props.auth.profile && this.props.auth.profile.NUID
                })(<Input placeholder="9-digit student number starting with “00”." />)}
              </FormItem>
              <FormItem label="Campus" style={{ marginBottom: "4px" }}>
                {getFieldDecorator("campus", {
                  rules: [{ required: true, message: "Please select your campus." }],
                  initialValue: this.props.auth.profile && this.props.auth.profile.campus
                })(
                  <Select>
                    {this.state.selection.campusSelection.map((one, i) => (
                      <Option key={i.toString()} value={one.id}>
                        {one.text}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem label="Program" style={{ marginBottom: "4px" }}>
                {getFieldDecorator("program", {
                  rules: [{ required: true, message: "Please select your program." }],
                  initialValue: this.props.auth.profile && this.props.auth.profile.program
                })(
                  <Select>
                    {this.state.selection.programSelection.map((one, i) => (
                      <Option key={i.toString()} value={one.id}>
                        {one.text}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem label="Co-op Adviser" style={{ marginBottom: "4px" }}>
                {getFieldDecorator("coopAdviser", {
                  rules: [{ required: true, message: "Please select your co-op adviser." }],
                  initialValue: this.props.auth.profile && this.props.auth.profile.coopAdviser
                })(
                  <RadioGroup
                    options={this.state.selection.coopAdviserSelection.map(one => ({ label: one.text, value: one.id }))}
                  />
                )}
              </FormItem>
              <FormItem label="Profile Photo (jpeg only, less than 2MB)">
                {getFieldDecorator("photo", {
                  rules: [
                    {
                      required: true,
                      message: "Please upload your profile photo."
                    },
                    {
                      type: "enum",
                      enum: "yes",
                      message: "Please upload your profile photo."
                    }
                  ],
                  initialValue: this.props.auth.photoUrl ? "yes" : "no"
                })(<Input style={{ display: "none" }} />)}
                <PhotoUploadComp onErrors={err => this.onPhotoErrors(err)} {...this.props} />
              </FormItem>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                Submit
              </Button>
            </Form>
          ) : (
            <div style={{ width: "100%", height: 24 }} />
          )}
        </Spin>
      </div>
    );
  }
}

export default connect(state => state)(Form.create()(ProfileForm));
