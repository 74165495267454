import * as api from "../api";
import { EmployerState, LoadEmployerDone, EmployerActions, EmployerActionTypes as AT } from "./employers.types";
import { ThunkActionCreator } from "./types";
import { ActionCreator, Reducer } from "redux";

const loadEmployersDone: ActionCreator<LoadEmployerDone> = data => ({
  type: AT.LOAD_DONE,
  data
});

const loadEmployer: ThunkActionCreator = () => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return Promise.resolve()
    .then(() => api.employer.list())
    .then(data => dispatch(loadEmployersDone(data)));
};

const updateEmployer: ThunkActionCreator = (idx, id, data) => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return Promise.resolve()
    .then(() => api.employer.update(id, clearEmployerData(data)))
    .then(data => dispatch({ type: AT.UPDATE_DONE, idx, data }));
};

const createEmployer: ThunkActionCreator = data => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return Promise.resolve()
    .then(() => api.employer.create(clearEmployerData(data)))
    .then(data => dispatch({ type: AT.CREATE_DONE, data }));
};

const deleteEmployer: ThunkActionCreator = (idx, id) => dispatch => {
  dispatch({ type: AT.TOUCH_START });
  return Promise.resolve()
    .then(() => api.employer.del(id))
    .then(() => dispatch({ type: AT.DELETE_DONE, idx }));
};

const clearEmployerData = ({ username, email, expireAt }) => {
  const data = {};
  if (username !== undefined) data["username"] = username;
  if (email !== undefined) data["email"] = email;
  if (expireAt !== undefined) data["expireAt"] = expireAt;
  return data;
};

const initialState: EmployerState = {
  data: [],
  dataGot: false,
  spinning: false,
  id2idx: {}
};

export const reducer: Reducer<EmployerState, EmployerActions> = (state = initialState, action) => {
  if (typeof action === "undefined") {
    return initialState;
  }

  switch (action.type) {
    case AT.LOAD_DONE: {
      const id2idx = {};
      action.data.forEach((one, i) => {
        id2idx[one.id] = i;
      });
      return {
        ...state,
        data: action.data,
        dataGot: true,
        spinning: false,
        id2idx
      };
    }
    case AT.TOUCH_START: {
      return {
        ...state,
        spinning: true
      };
    }
    case AT.UPDATE_DONE: {
      const old = state.data;
      const { data, idx } = action;
      return {
        ...state,
        spinning: false,
        data: old
          .slice(0, idx)
          .concat([data])
          .concat(old.slice(idx + 1))
      };
    }
    case AT.DELETE_DONE: {
      state.data.splice(action.idx, 1);
      return {
        ...state,
        spinning: false,
        data: [...state.data]
      };
    }
    case AT.CREATE_DONE: {
      return {
        ...state,
        spinning: false,
        data: [action.data, ...state.data]
      };
    }
    default:
      return { ...state };
  }
};

export const actions = { loadEmployer, updateEmployer, createEmployer, deleteEmployer };
