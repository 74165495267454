import * as React from "react";
import { message } from "antd";
import copy from "copy-to-clipboard";

const PrivateLink = ({ rawLink, secKey }) => {
  const link = `${rawLink}?key=${secKey}`;
  return (
    <span>
      <a href={link} target="_blank">
        {link}
      </a>
      <span style={{ padding: "0 5px" }}>|</span>
      <a
        onClick={() => {
          copy(link);
          message.success("Link copied to clipboard.");
        }}
      >
        copy
      </a>
    </span>
  );
};

export default PrivateLink;
