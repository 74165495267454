import * as React from "react";
import { connect } from "react-redux";
import {
  message,
  Spin,
  Drawer,
  Button,
  List,
  Icon,
  Collapse,
  Card,
  Row,
  Col,
  Select,
  Form,
  Popconfirm,
  Input,
} from "antd";
import moment from "moment";

import { actions } from "../../../store";
import "./CartDrawer.css";
import { FormComponentProps } from "antd/lib/form";
import { RootDispatch, RootState } from "../../../store/types";
import Fab from "./Fab";

const Handle = connect(
  (state: RootState) => ({ hidden: state.search.isListDrawerOpen, iconType: "menu-fold", idx: 0 }),
  (dispatch: RootDispatch) => ({ onClick: dispatch(actions.search.toggleListDrawer()) })
)(Fab);

const ListDrawerComp = ({ spinning, userList, selectedIdxs, id2idx, dispatch, visible }) => {
  const copyToCart = (ids) => {
    const idxs = [];
    for (const id of ids) {
      if (id2idx[id] !== undefined) {
        idxs.push(id2idx[id]);
      }
    }

    dispatch(actions.search.deselectStudents(selectedIdxs));
    dispatch(actions.search.selectStudents(idxs));
    const notfound = idxs.length - ids.length;
    if (notfound) {
      message.warn(`${idxs.length} of ${ids.length} student(s) copied, ${notfound} student(s) not found.`);
    } else {
      message.success("Student list copied.");
    }
  };

  const showLinkModal = (e, one) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(actions.search.openEmployerModal(one));
  };
  return (
    <Drawer
      title="Student Lists"
      width={360}
      visible={visible}
      onClose={() => dispatch(actions.search.closeListDrawer())}
    >
      <Spin spinning={spinning}>
        <Collapse bordered={false}>
          {userList.data.map((one, i) => (
            <Collapse.Panel
              header={
                <Row type="flex" justify="space-between" align="middle" style={{ paddingRight: 14 }}>
                  <Col>
                    <p>
                      {one.title} ({one.users.length})<br />
                      <span style={{ color: "rgba(0, 0, 0, 0.45)" }}>
                        Created at {moment(one.createdAt).fromNow()}.
                      </span>
                    </p>
                  </Col>
                  <Col>
                    <Button shape="circle" onClick={(e) => showLinkModal(e, one)} size="small">
                      <Icon type="link" />
                    </Button>
                  </Col>
                </Row>
              }
              key={one.id}
            >
              <Card
                className="list-drawer-card"
                bordered={false}
                actions={[
                  <Popconfirm
                    title="Replace currently selected with this list?"
                    onConfirm={() => copyToCart(one.users)}
                    okText="Replace"
                  >
                    <Icon type="copy" />
                  </Popconfirm>,
                  <Icon type="link" onClick={(e) => showLinkModal(e, one)} />,
                  <Popconfirm
                    title="Delete this list?"
                    onConfirm={() => dispatch(actions.search.deleteStudentList(one.id, i))}
                    okText="Delete"
                  >
                    <Icon type="delete" />
                  </Popconfirm>,
                ]}
              >
                <ol style={{ paddingLeft: 18 }}>
                  {one.userStudentprofiles.map((p, i) => (
                    <li key={i} style={{ fontWeight: "bold" }}>
                      <p style={{ fontWeight: "normal" }}>
                        <span>{` ${p.firstName} ${p.lastName} `}</span>
                        {`(${p.nuid})`}
                      </p>
                    </li>
                  ))}
                </ol>
                <p>{one.comment}</p>
              </Card>
            </Collapse.Panel>
          ))}
        </Collapse>
      </Spin>
    </Drawer>
  );
};

const ListDrawer = connect((state: RootState) => {
  return {
    ...state.search,
    userList: state.userList,
    visible: state.search.isListDrawerOpen,
    spinning: state.userList.spinning,
  };
})(ListDrawerComp);

class CartDrawerComp extends React.Component<
  { selectedData: any[]; visible: boolean; spinning: boolean } & FormComponentProps & { dispatch: RootDispatch }
> {
  handleSubmit(e) {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { listComment, listTitle, users } = values;
        this.props
          .dispatch(actions.search.saveStudentList({ users, comment: listComment, title: listTitle }))
          .then(() => message.success("List saved."));
      } else {
        console.error(err);
      }
    });
  }

  render() {
    const { selectedData, visible, dispatch, spinning } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Drawer
          width={480}
          title={`Selected Students(${selectedData.length})`}
          visible={visible}
          onClose={() => dispatch(actions.search.closeCartDrawer())}
        >
          <Form onSubmit={(e) => this.handleSubmit(e)} className="search-drawer-form">
            <Form.Item className="mb0">
              {getFieldDecorator("listTitle", {
                rules: [{ required: true, whitespace: true, message: "Please input list title." }],
              })(<Input placeholder="List Title" />)}
            </Form.Item>
            <List
              dataSource={selectedData}
              locale={{ emptyText: "no selected student" }}
              renderItem={(one, i) => (
                <List.Item
                  key={one.idx}
                  actions={[
                    <Button
                      icon="delete"
                      size="small"
                      shape="circle"
                      onClick={() => dispatch(actions.search.deselectStudents([one.idx]))}
                    />,
                  ]}
                >
                  <b>{`${i + 1}. `}</b>
                  <span>{` ${one.profile.firstName} ${one.profile.lastName} `}</span> {`(${one.profile.nuid})`}
                </List.Item>
              )}
            />
            {getFieldDecorator("users", { initialValue: selectedData.map((one) => one.userId) })(
              <Select mode="tags" style={{ display: "none" }} />
            )}
            {getFieldDecorator("listComment")(<Input.TextArea placeholder="List Comment" autosize={{ minRows: 2 }} />)}
            <Spin spinning={spinning}>
              <Button type="primary" htmlType="submit" style={{ width: "100%" }} disabled={selectedData.length === 0}>
                Save List
              </Button>
            </Spin>
          </Form>
          <ListDrawer />
          <Handle />
        </Drawer>
      </div>
    );
  }
}

const CartDrawer = connect((state: RootState) => {
  const selectedIdxs = state.search.selectedIdxs;
  return {
    selectedData: selectedIdxs ? selectedIdxs.map((idx) => state.search.data[idx]) : [],
    visible: state.search.isCartDrawerOpen,
    spinning: state.userList.spinning,
  };
})(Form.create()(CartDrawerComp));

export default CartDrawer;
