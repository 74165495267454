import { get } from "./utils";

const getAllSelections = ({ all = false }) =>
  get("/api/all-selections/").then(data => {
    const keys = [
      "entranceDateSelection",
      "expGraduationDateSelection",
      "availabilitySelection",
      "courseSelection",
      "programSelection",
      "campusSelection",
      "coopAdviserSelection",
      "skillTags"
    ];

    for (const key of keys) {
      if (!all) {
        data[key] = data[key].filter(one => !one.isHidden);
      }
      if (key.endsWith("Selection")) {
        data[key.slice(0, key.length - "Selection".length)] = data[key];
      }
    }
    return data;
  });

const getSurveyFields = () => get("/api/survey-field/");

const getSkillTags = () =>
  Promise.all([get("/api/skill-tags/i/"), get("/api/skill-tags/t/")]).then(v => ({ i: v[0], t: v[1] }));

export const selection = {
  getAllSelections,
  getSurveyFields,
  getSkillTags
};
