import * as React from "react";
import { Alert } from "antd";
import StudentProfile from "../../student/StudentProfile";

export default function PreviewProfileComp() {
  return (
    <React.Fragment>
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <Alert
          type="info"
          message="This page is a preview."
          showIcon
          description={
            <span>
              Students are required to input their basic profile at the very first beginning. They can also modify it
              from the Profile link at the top-right dropdown menu.<br></br>
              In this preview, all the validations work the same as is. After filling all the fields correctly and
              clicking the Submit button, you will be redirected to the tech self assessment preview page.
            </span>
          }
          closable
          style={{ marginBottom: "1em" }}
        ></Alert>
      </div>
      <StudentProfile facultyPreview></StudentProfile>
    </React.Fragment>
  );
}
