import { connect } from "react-redux";
import * as React from "react";
import { Card, Row, Col, Button, Form, Tooltip, Icon, Switch, Typography } from "antd";

import { actions } from "../../../store";
// import FilterAssessment from "./FilterAssessment";
import FilterTags from "./FilterTags";
import FilterBasic from "./FilterBasic";
import { SearchState, Filter } from "../../../store/search.types";
import { FormComponentProps } from "antd/lib/form";
import { RootDispatch } from "../../../store/types";

const Time = ({ generatedAt }) => (
  <Tooltip placement="left" title={`Data generated at ${generatedAt}`}>
    <a>
      <Icon type="clock-circle" />
    </a>
  </Tooltip>
);

class FilterComponent extends React.Component<SearchState & FormComponentProps & { dispatch: RootDispatch }> {
  onSubmit(e) {
    e.preventDefault();
    this.validateAndDispatch();
  }

  onReset() {
    this.props.form.resetFields();
    this.validateAndDispatch();
  }

  validateAndDispatch() {
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        values.coursesCompleted = values.coursesCompleted.map(one =>
          parseInt(
            one
              .split("-")
              .slice(-1)
              .pop(),
            10
          )
        );
        const filter: Filter = {
          assessment: [],
          single: {
            ...values
          },
          multiple: {
            ...values
          },
          skillTags: {
            ...values
          }
        };
        this.props.dispatch(actions.search.setFilter(filter));
      }
    });
  }

  render() {
    return (
      <Form onSubmit={e => this.onSubmit(e)} onReset={() => this.onReset()}>
        <Card title="Student Profile Search" bordered={false} extra={<Time {...this.props} />}>
          <FilterBasic form={this.props.form} />
          <FilterTags form={this.props.form} />
          {/* <FilterAssessment form={this.props.form} /> */}
          <Row gutter={24} type="flex" style={{ marginTop: 24 }}>
            <Col>
              <Button htmlType="submit" type="primary">
                Filter
              </Button>
            </Col>
            <Col>
              <Button htmlType="reset">Reset</Button>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

const Filter = Form.create({})(FilterComponent);

export default connect(state => state.search)(Filter);
