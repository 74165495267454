import * as React from "react";
import { connect } from "react-redux";
import { Spin, List, Button, Row, Col, Skeleton, Divider, Timeline } from "antd";
import moment = require("moment");

import ApprovalForm from "./ApprovalForm";
import { ComponentProps } from "../../../store/types";
import { ApprovalStudentActionTypes as AT } from "../../../store/approval-student.types";
import { actions } from "../../../store";

class ApprovalTimeline extends React.Component<{ approval: any; showHere: boolean; startNew: any }> {
  getCoopAdviserTimelineColor(approval) {
    if (!approval.finished) {
      return "#ECECEC";
    } else if (approval.rejected) {
      return "red";
    } else if (approval.coopAdviserApproved) {
      return "green";
    } else {
      return "blue";
    }
  }

  getCoopAdviserTimelineContent(approval) {
    if (approval.coopAdviserApproved) {
      return (
        <p>
          <b>Co-op Adviser Approved At:</b>
          <br />
          {moment(approval.coopAdviserApprovedAt).format("LLL")}
          <br />
          {approval.coopAdviserApprovedComment}
        </p>
      );
    } else {
      return (
        <p>
          <b>Co-op Adviser Approval</b>
          <br />
          {approval.finished ? "Pending" : ""}
        </p>
      );
    }
  }

  getAcademicAdviserTimelineColor(approval) {
    if (!approval.finished) {
      return "#ECECEC";
    } else if (approval.rejected) {
      return "red";
    } else if (approval.academicAdviserApproved) {
      return "green";
    } else {
      return "blue";
    }
  }

  getAcademicAdviserTimelineContent(approval) {
    if (approval.academicAdviserApproved) {
      return (
        <p>
          <b>Program Director Approved At:</b>
          <br />
          {moment(approval.academicAdviserApprovedAt).format("LLL")}
          <br />
          {approval.academicAdviserApprovedComment}
        </p>
      );
    } else {
      return (
        <p>
          <b>Program Director Approval</b> <br />
          {approval.finished ? "Pending" : ""}
        </p>
      );
    }
  }

  render() {
    const { approval, showHere, startNew } = this.props;
    return (
      <Timeline>
        <Timeline.Item color="green">
          <p>
            <b>Created At:</b> <br />
            {moment(approval.createdAt).format("LLL")}
          </p>
        </Timeline.Item>
        <Timeline.Item color={approval.finished ? "green" : "blue"}>
          {
            <p>
              <b>Submitted At:</b>
              <br />
              {approval.finished ? (
                moment(approval.finishedAt).format("LLL")
              ) : (
                <span>
                  You haven't submitted this approval.{" "}
                  {showHere && (
                    <span>
                      Click <a onClick={() => startNew()}>here</a> to finish it.
                    </span>
                  )}
                </span>
              )}
            </p>
          }
        </Timeline.Item>
        <Timeline.Item color={this.getCoopAdviserTimelineColor(approval)}>
          {this.getCoopAdviserTimelineContent(approval)}
        </Timeline.Item>
        <Timeline.Item color={this.getAcademicAdviserTimelineColor(approval)}>
          {this.getAcademicAdviserTimelineContent(approval)}
        </Timeline.Item>
        {approval.rejected && (
          <Timeline.Item color="red">
            <p>
              <b>Rejected At: </b>
              <br />
              {moment(approval.rejectedAt).format("LLL")}
              <br />
              {approval.rejectedComment}
            </p>
          </Timeline.Item>
        )}
      </Timeline>
    );
  }
}

class StudentApprovalHomeComp extends React.Component<
  ComponentProps & { readonly facultyPreview: boolean; readonly facultyMy: any }
> {
  componentDidMount() {
    if (this.props.facultyPreview) {
      const data = this.props.facultyMy || [];
      this.props.dispatch({ type: AT.LOAD_MY_DONE, data });
    } else {
      this.props.dispatch(actions.approvalStudent.retrieveMy());
    }
    this.props.dispatch(actions.selection.getSurveyFields());
  }

  startNew() {
    const { dispatch } = this.props;
    if (this.props.facultyPreview) {
      dispatch({
        type: AT.SET_CURRENT_APPROVAL,
        approval: {
          createdAt: new Date().toISOString()
        }
      });
    } else {
      dispatch(actions.approvalStudent.newApproval()).then(() => dispatch(actions.approvalStudent.retrieveMy()));
    }
  }

  renderFooter() {
    const { my, currentApproval } = this.props.approvalStudent;

    const finalized = my.filter(a => a.rejected || (a.coopAdviserApproved && a.academicAdviserApproved));
    const showStartNew = !currentApproval && my.length - finalized.length == 0;

    if (showStartNew) {
      return (
        <Row type="flex" justify="center">
          <Col>
            <Button type="primary" onClick={() => this.startNew()}>
              Start New
            </Button>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }

  renderItem(approval) {
    return (
      <List.Item>
        <ApprovalTimeline
          approval={approval}
          showHere={!this.props.approvalStudent.currentApproval}
          startNew={() => this.startNew()}
        />
      </List.Item>
    );
  }

  render() {
    return (
      <div style={{ maxWidth: 800, margin: "0 auto" }}>
        <h1>Co-op Approval</h1>
        <Spin spinning={this.props.approvalStudent.myLoading}>
          <List
            dataSource={this.props.approvalStudent.my}
            renderItem={item => this.renderItem(item)}
            footer={this.renderFooter()}
            locale={{ emptyText: "No Approval Submitted" }}
          />
        </Spin>
        {this.props.approvalStudent.currentApproval && (
          <div style={{ background: "#ECECEC", padding: "30px" }}>
            <Row type="flex" justify="center">
              <Col>
                <h2>Co-op Approval Form</h2>
              </Col>
            </Row>
            <Divider style={{ margin: "15px 0" }} />
            <ApprovalForm approval={this.props.approvalStudent.my} facultyPreview={this.props.facultyPreview} />
          </div>
        )}
        <div>
          <h2 style={{ marginTop: 10 }}>NOTE1：</h2>
          <p>
            This is only the very first step of your co-op approval. You'll still be needing to: <br />
            Go to the{" "}
            <a
              href="https://international.northeastern.edu/ogs/employment/curricular-practical-training-cpt-f1/"
              target="_blank"
            >
              OGS website
            </a>{" "}
            to watch the instructional videos, then submit an online{" "}
            <a href="https://international.northeastern.edu/ogs/forms/" target="_blank">
              CPT Authorization Request
            </a>{" "}
            form. Put your co-op adviser in as the approver. Ensure you are selected the advisor matches the advisor
            listed in NUcareers. Make sure that the start and end dates are correct and that you enter your actual work
            location where you will be working.
          </p>
          <h2 style={{ marginTop: 10 }}>NOTE2:</h2>
          <p>
            If the payor (company that will pay your paycheck) is a different company from where you will physically be
            working, then enter the payor information as the primary employer and the location where you will actually
            be working as the secondary entry. If your end-of-program date on your I-20 is <strong>BEFORE</strong> the
            last day of your co-op you must submit a program extension form or your co-op CPT request will be denied by
            OGS. Remember to sign and return your offer letter to the employer.
          </p>
          <h2 style={{ marginTop: 10 }}>REMEMBER：</h2>
          <p style={{ color: "red" }}>
            You cannot work even a single day until you have your I-20 which shows your work dates.
          </p>
        </div>
      </div>
    );
  }
}

export default connect(state => state)(StudentApprovalHomeComp);
