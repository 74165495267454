import * as React from "react";
import { Divider } from "antd";

import Step from "./Step";

export default class Done extends React.Component {
  render() {
    return (
      <div style={{ margin: 16 }}>
        <div style={{ maxWidth: 800, margin: "0 auto" }}>
          <Step currentStep={3} />
          <Divider />
          <h3 style={{ textAlign: "center" }}>You are all set, good luck!</h3>
        </div>
      </div>
    );
  }
}
