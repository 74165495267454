import * as React from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Divider, Button } from "antd";
import { ComponentProps } from "../../store/types";
import { actions } from "../../store";

const { Meta } = Card;

class Home extends React.Component<ComponentProps> {
  render() {
    return (
      <div>
        {/* <h1 style={{ textAlign: "center" }}>Co-op App</h1> */}
        <Row type="flex" justify="center" gutter={12} style={{ marginTop: 20 }}>
          <Col>
            <Card
              style={{ width: 375 }}
              hoverable
              onClick={() => this.props.dispatch(actions.push("/student/create/"))}
              actions={[
                <Button type="primary" style={{ width: 300 }}>
                  Start
                </Button>
              ]}
            >
              <Meta
                title="I Want a Co-op"
                description="Upload or update your information and let employers find you."
              />
              <Divider />
              <p>You will need to complete the following steps:</p>
              <ol style={{ marginTop: 5 }}>
                <li>Tech Self Assessment</li>
                <li>Information</li>
                <li>Resume</li>
              </ol>
            </Card>
          </Col>
          <Col>
            <Divider style={{ height: "100%" }} type="vertical" />
          </Col>
          <Col>
            <Card
              style={{ width: 375 }}
              hoverable
              onClick={() => this.props.dispatch(actions.push("/student/approval"))}
              actions={[
                <Button type="primary" style={{ width: 300 }}>
                  Start (Beta)
                </Button>
              ]}
            >
              <Meta title="I Got a Co-op" description="Complete the application and upload necessary documents." />
              <Divider />
              <p>You will need to complete the following steps:</p>
              <ol style={{ marginTop: 5 }}>
                <li>Application Form</li>
                <li>File Upload</li>
                <li>Survey</li>
              </ol>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default connect(state => state)(Home);
