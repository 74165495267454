import { createStore, applyMiddleware, compose, combineReducers, Store } from "redux";
import thunk from "redux-thunk";

import { RootState, Actions } from "./types";
import * as auth from "./auth";
import * as employer from "./employer";
import * as search from "./search";
import * as userList from "./userList";
import * as assessment from "./assessment";
import * as selection from "./selections";
import * as pu from "./profile-user";
import * as approvalStudent from "./approval-student";
import * as approvalFaculty from "./approval-faculty";
import * as adviser from "./adviser-user";
import { routerMiddleware, connectRouter, routerActions } from "connected-react-router";
import { createBrowserHistory } from "history";

export const history = createBrowserHistory();

const createRootReducers = history =>
  combineReducers({
    router: connectRouter(history),
    auth: auth.reducer,
    employer: employer.reducer,
    search: search.reducer,
    userList: userList.reducer,
    assessment: assessment.reducer,
    selection: selection.recuder,
    pu: pu.reducer,
    approvalStudent: approvalStudent.reducer,
    approvalFaculty: approvalFaculty.reducer,
    adviser: adviser.reducer
  });

export const actions = {
  ...auth.actions,
  ...employer.actions,
  ...routerActions,
  userList: userList.actions,
  search: search.actions,
  assessment: assessment.actions,
  selection: selection.actions,
  pu: pu.actions,
  approvalStudent: approvalStudent.actions,
  approvalFaculty: approvalFaculty.actions,
  adviser: adviser.actions
};

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store: Store<RootState, Actions> = createStore(
  createRootReducers(history),
  composeEnhancers(applyMiddleware(thunk, routerMiddleware(history)))
);
