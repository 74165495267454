import * as React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Table, Row, Col, Button, Icon, Popconfirm, Modal, Form, Input, DatePicker, Spin, message } from "antd";

import { actions } from "../../store";
import { FormComponentProps } from "antd/lib/form";
import { RootDispatch, ComponentProps } from "../../store/types";
import PrivateLink from "./search/PrivateLink";

class EmployerModalComp extends React.Component<
  FormComponentProps & {
    visible: boolean;
    id: number;
    idx: number;
    dispatch: RootDispatch;
    onCancel: () => {};
    username: string;
    email: string;
    expireAt: any;
  },
  { spinning: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { spinning: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible === false && this.props.visible === true) {
      this.props.form.resetFields();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.validateAdnSubmit();
  }

  validateAdnSubmit() {
    this.props.form.validateFields((err, values) => {
      const handleErr = res => {
        if (res.status === 400) {
          res.json().then(err => {
            const fields = {};
            Object.keys(err).forEach(k => {
              fields[k] = {
                errors: err[k].map(one => new Error(one)),
                value: values[k]
              };
            });
            this.props.form.setFields(fields);
          });
        }
      };
      if (!err) {
        const { idx, id } = this.props;
        this.setState({ spinning: true });
        const data = { ...values, expireAt: values.expireAt.toISOString() };
        if (id) {
          this.props
            .dispatch(actions.updateEmployer(idx, id, data))
            .then(() => {
              message.success("Saved.");
              this.props.onCancel();
            })
            .catch(handleErr)
            .finally(() => this.setState({ spinning: false }));
        } else {
          this.props
            .dispatch(actions.createEmployer(data))
            .then(() => {
              message.success("Created.");
              this.props.onCancel();
            })
            .catch(handleErr)
            .finally(() => this.setState({ spinning: false }));
        }
      }
    });
  }

  render() {
    const { id, username, email, expireAt, visible, onCancel, form } = this.props;
    const expireAtMoment = expireAt ? moment(expireAt) : moment().add(7, "days");
    const { getFieldDecorator } = form;
    return (
      <Modal
        visible={visible}
        title={id ? `Edit Employer ${username}` : "New Employer"}
        okText={id ? "Save" : "Create"}
        onCancel={onCancel}
        onOk={() => this.validateAdnSubmit()}
        confirmLoading={this.state.spinning}
      >
        <Spin spinning={this.state.spinning}>
          <Form onSubmit={e => this.handleSubmit(e)}>
            <Form.Item label="Username">
              {getFieldDecorator("username", {
                rules: [{ required: true, whitespace: true, message: "Please input username." }],
                initialValue: username || ""
              })(<Input placeholder="Username" suffix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />} />)}
            </Form.Item>
            <Form.Item label="Email">
              {getFieldDecorator("email", {
                rules: [
                  { required: true, message: "Please input email." },
                  { type: "email", message: "The input is not a valida email." }
                ],
                initialValue: email || ""
              })(<Input placeholder="Email" suffix={<Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />} />)}
            </Form.Item>
            <Form.Item label="Expire At">
              {getFieldDecorator("expireAt", {
                rules: [{ required: true, message: "Please select expire datetime." }],
                initialValue: expireAtMoment
              })(
                <DatePicker style={{ width: "100%" }} showTime format="MM/DD/YYYY HH:mm:ss" placeholder="Expire At" />
              )}
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    );
  }
}

const EmployerModal = Form.create()(EmployerModalComp);

const parseDT = dtStr => {
  const m = moment(dtStr);
  return `${m.format("L")} ${m.format("LT")}`;
};

class EmployerComp extends React.Component<ComponentProps, { modalVisible: boolean; modalData: any }> {
  constructor(props) {
    super(props);
    this.state = {
      modalVisible: false,
      modalData: {}
    };
  }

  componentDidMount() {
    this.props.dispatch(actions.loadEmployer()).then(() => {
      const qp = new URLSearchParams(this.props.router.location.search);
      if (qp.has("create")) {
        this.openModal(null);
      } else if (qp.has("edit")) {
        const id = parseInt(qp.get("edit"), 10);
        const idx = this.props.employer.id2idx[id];
        if (idx !== undefined) {
          this.openModal(idx);
        }
      }
    });
  }

  openModal(idx) {
    if (idx === null) {
      this.setState({ modalVisible: true, modalData: {} });
    } else {
      const employer = this.props.employer.data[idx];
      this.setState({
        modalVisible: true,
        modalData: {
          id: employer.id,
          username: employer.user.username,
          email: employer.user.email,
          expireAt: employer.expireAt,
          idx
        }
      });
    }
  }
  render() {
    const columns = [
      {
        title: "ID",
        dataIndex: "id"
      },
      {
        title: "Username",
        dataIndex: "user.username"
      },
      {
        title: "Email",
        dataIndex: "user.email",
        render: text => <a href={`mailto:${text}`}>{text}</a>
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        render: text => parseDT(text)
      },
      {
        title: "Last Visit",
        dataIndex: "user.lastLogin",
        render: text => (text ? parseDT(text) : "never")
      },
      {
        title: "Key",
        dataIndex: "key"
      },
      {
        title: "Private Search Link",
        key: "link",
        render: (_, record) => {
          const rawLink = `${window.location.origin}/employer/search`;
          return <PrivateLink rawLink={rawLink} secKey={record.key} />;
        }
      },
      {
        title: "Key Expire At",
        dataIndex: "expireAt",
        render: text => parseDT(text)
      },
      {
        title: "",
        dataIndex: "id",
        key: "action",
        render: (id, record, idx) => (
          <span>
            <a>
              <Icon type="edit" onClick={() => this.openModal(idx)} />
            </a>
            <span style={{ padding: "0 5px" }}>|</span>
            <Popconfirm
              title="Delete this account?"
              onConfirm={() => this.props.dispatch(actions.deleteEmployer(idx, id))}
              okText="Delete"
            >
              <a>
                <Icon type="delete" />
              </a>
            </Popconfirm>
          </span>
        )
      }
    ];

    return (
      <div className="white-content">
        <Row style={{ marginBottom: 24 }} type="flex" justify="space-between">
          <Col>
            <h1>Employers</h1>
          </Col>
          <Col>
            <Button type="primary" onClick={() => this.openModal(null)}>
              <Icon type="plus" /> New Employer
            </Button>
          </Col>
        </Row>
        <Table
          size="small"
          columns={columns}
          dataSource={this.props.employer.data}
          loading={!this.props.employer.dataGot}
          pagination={false}
        />
        <EmployerModal
          {...this.state.modalData}
          visible={this.state.modalVisible}
          dispatch={this.props.dispatch}
          onCancel={() => this.setState({ modalVisible: false })}
        />
      </div>
    );
  }
}

const Employer = connect(state => state)(EmployerComp);

export default Employer;
