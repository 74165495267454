import { connect } from "react-redux";
import * as React from "react";
import { List, Modal, Spin, Row, Col } from "antd";

import SelfAssessment from "./SelfAssessment";
import ListPagination from "./ListPagination";
import BulkCurrentPage from "./BulkCurrentPage";

import ListItem from "./ListItem";
import { ComponentProps } from "../../../store/types";
import AllToggler from "./AllToggler";

class ListComponent extends React.Component<
  ComponentProps & { selectable: boolean },
  { assessmentModalVisible: boolean; assessmentModalData: any }
> {
  constructor(props) {
    super(props);
    this.state = { assessmentModalVisible: false, assessmentModalData: {} };
  }

  getShowModal(item) {
    return () => {
      const assessmentModalData = {
        name: `${item.profile.firstName} ${item.profile.lastName}`,
        sections: this.props.assessment.sections,
        answers: item.assessmentAnswers
      };
      this.setState({ assessmentModalVisible: !this.state.assessmentModalVisible, assessmentModalData });
    };
  }

  render() {
    return (
      <Spin spinning={this.props.search.listSpinning}>
        <div style={{ marginTop: 12, marginLeft: 18 }}>
          <Row type="flex">
            <Col>
              <ListPagination total={this.props.search.filteredData.length} />
            </Col>
            {this.props.auth.role === "faculty" && (
              <Col>
                <AllToggler />
              </Col>
            )}
          </Row>
        </div>
        <div style={{ margin: "0 20px", width: "100%" }} />
        <List
          itemLayout="vertical"
          size="large"
          dataSource={this.props.search.page}
          renderItem={item => (
            <ListItem
              selectable={this.props.selectable}
              key={item.userId}
              item={item}
              getShowModal={item => this.getShowModal(item)}
            />
          )}
          header={this.props.selectable && <BulkCurrentPage />}
          footer={this.props.selectable && <BulkCurrentPage />}
        />
        <div style={{ marginTop: 12, marginLeft: 18 }}>
          <ListPagination total={this.props.search.filteredData.length} />
        </div>
        <Modal
          visible={this.state.assessmentModalVisible}
          title={this.state.assessmentModalData.name}
          footer={null}
          onCancel={() => this.setState({ assessmentModalVisible: false })}
        >
          <SelfAssessment {...this.state.assessmentModalData} />
        </Modal>
      </Spin>
    );
  }
}

export default connect(state => state)(ListComponent);
