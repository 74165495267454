import * as React from "react";
import { connect } from "react-redux";
import { WrappedFormUtils } from "antd/lib/form/Form";
import { Row, Col, Form, Input, Rate, Empty, Tag, InputNumber, Spin } from "antd";

import { ComponentProps } from "../../../store/types";
import desc from "../../topDescription";
import { actions } from "../../../store";

class FilterTags extends React.Component<ComponentProps & { form: WrappedFormUtils }> {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(actions.selection.getSkillTags());
  }

  handleTopTechSkillsChange(t, checked) {
    const value = { ...this.topTechSkillsValue };
    if (!checked) {
      delete value[t.id];
    } else {
      value[t.id] = { ...t, rating: 0 };
    }
    this.props.form.setFieldsValue({ topTechSkills: value });
  }

  handleRatingChange(t, rating) {
    this.props.form.setFieldsValue({ topTechSkills: { ...this.topTechSkillsValue, [t.id]: { ...t, rating } } });
  }

  handleTechSkillsChange(t, checked) {
    this.props.form.setFieldsValue({ techSkills: { ...this.techSkillsValue, [t.id]: checked } });
  }

  showMoreTags(field: "i" | "t") {
    const key = field === "t" ? "topTechSkillsShowing" : "techSkillsShowing";
    const value = field === "t" ? this.topTechSkillsShowingValue : this.techSkillsShowingValue;
    this.props.form.setFieldsValue({ [key]: value + 25 });
  }

  showAllTags(field: "i" | "t") {
    const key = field === "t" ? "topTechSkillsShowing" : "techSkillsShowing";
    const value = this.props.selection.skillTagsIT[field].raw.length;
    this.props.form.setFieldsValue({ [key]: value });
  }

  get techSkillsValue() {
    return this.props.form.getFieldValue("techSkills");
  }

  get techSkillsShowingValue() {
    return this.props.form.getFieldValue("techSkillsShowing");
  }

  get topTechSkillsValue() {
    return this.props.form.getFieldValue("topTechSkills");
  }

  get topTechSkillsShowingValue() {
    return this.props.form.getFieldValue("topTechSkillsShowing");
  }

  hasMore(field: "i" | "t") {
    if (this.spinning) {
      return false;
    }
    const cur = field === "t" ? this.topTechSkillsShowingValue : this.techSkillsShowingValue;
    const m = this.props.selection.skillTagsIT[field].raw.length;
    return cur < m;
  }

  get spinning() {
    return this.props.selection.spinning || !this.props.selection.skillTagsITGot;
  }

  get skillTagsI() {
    return this.spinning ? [] : this.props.selection.skillTagsIT.i.raw.slice(0, this.techSkillsShowingValue);
  }

  get skillTagsT() {
    return this.spinning ? [] : this.props.selection.skillTagsIT.t.raw.slice(0, this.topTechSkillsShowingValue);
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <React.Fragment>
        {getFieldDecorator("techSkills", { initialValue: {} })(<Input hidden />)}
        {getFieldDecorator("techSkillsShowing", { initialValue: 50 })(<InputNumber style={{ display: "none" }} />)}
        {getFieldDecorator("topTechSkills", { initialValue: {} })(<Input hidden />)}
        {getFieldDecorator("topTechSkillsShowing", { initialValue: 50 })(<InputNumber style={{ display: "none" }} />)}
        <Row>
          <Col>
            <Form.Item label="Tech Skills">
              <Spin spinning={this.spinning}>
                {this.skillTagsI.map(t => (
                  <Tag.CheckableTag
                    key={t.id}
                    onChange={checked => this.handleTechSkillsChange(t, checked)}
                    checked={!!this.techSkillsValue[t.id]}
                  >{`${t.text}(${t.count})`}</Tag.CheckableTag>
                ))}
                {this.hasMore("i") && (
                  <React.Fragment>
                    <a onClick={() => this.showMoreTags("i")}>Show More Tags</a> |{" "}
                    <a onClick={() => this.showAllTags("i")}>Show All Tags</a>
                  </React.Fragment>
                )}
              </Spin>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Item label="Top Tech Skills With Minimun Rating">
              <Row type="flex" gutter={16}>
                {Object.values(this.topTechSkillsValue).map(t => (
                  <Col key={t["text"]}>
                    <Tag>{t["text"]}</Tag>
                    <Rate tooltips={desc} value={t["rating"]} onChange={r => this.handleRatingChange(t, r)} />
                  </Col>
                ))}
              </Row>
              <Spin spinning={this.spinning}>
                <Row type="flex">
                  <Col>
                    {this.skillTagsT.length > 0 ? (
                      this.skillTagsT.map(t => (
                        <Tag.CheckableTag
                          key={t.id}
                          onChange={checked => this.handleTopTechSkillsChange(t, checked)}
                          checked={!!this.topTechSkillsValue[t.id]}
                        >{`${t.text}(${t.count})`}</Tag.CheckableTag>
                      ))
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                    {this.hasMore("t") && (
                      <React.Fragment>
                        <a onClick={() => this.showMoreTags("t")}>Show More Tags</a> |{" "}
                        <a onClick={() => this.showAllTags("t")}>Show All Tags</a>
                      </React.Fragment>
                    )}
                  </Col>
                </Row>
              </Spin>
            </Form.Item>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default connect(state => state)(FilterTags);
