import { Action } from "redux";

export interface UserListState {
  readonly data: any[];
  readonly dataGot: boolean;
  readonly spinning: boolean;
  readonly id2idx: any;
}

export enum UserListActionTypes {
  TOUCH_START = "ULIST/TOUCH_START",
  LOAD_DONE = "ULIST/LOAD_DONE",
  DELETE_DONE = "ULIST/DELETE_DONE",
  CREATE_DONE = "ULIST/CREATE_DONE"
}

export class TouchStart implements Action {
  readonly type = UserListActionTypes.TOUCH_START;
}

export class LoadDone implements Action {
  readonly type = UserListActionTypes.LOAD_DONE;
  constructor(public data: any) {}
}

export class DeleteDone implements Action {
  readonly type = UserListActionTypes.DELETE_DONE;
  constructor(public id: number) {}
}

export class CreateDone implements Action {
  readonly type = UserListActionTypes.CREATE_DONE;
  constructor(public data: any) {}
}

export type UserListActions = TouchStart | LoadDone | DeleteDone | CreateDone;
