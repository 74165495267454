import * as React from "react";
import { Tabs, Row, Col } from "antd";
import { ComponentProps } from "../../../../store/types";
import { actions } from "../../../../store";

import FacultyAccounts from "./FacultyAccounts";
import Selection from "./Selection";

export default class Advisers extends React.Component<ComponentProps, { newFacultyAccountModalVisible: boolean }> {
  constructor(props) {
    super(props);
    this.state = { newFacultyAccountModalVisible: false };
  }

  componentDidMount() {
    this.props.dispatch(actions.selection.getAllSelections());
  }

  closeNewFacultyAccountModal() {
    this.setState({ newFacultyAccountModalVisible: false });
  }

  openNewFacultyAccountModal() {
    this.setState({ newFacultyAccountModalVisible: true });
  }

  render() {
    const facultyModalProps = {
      newFacultyAccountModalVisible: this.state.newFacultyAccountModalVisible,
      closeNewFacultyAccountModal: () => this.closeNewFacultyAccountModal(),
      openNewFacultyAccountModal: () => this.openNewFacultyAccountModal()
    };
    return (
      <React.Fragment>
        <Row type="flex" gutter={16}>
          <Col style={{ flex: 1 }}>
            <Tabs>
              <Tabs.TabPane key="coop" tab="Co-op Adviser Selection">
                <Selection {...this.props} {...facultyModalProps} type="coop" />
              </Tabs.TabPane>
              <Tabs.TabPane key="program" tab="Program Selection">
                <Selection {...this.props} {...facultyModalProps} type="program" />
              </Tabs.TabPane>
            </Tabs>
          </Col>
          <Col style={{ padding: "0 16px 16px 16px", height: "100%" }}>
            <FacultyAccounts {...this.props} {...facultyModalProps} />
          </Col>
        </Row>
        {/* <Tabs>
          <Tabs.TabPane key="1" tab="Adviser User">
            <FacultyAccounts {...this.props} />
          </Tabs.TabPane>
          <Tabs.TabPane key="2" tab="Co-op Adviser Selection">
            <h1>Co-op Adviser Selection</h1>
          </Tabs.TabPane>
          <Tabs.TabPane key="3" tab="Program Selection">
            <h1>Program Selection</h1>
          </Tabs.TabPane>
        </Tabs> */}
      </React.Fragment>
    );
  }
}
