import * as React from "react";
import { connect } from "react-redux";
import { message, Spin } from "antd";

import TokenSignInComp from "./components/student/TokenSignIn";
import StudentHomeComp from "./components/student/StudentHome";
import PasswordSignInComp from "./components/faculty/PasswordSignIn";
import StudentProfileComp from "./components/student/StudentProfile";
import SearchComp from "./components/faculty/search";
import EmployerComp from "./components/faculty/Employers";
import ChangePasswordComp from "./components/faculty/ChangePassword";
import PUComp from "./components/faculty/PU";
import { Approval as FacultyApprovalComp, SurveyFields as SurveyFieldsComp, PendingApproval as FacultyPendingApprovalComp } from "./components/faculty/approval";
import AdvisersComp from "./components/faculty/selections/adviser/Advisers";
import AssessmentAdminComp from "./components/faculty/selections/assessments/Assessments";
import CourseAdminComp from "./components/faculty/selections/Courses";
import PreviewProfileComp from "./components/faculty/preview/Profile";
import PreviewApprovalAfterComp from "./components/faculty/preview/ApprovalAfter";
import PreviewApprovalBeforeComp from "./components/faculty/preview/ApprovalBefore";
import PreviewAssessmentComp from "./components/faculty/preview/SelfAssessment";
import PreviewInformationComp from "./components/faculty/preview/Information";
import PreviewResumeComp from "./components/faculty/preview/Resume";
import DashboardComp from "./components/faculty/Dashboard";
import * as create from "./components/student/create";

import StudentApprovalComp from "./components/student/approve/Approval";

import EmployerSearchComp from "./components/employer/Search";
import StudentListComp from "./components/employer/StudentList";

import { ComponentProps } from "./store/types";
import { actions } from "./store";
import * as api from "./api";

function makeContainer(title: string, role: "student" | "faculty" | "employer", Component) {
  class Container extends React.Component<ComponentProps, { spinning: boolean }> {
    constructor(props) {
      super(props);
      this.state = { spinning: true };
    }

    componentDidMount() {
      document.title = title ? `Co-op | ${title}` : "Co-op";
      switch (role) {
        case "student":
          this.props.dispatch(actions.whoami());
          this.props.dispatch(actions.signInDone(role));
          this.setState({ spinning: false });
          return;
        case "faculty":
          this.props.dispatch(actions.whoami());
          this.props.dispatch(actions.signInDone(role));
          this.props.dispatch(actions.approvalFaculty.updateApprovalBadge());
          this.setState({ spinning: false });
          return;
        case "employer":
          const qp = new URLSearchParams(this.props.router.location.search);
          const key = qp.get("key");
          this.props.dispatch(actions.signInDone(role));
          if (key) {
            api.auth.signInKey({ key }).then(({ valid, err }) => {
              if (valid) {
                this.props.dispatch(actions.whoami());
                this.props.dispatch(actions.replace(window.location.pathname));
                this.setState({ spinning: false });
              } else {
                message.error(err);
              }
            });
          } else {
            this.props.dispatch(actions.whoami());
            this.setState({ spinning: false });
          }
          return;
      }
    }

    render() {
      if (this.state.spinning) {
        return <Spin />;
      } else {
        return <Component {...this.props} />;
      }
    }
  }
  return connect(state => state)(Container);
}

// Students
const makeStudentContainer = (title: string, Component) => makeContainer(title, "student", Component);
export const TokenSignIn = makeStudentContainer("Sign in", TokenSignInComp);
export const StudentHome = makeStudentContainer("Home", StudentHomeComp);
export const SelfAssessment = makeStudentContainer("1. Tech Self Assessment", create.SelfAssessment);
export const Information = makeStudentContainer("2. Information", create.Information);
export const Resume = makeStudentContainer("3. Resume", create.Resume);
export const CreateDone = makeStudentContainer("Done", create.Done);
export const StudentProfile = makeStudentContainer("Profile", StudentProfileComp);
export const StudentApproval = makeStudentContainer("Approve", StudentApprovalComp);

// Faculty
const makeFacultyContainer = (title: string, Component) => makeContainer(title, "faculty", Component);
export const PasswordSignIn = makeFacultyContainer("Sign in", PasswordSignInComp);
export const Search = makeFacultyContainer("Search", SearchComp);
export const Employer = makeFacultyContainer("Employer", EmployerComp);
export const ChangePassword = makeFacultyContainer("Password", ChangePasswordComp);
export const PU = makeFacultyContainer("Students", PUComp);
export const FacultyApproval = makeFacultyContainer("Approval - Historical", FacultyApprovalComp);
export const FacultyPendingApproval = makeFacultyContainer("Approval - Pending", FacultyPendingApprovalComp);

export const SurveyFields = makeFacultyContainer("Admin - Survey Fields", SurveyFieldsComp);
export const Advisers = makeFacultyContainer("Admin - Advisers", AdvisersComp);
export const AssessmentAdmin = makeFacultyContainer("Admin - Assessment", AssessmentAdminComp);
export const CourseAdmin = makeFacultyContainer("Admin - Courses", CourseAdminComp);

export const PreviewProfile = makeFacultyContainer("Preview - Profile", PreviewProfileComp);
export const PreviewAssessment = makeFacultyContainer("Preview - Tech Self Assessment", PreviewAssessmentComp);
export const PreviewInformation = makeFacultyContainer("Preview - Information", PreviewInformationComp);
export const PreviewResume = makeFacultyContainer("Preview - Resume", PreviewResumeComp);
export const PreviewDone = makeFacultyContainer("Preview - Done", create.Done);
export const PreviewApprovalAfter = makeFacultyContainer("Preview - Approval After", PreviewApprovalAfterComp);
export const PreviewApprovalBefore = makeFacultyContainer("Preview - Approval Before", PreviewApprovalBeforeComp);

export const Dashboard = makeFacultyContainer("Dashboard", DashboardComp);

// Emplopyer
const makeEmployerContainer = (title: string, Component) => makeContainer(title, "employer", Component);
export const EmployerSearch = makeEmployerContainer("Search", EmployerSearchComp);
export const StudentList = makeEmployerContainer("Student List", StudentListComp);
