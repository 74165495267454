import * as React from "react";
import { Button, Icon } from "antd";

const Fab = ({ onClick, hidden, iconType, idx }) => (
  <div
    style={{
      position: "fixed",
      bottom: 24 + 56 * idx,
      right: 0,
    }}
  >
    <Button
      type="primary"
      shape="circle"
      hidden={hidden}
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        width: 48,
        height: 48,
        fontSize: 20,
      }}
      onClick={onClick}
    >
      {" "}
      <Icon type={iconType} />
    </Button>
  </div>
);

export default Fab;
